import cloneDeep from 'lodash/cloneDeep';

import { isSSG } from '@fhs-legacy/frontend/src/utils/environment';

import { Logger } from '../debugger';
import { CartModel, CartState } from '../types';

import { cart } from './tmp-cart';

export async function getCart(_userId: string) {
  await sleep(200);
  const state = cart.getState();

  // need to avoid mutating the object directly and getting phantom state updates
  return cloneDeep(state);
}

if (!isSSG) {
  // @ts-ignore - using this for introspection
  window.getCart = () => cart.getState();
}

async function writeCart(update: CartModel, logger: Logger) {
  await sleep(1000);
  if (update.version === cart.getState().version + 1) {
    logger.log('[SUCCESS] - Writing Cart Update complete');
    cart.setState(update);
  } else {
    logger.log('[ERROR] - Writing cart update');
    throw new Error('Dropping cart write - debug this');
  }
}

// A central updateCart function can enforce state machine logic
export async function updateCart(update: CartModel, logger: Logger) {
  const { state: priorState, version: priorVersion } = await getCart('foobar');
  logger.log(`updating cart. prior state: ${priorState}, next state: ${update.state}`);

  if (priorVersion !== update.version) {
    logger.log('[UPDATE_ERROR] Handling state update against a previous version - discarding');
    throw new Error('VERSION_MISMATCH');
  }

  update.version++;
  logger.log('Updating version number for update. new version is', update.version);

  const defaultUnhandledHandler = () => {
    logger.log(
      `[STATE_MACHINE_ERROR] state machine was not handled for transition for ${priorState} to ${update.state}`
    );
    throw new Error('INVALID_TRANSITION');
  };

  const defaultUnhandledStates: Record<CartState, Function> = {
    NEEDS_LOYALTY_VALIDATION: defaultUnhandledHandler,
    VALID: defaultUnhandledHandler,
    LOYALTY_INVALID: defaultUnhandledHandler,
    PRICE_REQUESTED: defaultUnhandledHandler,
    PRICE_ERROR: defaultUnhandledHandler,
    PRICE_SUCCESSFUL: defaultUnhandledHandler,
    INSERT_REQUESTED: defaultUnhandledHandler,
    INSERT_ERROR: defaultUnhandledHandler,
    INSERT_SUCCESSFUL: defaultUnhandledHandler,
  };

  // Map<LastState, Map<SupportedNextState, Handler>>
  const stateMachine: Record<CartState, Record<CartState, Function>> = {
    VALID: {
      ...defaultUnhandledStates,
      NEEDS_LOYALTY_VALIDATION: () => writeCart(update, logger),
      PRICE_REQUESTED: () => writeCart(update, logger),
    },
    NEEDS_LOYALTY_VALIDATION: {
      ...defaultUnhandledStates,
      VALID: () => writeCart(update, logger),
      LOYALTY_INVALID: () => writeCart(update, logger),
      // This should be allowed to let the cart receive updates while the cart is in validation
      NEEDS_LOYALTY_VALIDATION: () => writeCart(update, logger),
    },
    LOYALTY_INVALID: {
      ...defaultUnhandledStates,
      NEEDS_LOYALTY_VALIDATION: () => writeCart(update, logger),
    },
    PRICE_ERROR: {
      ...defaultUnhandledStates,
      NEEDS_LOYALTY_VALIDATION: () => writeCart(update, logger),
      PRICE_REQUESTED: () => writeCart(update, logger),
    },
    PRICE_REQUESTED: {
      ...defaultUnhandledStates,
      PRICE_ERROR: () => {
        logger.log('Removing rbiOrderId metadata');
        delete update.metadata.rbiOrderId;
        return writeCart(update, logger);
      },
      PRICE_REQUESTED: () => writeCart(update, logger),
      PRICE_SUCCESSFUL: () => writeCart(update, logger),
      NEEDS_LOYALTY_VALIDATION: () => {
        logger.log('Removing rbiOrderId metadata');
        delete update.metadata.rbiOrderId;
        return writeCart(update, logger);
      },
    },
    PRICE_SUCCESSFUL: {
      ...defaultUnhandledStates,
      NEEDS_LOYALTY_VALIDATION: () => writeCart(update, logger),
      INSERT_REQUESTED: () => writeCart(update, logger),
    },
    INSERT_REQUESTED: {
      ...defaultUnhandledStates,
      INSERT_ERROR: () => writeCart(update, logger),
      INSERT_REQUESTED: () => writeCart(update, logger),
      INSERT_SUCCESSFUL: () => writeCart(update, logger),
    },
    INSERT_ERROR: {
      ...defaultUnhandledStates,
      NEEDS_LOYALTY_VALIDATION: () => writeCart(update, logger),
      PRICE_REQUESTED: () => writeCart(update, logger),
    },
    INSERT_SUCCESSFUL: {
      ...defaultUnhandledStates,
    },
  };

  // process the state machine update.
  return stateMachine[priorState][update.state]();
}

const sleep = async (time: number) => new Promise(resolve => setTimeout(resolve, time));
