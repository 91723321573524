import { useEffect, useRef } from 'react';
import { Platform, View } from 'react-native';

import { Pressable, PressableProps } from '../pressable';

import { useCheckbox } from './context';

export type CheckboxPressableProps = Omit<
  PressableProps,
  'role' | 'aria-checked' | 'aria-label' | 'onPress' | 'disabled'
>;

export function CheckboxPressable(props: CheckboxPressableProps) {
  const checkbox = useCheckbox();
  const pressableRef = useRef<View>(null);

  useEffect(() => {
    if (Platform.OS === 'web') {
      // Focus checkbox pressable when item becomes selected (maybe from clicking CheckboxLabel)
      pressableRef.current?.focus();
    }
  }, [checkbox.checked]);

  return (
    <Pressable
      ref={pressableRef}
      role="checkbox"
      aria-checked={checkbox.checked}
      disabled={checkbox.disabled}
      onPress={checkbox.handlePress}
      aria-label={checkbox.label}
      {...props}
    />
  );
}
