import { Image } from 'expo-image';
import { useEffect } from 'react';
import { useRef } from 'react';
import { StyleSheet, View } from 'react-native';

import { tokens } from '../../tokens';
import { Pressable, PressableProps, usePressableState } from '../pressable';
import { Text } from '../text';

import type { ImageAssetWithAltText } from './types';
import { useAnchors } from './use-anchors';

export function AnchorListVertical() {
  const anchors = useAnchors();
  return (
    <>
      {anchors.map(a => (
        <Anchor
          key={a.id}
          isActive={a.isActive}
          image={a.image}
          onPress={a.onPress}
          title={a.title}
        />
      ))}
    </>
  );
}

const Anchor = (props: {
  isActive: boolean;
  onPress: PressableProps['onPress'];
  image: ImageAssetWithAltText;
  title: string;
}) => {
  const ref = useRef<View>(null);

  useEffect(() => {
    if (!props.isActive) {
      ref.current?.blur?.();
    }
  }, [props.isActive]);

  return (
    <Pressable
      ref={ref}
      onPress={props.onPress}
      style={[styles.pressable, props.isActive && styles.isActivePressable]}
      focusedStyle={styles.isFocusedPressable}
      hoveredStyle={styles.isHoveredPressable}
    >
      {props.image?.asset && (
        <Image source={props.image.asset} alt={props.image.altText} style={styles.image} />
      )}
      <Title isActive={props.isActive} title={props.title} />
    </Pressable>
  );
};

const Title = ({ isActive, title }: { isActive: boolean; title: string }) => {
  const pressableState = usePressableState();

  return (
    <Text.Paragraph
      size="sm"
      weight={isActive || pressableState.hovered ? 'semibold' : 'normal'}
      style={[isActive && styles.isActiveText, pressableState.hovered && styles.isHoveredText]}
    >
      {title}
    </Text.Paragraph>
  );
};

const styles = StyleSheet.create({
  pressable: {
    paddingVertical: 4,
    paddingHorizontal: 16,
    gap: 8,
    minHeight: 52,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  },
  isActivePressable: {
    backgroundColor: tokens.colors.$houseRedOpacity04,
  },
  isHoveredPressable: {
    backgroundColor: tokens.colors.$houseRedOpacity04,
  },
  isFocusedPressable: {},
  isActiveText: {
    color: tokens.colors.$houseRedDarken,
  },
  isHoveredText: {
    color: tokens.colors.$houseRedDarken,
  },
  isFocusedText: {
    color: tokens.colors.$houseRedDarken,
  },
  image: {
    width: 40,
    height: 40,
  },
});
