import { ImageBackground, ImageBackgroundProps } from 'expo-image';
import { Link } from 'expo-router';

import { Text } from '@fhs/ui';
import { Button, YStack, createMqStyles, tokens, useMqSelect } from '@fhs/ui';

export type PublicSafetyFoundationCardProps = Omit<ImageBackgroundProps, 'children' | 'source'> & {
  foundationHref: string;
};
export function PublicSafetyFoundationCard({
  foundationHref,
  ...props
}: PublicSafetyFoundationCardProps) {
  const styles = useStyles();
  return (
    <ImageBackground
      source={require('./public-safety-foundation-card-background.webp')}
      placeholder={{ thumbhash: 'ZhgGE4Teo3g9wKVLb+j6pKs' }}
      {...props}
      style={[styles.backgroundImageContainer, props.style]}
    >
      <YStack style={styles.contentContainer}>
        <Text weight="bold" style={styles.helpThemSaveLives}>
          Help Them Save Lives!
        </Text>
        <Link href={foundationHref} asChild target="_blank">
          <Button type="solid" size={useMqSelect({ $gteDesktopLg: 'xl' }, 'lg')}>
            <Button.Text>Learn More</Button.Text>
          </Button>
        </Link>
      </YStack>
    </ImageBackground>
  );
}

const useStyles = createMqStyles({
  backgroundImageContainer: {
    $base: {
      aspectRatio: 1920 / 879,
      padding: 16,
      alignItems: 'center',
    },
  },
  contentContainer: {
    $base: {
      marginLeft: 'auto',
      backgroundColor: tokens.colors.$whiteOpacity90,
      borderRadius: 12,
      alignItems: 'center',
      gap: 24,
      marginTop: 32,
      marginRight: 24,
    },
    $ltDesktopLg: {
      padding: 24,
      width: 300,
    },
    $gteDesktopLg: {
      padding: 32,
      marginTop: 64,
      marginRight: 54,
    },
  },
  helpThemSaveLives: {
    $base: {
      textAlign: 'center',
      color: tokens.colors.$houseRedDarken,
      fontSize: 30,
    },
  },
});
