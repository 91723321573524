import React from 'react';
import { useIntl } from 'react-intl';

import { Box, Header, Text } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Dialog from 'components/dialog';

interface IConfirmRedemptionDialogProps {
  showDialog: boolean;
  dismiss: () => void;
}

const ErrorInStoreRedemptionDialog = ({ showDialog, dismiss }: IConfirmRedemptionDialogProps) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog
      showDialog={showDialog}
      headingComponent={
        <Header variant="headerOne">{formatMessage({ id: 'inStoreRedemptionErrorHeader' })}</Header>
      }
      bodyComponent={
        <Text variant="copyThree">{formatMessage({ id: 'inStoreRedemptionErrorBody' })}</Text>
      }
      actions={
        <Box width="full" marginTop="$4">
          <ActionButton
            marginTop="$2"
            width="full"
            variant={ActionButtonVariants.PRIMARY}
            onPress={dismiss}
          >
            {formatMessage({ id: 'okay' })}
          </ActionButton>
        </Box>
      }
      modalAppearanceEventMessage="Error redeem in restaurant Dialog"
      onDismiss={dismiss}
    />
  );
};

export default ErrorInStoreRedemptionDialog;
