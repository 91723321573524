import { Incentive, ItemEntry } from '../../types';

import { CartOfferItem as CartOfferTypeItem } from './offer-item';
import { CartRewardItem } from './reward-item';

type Props = {
  incentive: Incentive;
  entries: ItemEntry[];
};

export function CartOfferItem(props: Props) {
  switch (props.incentive.type) {
    case 'REWARD':
      return <CartRewardItem incentive={props.incentive} entry={props.entries[0]} />;
    default:
      return <CartOfferTypeItem incentive={props.incentive} entries={props.entries} />;
  }
}
