import { Stack } from 'expo-router';
import Head from 'expo-router/head';
import { useIntl } from 'react-intl';

import StoreSelected from '@fhs-legacy/frontend/src/components/store-selected';
import MainMenu from '@fhs-legacy/frontend/src/pages/main-menu';
import { isNative } from '@fhs-legacy/frontend/src/utils/environment';
export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export default function MenuIndexPage() {
  const { formatMessage } = useIntl();

  return (
    <StoreSelected>
      <Stack.Screen options={{ headerShown: isNative }} />
      <Head>
        <title>{`${formatMessage({ id: 'menu' })} - Firehouse Subs`}</title>
      </Head>
      <MainMenu />
    </StoreSelected>
  );
}
