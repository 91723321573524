import { memo } from 'react';
import { useIntl } from 'react-intl';
import { GestureResponderEvent } from 'react-native';

import { ActionSheet, ActionSheetRow, IconAdd, IconStore } from '@fhs/ui';

type StoreOption = {
  storeName: string;
  storeAddress: string;
  onPress: (event: GestureResponderEvent) => void;
};

type OtherOption = {
  label: string;
  onPress: (event: GestureResponderEvent) => void;
};

type ConfirmStoreActionSheetProps = {
  isDelivery: boolean;
  option1: StoreOption;
  option2: StoreOption;
  option3: OtherOption;
  isVisible: boolean;
  onClose: () => void;
};

function _ConfirmStoreActionSheet({
  isDelivery = false,
  isVisible = false,
  option1,
  option2,
  option3,
  onClose,
}: ConfirmStoreActionSheetProps) {
  const { formatMessage } = useIntl();

  const title = isDelivery
    ? formatMessage({ id: 'selectYourDeliveryAddress' })
    : formatMessage({ id: 'selectYourPickUpStore' });

  return (
    <ActionSheet isVisible={isVisible} headerTitle={title} onClose={onClose}>
      <ActionSheetRow type="radio" icon={<IconStore size={24} />} onPress={option1.onPress}>
        <ActionSheetRow.Item firstLine={option1.storeName} secondLine={option1.storeAddress} />
      </ActionSheetRow>
      <ActionSheetRow type="radio" icon={<IconStore size={24} />} onPress={option2.onPress}>
        <ActionSheetRow.Item firstLine={option2.storeName} secondLine={option2.storeAddress} />
      </ActionSheetRow>
      <ActionSheetRow type="radio" icon={<IconAdd size={24} />} onPress={option3.onPress}>
        <ActionSheetRow.Item firstLine={option3.label} />
      </ActionSheetRow>
    </ActionSheet>
  );
}

export const ConfirmStoreActionSheet = memo(_ConfirmStoreActionSheet);
