import { isNil } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { ScrollView, Text, YStack } from '@fhs/ui';
import { Box, Icon, Text as UclText } from '@fhs-legacy/universal-components';
import { ArrowLink } from 'components/arrow-link';
import Link from 'components/link';
import { VerifyEmailMessage } from 'components/verify-email-message';
import useAuth from 'hooks/auth/use-auth';
import { useIsDesktopBp, useIsMobileBp } from 'hooks/breakpoints';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import LoyaltyCardTile from 'pages/loyalty/loyalty-card-tile/loyalty-card-tile';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { MyAccountSections, defaultMyAccountSections } from 'state/launchdarkly/variations';
import { theme } from 'styles/configure-theme';
import { routes } from 'utils/routing';
import { DRAWER_ACCOUNT_INFO_BUTTON } from 'utils/test-ids';

import CreateAccount from './create-account';
import Disclaimer from './disclaimer';
import ListOfLinks from './list-of-links';
import RewardsLink from './rewards-link';

const AccountAction = Link.withConfig({
  flexDirection: 'row',
  textDecoration: 'none',
  marginY: '2',
  alignItems: 'center',
});

const StyledIcon = Box.withConfig({});

const ActionsWrapper = Box.withConfig({
  borderBottomWidth: '1px',
  borderBottomColor: 'black',
  paddingBottom: '4',
});

const AccountLinksWrapper = Box.withConfig({
  marginBottom: '0',
});

const Account = () => {
  const { formatMessage } = useIntl();
  const { user } = useAuthContext();
  const enableRequestMyInformation = useFlag(LaunchDarklyFlag.ENABLE_REQUEST_MY_INFORMATION);
  const enableOrderingFlag = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const enableOrdering = isNil(enableOrderingFlag) || enableOrderingFlag;
  const enableAccountDeletePage = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_DELETE_PAGE);
  const enableCommPrefFlag = useFlag(LaunchDarklyFlag.ENABLE_COMMUNICATION_PREFERENCES);
  const enableAppIssueCollector = useFlag(LaunchDarklyFlag.ENABLE_APP_ISSUE_COLLECTOR);
  const enableDiagnosticTools = useFlag(LaunchDarklyFlag.ENABLE_DIAGNOSTIC_TOOLS);
  const enableFindRestaurantLink = useFlag(LaunchDarklyFlag.ENABLE_FIND_RESTAURANT_LINK);
  const isMobile = useIsMobileBp();
  const isDesktop = useIsDesktopBp();
  const enableCommunicationPreferences = Boolean(enableCommPrefFlag);
  const { isAuthenticated } = useAuth();

  // TODO: Remove when simplified menu is out of beta
  const enableSimplifiedMenuBeta = useFlag(LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA);
  const enableInternalTestingTools =
    enableDiagnosticTools || enableSimplifiedMenuBeta || enableAppIssueCollector;

  useRumPageView('account', 'Account');

  const { featureAboutMenuEntriesMobile: aboutMenuEntries }: any = useFeatureNavigation();

  const enableSignUpFlag = useFlag(LaunchDarklyFlag.ENABLE_SIGN_UP);
  const enableSignUp = isNil(enableSignUpFlag) || enableSignUpFlag;
  const enabledAccountSections =
    useFlag<MyAccountSections>(LaunchDarklyFlag.ENABLE_ACCOUNT_SECTIONS_VARIATIONS) ||
    defaultMyAccountSections;

  const showCreateAccount = isMobile && !isAuthenticated;
  const showSignUpLink = isDesktop && !isAuthenticated;
  const showEarnPointsLink = isMobile && enabledAccountSections.earnPoints;

  return (
    <ScrollView contentContainerStyle={styles.padding16}>
      <SafeAreaView>
        {isAuthenticated && (
          <>
            <YStack style={styles.accountTitleWrapper}>
              <Text weight="semibold" style={styles.accountTitle}>
                {formatMessage({ id: 'account' })}
              </Text>
            </YStack>

            <AccountLinksWrapper paddingX={isDesktop ? '1/4' : 0}>
              {user?.details.emailVerified === false && <VerifyEmailMessage />}
              <RewardsLink />
              {enabledAccountSections.accountInfo && (
                <ArrowLink to={routes.info} testID={DRAWER_ACCOUNT_INFO_BUTTON}>
                  {formatMessage({ id: 'accountInfo' })}
                </ArrowLink>
              )}
              {showEarnPointsLink && (
                <ArrowLink to={routes.redeem} testID="earn-points">
                  {formatMessage({ id: 'earnPoints' })}
                </ArrowLink>
              )}
              {enabledAccountSections.paymentMethods && enableOrdering && (
                <ArrowLink to={routes.payment} testID="payment-methods">
                  {formatMessage({ id: 'paymentMethods' })}
                </ArrowLink>
              )}
              {enabledAccountSections.recentOrders && enableOrdering && (
                <ArrowLink to={routes.orders} testID="recent-orders">
                  {formatMessage({ id: 'recentOrders' })}
                </ArrowLink>
              )}
              {enableCommunicationPreferences && (
                <ArrowLink testID="communication-preferences" to={routes.communication}>
                  {formatMessage({ id: 'communicationPreferences' })}
                </ArrowLink>
              )}
              {enableRequestMyInformation && (
                <ArrowLink to={formatMessage({ id: 'routes.accountRequestInfo' })}>
                  {formatMessage({ id: 'requestMyInformation' })}
                </ArrowLink>
              )}
              {enableAccountDeletePage && (
                <ArrowLink to={formatMessage({ id: 'routes.accountDelete' })}>
                  {formatMessage({ id: 'deleteAccount' })}
                </ArrowLink>
              )}
              <LoyaltyCardTile />
            </AccountLinksWrapper>
          </>
        )}

        {!isDesktop && (
          <>
            {!!enableSignUp && (
              <ActionsWrapper>
                {showCreateAccount && <CreateAccount />}
                {showSignUpLink && (
                  <AccountAction testID="signup-link" to={routes.signIn}>
                    <>
                      <StyledIcon>
                        <Icon
                          variant="account"
                          color={theme.token('icon-default')}
                          width="24px"
                          aria-hidden
                        />
                      </StyledIcon>
                      <UclText marginLeft="1">
                        {formatMessage({ id: 'signInOrCreateAccount' })}
                      </UclText>
                    </>
                  </AccountAction>
                )}
                {enableFindRestaurantLink && (
                  <AccountAction to={routes.storeLocator}>
                    <>
                      <StyledIcon>
                        <Icon
                          variant="location"
                          color={theme.token('icon-default')}
                          width="24px"
                          aria-hidden
                        />
                      </StyledIcon>
                      <UclText bold>{formatMessage({ id: 'findARestaurant' })}</UclText>
                    </>
                  </AccountAction>
                )}
              </ActionsWrapper>
            )}

            {aboutMenuEntries && <ListOfLinks entries={aboutMenuEntries.pages || []} />}
            <Disclaimer />
            {enableInternalTestingTools && (
              <AccountLinksWrapper>
                <YStack
                  style={{
                    paddingTop: 32,
                    paddingBottom: 16,
                    alignItems: 'center',
                    borderBottomWidth: 1,
                    borderBottomColor: '#aaa',
                  }}
                >
                  <Text weight="semibold" style={{ fontSize: 16 }}>
                    Internal Testing Tools
                  </Text>
                </YStack>
                {enableSimplifiedMenuBeta && (
                  <ArrowLink to="/v2/menu" testID="beta-simply-menu">
                    <Text weight="semibold">Simply Better Menu</Text>
                  </ArrowLink>
                )}
                {enableDiagnosticTools && (
                  <ArrowLink to={routes.diagnostics}>
                    <Text weight="semibold">{formatMessage({ id: 'diagnostics' })}</Text>
                  </ArrowLink>
                )}
                {enableAppIssueCollector && (
                  <ArrowLink to={routes.issueCollector} marginTop="2">
                    <Text weight="semibold">Issue Collector</Text>
                  </ArrowLink>
                )}
              </AccountLinksWrapper>
            )}
          </>
        )}
      </SafeAreaView>
    </ScrollView>
  );
};

export default Account;

const styles = StyleSheet.create({
  padding16: {
    padding: 16,
  },
  accountTitleWrapper: {
    alignItems: 'center',
  },
  accountTitle: {
    fontSize: 24,
  },
});
