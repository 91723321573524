import { ActivityIndicator, Pressable, StyleSheet, View } from 'react-native';

import {
  IconPaymentTypeAddNewPayment,
  IconPaymentTypeAmex,
  IconPaymentTypeMastercard,
  IconPaymentTypeVisa,
  Text,
  tokens,
} from '@fhs/ui';
import { useStoredPaymentMethodsQuery } from '@fhs-legacy/frontend/src/generated/graphql-gateway';

const iconMap = {
  VISA: <IconPaymentTypeVisa size={24} />,
  MASTERCARD: <IconPaymentTypeMastercard size={24} />,
  AMEX: <IconPaymentTypeAmex size={24} />,
};

export function PaymentMethodSelection() {
  // TODO: We should create our own new payment API on our backend
  // that stands in front of this old api requests so we can simplify the api
  const { data, loading } = useStoredPaymentMethodsQuery();

  if (loading) {
    return <ActivityIndicator />;
  }

  return (
    <>
      {(data?.storedPaymentMethods ?? []).map(method => {
        switch (method.__typename) {
          case 'SchemeStoredPaymentMethod':
            return (
              <Pressable style={styles.row} key={method.id}>
                <View style={styles.innerRow}>
                  <View style={styles.iconCircle}>{iconMap[method.brand]}</View>
                  <Text.Ui weight="semibold" size="lg">
                    {method.brand} • • • • {method.last4}
                  </Text.Ui>
                </View>
              </Pressable>
            );
          // we dont support the other methods previously listed
          default:
            return null;
        }
      })}
      <Pressable style={styles.row}>
        <View style={styles.innerRow}>
          <View style={styles.iconCircle}>
            <IconPaymentTypeAddNewPayment size={24} />
          </View>
          <Text.Ui weight="semibold" size="lg">
            Add New Credit Card
          </Text.Ui>
        </View>
      </Pressable>
    </>
  );
}

const styles = StyleSheet.create({
  row: {
    borderBottomColor: tokens.colors.$black10,
    borderBottomWidth: 1,
    width: '100%',
    height: 64,
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  innerRow: {
    width: 390,
    marginHorizontal: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
  },

  iconCircle: {
    height: 40,
    width: 40,
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: tokens.colors.$houseLight,
    marginRight: 12,
  },
});
