import { Image } from 'expo-image';
import { Link } from 'expo-router';
import { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { createMqStyles } from '../../mq-styles';
import { tokens } from '../../tokens';
import { Pressable, PressableProps, usePressableState } from '../pressable';
import { Text } from '../text';

import type { BadgeType, ItemSizeType, ItemType } from './types';

export type SectionListItemProps = PressableProps & {
  size: ItemSizeType;
  item: ItemType;
  isFirstColumn: boolean;
  isLastColumn: boolean;
};

export function SectionListItem({
  size,
  item,
  isFirstColumn,
  isLastColumn,
  ...pressableProps
}: SectionListItemProps) {
  const stackStyles = useStackStyles();
  const containerStyles = useContainerStyles();

  return (
    <View
      style={[
        containerStyles.container,
        isFirstColumn && containerStyles.isFirstColumn,
        isLastColumn && containerStyles.isLastColumn,
      ]}
    >
      <Link href={item.href} asChild>
        <Pressable
          {...pressableProps}
          disabled={!item.isAvailable}
          style={
            // Have to use StyleSheet.flatten manually due to issue with Link component's asChild functionality
            // circumventing react-native-web's automatic flattening for the style prop
            useMemo(
              () =>
                StyleSheet.flatten([stackStyles.stack, stackStyles[size], pressableProps.style]),
              [pressableProps.style, size, stackStyles]
            )
          }
          disabledStyle={pressableProps.disabledStyle}
          outlineStyle={[pressableStyle.pressableNoOutline, pressableProps.outlineStyle]}
          outlineHoveredStyle={[
            pressableStyle.pressableNoOutline,
            pressableProps.outlineHoveredStyle,
          ]}
          outlineDisabledStyle={[
            pressableStyle.pressableNoOutline,
            pressableProps.outlineDisabledStyle,
          ]}
          outlinePressedStyle={[
            pressableStyle.pressableNoOutline,
            pressableProps.outlinePressedStyle,
          ]}
          outlineFocusedStyle={[
            pressableStyle.pressableNoOutline,
            pressableProps.outlineFocusedStyle,
          ]}
        >
          <OutlineBox size={size} />
          <Image
            recyclingKey={item.image.asset.uri}
            source={item.image.asset}
            alt={item.image.altText}
            contentFit="contain"
            style={[imageStyles.img, imageStyles[size], !item.isAvailable && imageStyles.disabled]}
          />
          <Badge type={!item.isAvailable ? 'outOfStock' : item.badge} />
          <ItemHeading item={item} />
          <Text.Paragraph
            size="sm"
            numberOfLines={2}
            style={[descriptionStyles.description, !item.isAvailable && descriptionStyles.disabled]}
          >
            {item.description}
          </Text.Paragraph>
        </Pressable>
      </Link>
    </View>
  );
}

const badgeTypeToText: Record<BadgeType, string> = {
  none: '',
  new: 'New!',
  limited: 'Limited!',
  outOfStock: 'Out of Stock',
};

function Badge(props: { type?: BadgeType }) {
  const badgeType = props.type ?? 'none';

  if (badgeType === 'none') {
    return null;
  }

  return (
    <View style={[badgeStyle.badge, badgeStyle[`badge-${badgeType}`]]}>
      <Text.Ui
        size="sm"
        weight="semibold"
        style={[badgeStyle.text, badgeStyle[`text-${badgeType}`]]}
      >
        {badgeTypeToText[badgeType]}
      </Text.Ui>
    </View>
  );
}

function OutlineBox(props: Pick<SectionListItemProps, 'size'>) {
  const pressableState = usePressableState();

  return (
    <View
      style={[
        boxStyles.box,
        boxStyles[props.size],
        pressableState.hovered && boxStyles.hovered,
        pressableState.focused && boxStyles.focused,
      ]}
    />
  );
}

function ItemHeading(props: Pick<SectionListItemProps, 'item'>) {
  const pressableState = usePressableState();

  return (
    <View style={headingStyles.headingContainer}>
      <Text.Heading
        numberOfLines={2}
        type="four"
        style={[
          headingStyles.heading,
          pressableState.hovered && headingStyles.hovered,
          pressableState.focused && headingStyles.focused,
          pressableState.disabled && headingStyles.disabled,
        ]}
      >
        {props.item.title}
      </Text.Heading>
    </View>
  );
}

const badgeStyle = StyleSheet.create({
  badge: {
    paddingHorizontal: 8,
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: tokens.colors.$success,
    height: 24,
    justifyContent: 'center',
    borderRadius: 4,
  },
  'badge-outOfStock': {
    backgroundColor: tokens.colors.$houseLight,
  },
  text: {
    color: tokens.colors.$white,
  },
  'text-outOfStock': {
    color: tokens.colors.$houseDark,
  },
});

const pressableStyle = StyleSheet.create({
  pressableNoOutline: {
    borderColor: 'transparent',
  },
});

const useContainerStyles = createMqStyles({
  container: {
    $base: {
      flexGrow: 1,
      flexShrink: 1,
      paddingLeft: 6,
      paddingRight: 6,
      paddingBottom: 10,
      paddingTop: 10,
    },
    $gteDesktop: {
      paddingLeft: 6,
      paddingRight: 6,
      paddingBottom: 12,
      paddingTop: 12,
    },
  },
  isFirstColumn: {
    $base: {
      paddingLeft: 0,
    },
  },
  isLastColumn: {
    $base: {
      paddingRight: 0,
    },
  },
});

const useStackStyles = createMqStyles({
  stack: {
    $base: {
      flexShrink: 1,
      flexGrow: 1,
      gap: 4,
      position: 'relative',
      backgroundColor: 'white',
      alignItems: 'center',
      padding: 16,
      paddingTop: 0,
    },

    $gteDesktop: {
      padding: 24,
      paddingTop: 0,
    },
  },
  sm: {
    $base: {},
    $gteDesktop: {
      paddingBottom: 12,
    },
  },
  lg: {
    $base: {
      paddingBottom: 20,
    },
  },
});

const boxStyles = StyleSheet.create({
  box: {
    borderRadius: 8,
    borderColor: tokens.colors.$blackOpacity10,
    borderWidth: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  lg: {
    top: 52,
  },
  sm: {
    top: 32,
  },
  hovered: {
    borderColor: tokens.colors.$houseRedOpacity30,
  },
  focused: {
    borderColor: tokens.colors.$houseRedOpacity30,
  },
});

const headingStyles = StyleSheet.create({
  headingContainer: {
    minHeight: 40,
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
  },
  heading: {
    textAlign: 'center',
  },
  hovered: {
    color: tokens.colors.$houseRedDarken,
  },
  focused: {
    color: tokens.colors.$houseRedDarken,
  },
  disabled: {
    color: tokens.colors.$disabledText,
  },
});

const descriptionStyles = StyleSheet.create({
  description: {
    textAlign: 'center',
  },
  disabled: {
    color: tokens.colors.$disabledText,
  },
});

const imageStyles = StyleSheet.create({
  img: {
    width: '100%',
  },
  disabled: {
    opacity: 0.74,
  },
  lg: {
    height: 90,
  },
  sm: {
    height: 70,
  },
});
