import { withStaticProperties } from '@fhs/utils';

import { RadioControl as Control } from './radio-control';
import { RadioGroup as Group } from './radio-group';
import { RadioItem as Item } from './radio-item';
import { RadioLabel as Label } from './radio-label';
import { RadioPressable as Pressable } from './radio-pressable';

export const RadioGroup = withStaticProperties(Group, {
  Item,
  Pressable,
  Label,
  Control,
});

export { useRadioGroup, useRadioItem } from './context';
export type { RadioGroupContext, RadioItemContext } from './context';
export type { RadioControlProps } from './radio-control';
export type { RadioItemProps } from './radio-item';
export type { RadioLabelProps } from './radio-label';
export type { RadioGroupProps } from './radio-group';
export type { RadioPressableProps } from './radio-pressable';
