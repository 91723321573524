import { MqSwitch } from '@fhs/ui';
import { withStaticProperties } from '@fhs/utils';

import { ActionSheetListItem, ListItem } from './action-sheet-list-item';
import { BottomDrawer } from './bottom-drawer';
import { DesktopOptionSelectModal } from './desktop-option-select-modal.web';
import { ActionSheetProps } from './types';

export const ActionSheetRow = withStaticProperties(ActionSheetListItem, {
  Item: ListItem,
});

export const ActionSheet = (props: ActionSheetProps) => {
  return (
    <MqSwitch
      $ltDesktop={() => <BottomDrawer {...props} />}
      $gteDesktop={() => <DesktopOptionSelectModal {...props} />}
    />
  );
};
