import { getCurrentSession } from '@fhs-legacy/frontend/src/remote/auth';

import { Logger } from '../../debugger';

export async function fetchOrderStatus(
  rbiOrderId: string,
  logger: Logger
): Promise<{
  status: 'PRICE_SUCCESSFUL' | 'PRICE_REQUESTED' | 'INSERT_REQUESTED' | 'INSERT_SUCCESSFUL';
  cart: {
    subTotalCents: number;
  };
}> {
  logger.log('pricing transformed cart');
  const session = await getCurrentSession();
  const bearer = session?.getIdToken().getJwtToken();

  const res = await fetch('https://use1-dev-fhs-gateway.rbictg.com/graphql', {
    method: 'POST',
    body: JSON.stringify({
      query:
        'query GetOrder($rbiOrderId: ID!){order(rbiOrderId: $rbiOrderId){status,cart{subTotalCents}}}',
      variables: {
        rbiOrderId,
      },
    }),
    headers: {
      Authorization: `Bearer ${bearer}`,
      'Content-Type': 'application/json',
      // TODO: make sure these are real values when we ship this
      'X-Ui-Language': 'en',
      'X-Ui-Platform': 'web',
      'X-Ui-Region': 'US',
      'X-Ui-Sha': 'c2fc001375a1722baee5826c17b236cbe98f4209',
      'X-Ui-Version': '9.2.1',
      'X-User-Datetime': '2024-07-18T11:21:17-05:00',
      'X-Platform-Framework': 'react-native',
      'X-Session-Id': '124194402184',
    },
  });

  const json = await res.json();

  return json.data.order;
}
