import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { tokens } from '../../tokens';

import { PRESSABLE_BORDER_WIDTH } from './constants';

const OUTLINE_WIDTH = 4;
// PUlls the outline view beyond containing pressable.
const OUTLINE_POSITION = 0 - OUTLINE_WIDTH - PRESSABLE_BORDER_WIDTH;

type PressableOutlineProps = {
  borderRadius: number;
  style: StyleProp<ViewStyle>;
};

export function PressableOutline(props: PressableOutlineProps) {
  return (
    <View
      style={[
        outlineStyles.baseline,
        { borderRadius: props.borderRadius + OUTLINE_WIDTH },
        props.style,
      ]}
    />
  );
}

const outlineStyles = StyleSheet.create({
  baseline: {
    position: 'absolute',
    top: OUTLINE_POSITION,
    right: OUTLINE_POSITION,
    bottom: OUTLINE_POSITION,
    left: OUTLINE_POSITION,
    borderWidth: OUTLINE_WIDTH,
    borderColor: tokens.colors.$transparent,
  },
});
