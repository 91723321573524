import { StyleSheet } from 'react-native';

import { tokens } from '../../tokens';
import { XStack } from '../stack';

export function Divider() {
  return <XStack style={styles.divider} />;
}

const styles = StyleSheet.create({
  divider: {
    backgroundColor: tokens.colors.$blackOpacity10,
    height: StyleSheet.hairlineWidth,
    borderRadius: 10,
    marginVertical: 40,
  },
});
