import { CartModel, Discount } from '@fhs/cart';
import { computeSubtotal } from '@fhs/cart/src/utils/compute-subtotal';
import { getCurrentSession } from '@fhs-legacy/frontend/src/remote/auth';

import { GetLoyaltyIncentivesParams, LoyaltyIncentives, OfferMock } from '../types';

import { data } from './mock.data';

export async function receiveDiscounts(cart: CartModel): Promise<Discount[]> {
  const incentive = cart.appliedIncentives[0];

  if (!incentive) {
    return [];
  }

  switch (incentive.type) {
    case 'GLOBAL_DISCOUNT':
      return [
        {
          appliedCartEntries: [],
          savingsAmount: 400,
          type: 'GLOBAL_DISCOUNT',
        },
      ];
    case 'REWARD':
      const entryId = cart.appliedIncentives[0].appliedCartEntries[0];
      const entry = cart.entries.find(({ lineId }) => lineId === entryId);
      return [
        {
          appliedCartEntries: cart.appliedIncentives[0].appliedCartEntries,
          savingsAmount: entry?.price ?? 0,
          type: 'REWARD',
        },
      ];
    default:
      return [];
  }
}

export async function validateCart(
  cart: CartModel
): Promise<{ state: 'VALID' | 'LOYALTY_INVALID' }> {
  if (cart.appliedIncentives.length > 0 && cart.entries.length === 0) {
    return { state: 'LOYALTY_INVALID' };
  }

  if (computeSubtotal(cart) === 0) {
    return {
      state: 'LOYALTY_INVALID',
    };
  }

  return {
    state: 'VALID',
  };
}

export async function getOffer(offerId: string): Promise<OfferMock> {
  const offer = data.find(o => o.id === offerId);
  if (offer) {
    return Promise.resolve(offer);
  }
  throw new Error('Could not find offer');
}

export async function getRewardsOffer(_itemId: string): Promise<OfferMock> {
  return {
    name: 'Using 6,000 Points',
    id: 'reward-6000',
    description: '',
    expiration: '',
    imageUrl: '',
    type: 'REWARD', // TODO: Type this properly
  };
}

// Loyalty will give us the cost per item in points and if it's
// able to be redeemed as a reward
export async function receivePointCosts(cart: CartModel): Promise<
  {
    lineId: string;
    points: number;
    rewardIsRedeemable: boolean;
  }[]
> {
  return cart.entries.map(entry => ({
    lineId: entry.lineId,
    points: 6000,
    rewardIsRedeemable: cart.appliedIncentives.length === 0,
  }));
}

export async function getLoyaltyIncentives(
  params: GetLoyaltyIncentivesParams
): Promise<LoyaltyIncentives> {
  const session = await getCurrentSession();
  const bearer = session?.getIdToken().getJwtToken();
  try {
    const url =
      'https://dev-fhs-loyalty-middleware.rbictg.com/ui/offers?' +
      new URLSearchParams({
        serviceMode: params.serviceMode,
        storeId: params.storeId,
        ...(params.loyaltyId ? { loyaltyId: params.loyaltyId } : {}),
      }).toString();

    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${bearer}`,
        'Content-Type': 'application/json',
        'X-Ui-Language': 'en',
        'X-Ui-Platform': 'web',
        'X-Ui-Region': 'US',
        'X-Ui-Sha': 'c2fc001375a1722baee5826c17b236cbe98f4209',
        'X-Ui-Version': '9.2.1',
        'X-User-Datetime': '2024-07-18T11:21:17-05:00',
        'X-Platform-Framework': 'react-native',
        'X-Session-Id': '124194402184',
      },
    });
    const json = await res.json();

    if (json.message) {
      throw new Error(json.message);
    }
    return json;
  } catch (error) {
    throw error;
  }
}
