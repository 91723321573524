import { Link, router } from 'expo-router';
import { useEffect, useMemo } from 'react';
import {
  ActivityIndicator,
  FlatList,
  SafeAreaView,
  SectionList,
  StyleSheet,
  View,
} from 'react-native';

import { getMenuItemData } from '@fhs/menu/src/data/__mocks__/menu-item-data';
import { MenuItem } from '@fhs/menu/src/types';
import { Button, IconChevronDown, Text, tokens } from '@fhs/ui';

import { CartDonationSwitch } from '../components/cart-donation-switcher';
import { CartItem } from '../components/cart-item';
import { CartOfferItem } from '../components/cart-offer-item';
import { CartUpsellItem } from '../components/cart-upsell-item';
import { Debugger } from '../debugger';
import { Incentive, ItemEntry } from '../types';
import { computeSubtotal } from '../utils/compute-subtotal';

import { GetCart, PriceOrder, useMutation, useQuery } from './client';

type ItemTypeEntry = { type: 'Item'; entry: ItemEntry };
type OfferTypeEntry = { type: 'Offer'; entries: ItemEntry[]; incentive: Incentive };
type Entries = ItemTypeEntry | OfferTypeEntry;
type Data =
  | {
      title: 'Donation';
      data: { key: string }[];
    }
  | { title: 'Upsells'; data: any[] }
  | { title: 'CartEntries'; data: Entries[] };

export function CartScreen() {
  const { data: cart, fetching: loadingCart } = useQuery(GetCart);
  const [{ fetching, error }, priceOrder] = useMutation(PriceOrder);

  useEffect(() => {
    if (error) {
      alert(error.message);
    }
  }, [error]);

  const data = useMemo<Data[]>(() => {
    if (!cart) {
      return [];
    }
    return [
      { title: 'Donation', data: [{ key: 'donation' }] },
      {
        title: 'Upsells',
        // need something in here so it renders the entry
        data: [void 0],
      },
      {
        title: 'CartEntries',
        data: mapOffersAndEntries(cart.appliedIncentives, cart.entries),
      },
    ];
  }, [cart?.entries, cart?.appliedIncentives]);

  if (loadingCart) {
    return (
      <View style={styles.page}>
        <ActivityIndicator />
      </View>
    );
  }

  // TODO: Show an error state
  if (!cart) {
    return null;
  }

  return (
    <View style={styles.page}>
      <Debugger />
      <SectionList
        sections={data}
        style={styles.flex1}
        contentContainerStyle={[styles.container, styles.list]}
        ListEmptyComponent={() => {
          return (
            <View style={{ flex: 1, padding: 16, alignItems: 'center' }}>
              <Text.Heading type="one">No Cart Entries</Text.Heading>
            </View>
          );
        }}
        renderSectionHeader={({ section }) => {
          switch (section.title) {
            case 'Upsells':
              return (
                <Text.Ui size="md" weight="bold">
                  Add To Order
                </Text.Ui>
              );
            case 'CartEntries':
              return (
                <Text.Ui size="md" weight="bold">
                  Cart Items
                </Text.Ui>
              );
            default:
              return null;
          }
        }}
        renderItem={({ item, section }) => {
          switch (section.title) {
            case 'Donation':
              return <CartDonationSwitch donationAmount={cart?.donationAmount ?? 0} />;
            case 'Upsells':
              return <UpsellFlatList />;
            case 'CartEntries':
              switch (item.type) {
                case 'Item':
                  return (
                    <View style={styles.itemContainer}>
                      <CartItem
                        item={item.entry}
                        canUsePoints={!!item.entry.pointCost}
                        points={item.entry.pointCost ?? 0}
                      />
                    </View>
                  );
                case 'Offer':
                  return (
                    <View style={styles.itemContainer}>
                      <CartOfferItem incentive={item.incentive} entries={item.entries} />
                    </View>
                  );
              }
            default:
              return null;
          }
        }}
      />
      <SafeAreaView>
        <View style={styles.subtotalContainer}>
          <View style={[styles.container, styles.subtotalRow]}>
            <Text.Ui size="md" weight="semibold">
              Subtotal:
            </Text.Ui>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text.Ui size="md" weight="semibold">
                ${computeSubtotal(cart) / 100}
              </Text.Ui>
              <IconChevronDown color={tokens.colors.$houseRedDarken} size={24} />
            </View>
          </View>
        </View>
        <View style={styles.container}>
          <Link
            href="/v2/cart/(checkout)"
            asChild
            onPress={async e => {
              e.preventDefault();
              await priceOrder();
              router.replace('/v2/cart/(checkout)');
            }}
          >
            <Button loading={fetching}>
              <Button.Text>Continue</Button.Text>
            </Button>
          </Link>
        </View>
      </SafeAreaView>
    </View>
  );
}

function UpsellFlatList() {
  /* TODO: SimplyBetter - how do we want to choose what items to add to order */
  const upsells: MenuItem[] = [
    getMenuItemData('firehouse-hero')!,
    getMenuItemData('hook-and-ladder')!,
  ];

  return (
    <FlatList
      data={upsells}
      contentContainerStyle={styles.upsellList}
      keyExtractor={item => item?.id}
      horizontal
      renderItem={({ item }) => {
        return <CartUpsellItem {...item} />;
      }}
    />
  );
}

const styles = StyleSheet.create({
  upsellList: {
    gap: 12,
  },
  page: {
    backgroundColor: 'white',
    borderTopColor: tokens.colors.$black10,
    borderTopWidth: 1,
    flex: 1,
  },
  flex1: {
    flex: 1,
  },
  container: {
    marginHorizontal: 'auto',
    padding: 16,
    width: 375,
  },
  list: {
    gap: 16,
  },
  subtotalContainer: {
    backgroundColor: tokens.colors.$houseLight,
    width: '100%',
    height: 48,
  },
  subtotalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemContainer: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: tokens.colors.$black10,
  },
});

// cart entries contains all items in the basket
// but in our UI we need to bisect the array into two seperate
// arrays. One of normal cart entries, and one where the entries
// are paired with the offer that "owns" them.
// - offers should always be displayed first
function mapOffersAndEntries(
  appliedIncentives: Incentive[] = [],
  oEntries: ItemEntry[] = []
): Entries[] {
  const entries = [...oEntries].filter(
    ({ lineId }) => !appliedIncentives.some(s => s.appliedCartEntries.includes(lineId))
  );

  return [
    ...appliedIncentives.map(
      (incentive): OfferTypeEntry => ({
        type: 'Offer',
        incentive,
        entries: oEntries.filter(({ lineId }) => incentive.appliedCartEntries.includes(lineId)),
      })
    ),
    ...entries.map(
      (entry): ItemTypeEntry => ({
        type: 'Item',
        entry,
      })
    ),
  ];
}
