import { getCurrentSession } from '@fhs-legacy/frontend/src/remote/auth';

import { Logger } from '../../debugger';

import { LegacyPriceOrderInput } from './types';

export async function fetchPriceOrderMutation(v1cart: LegacyPriceOrderInput, logger: Logger) {
  logger.log('pricing transformed cart');
  const session = await getCurrentSession();
  const bearer = session?.getIdToken().getJwtToken();

  const res = await fetch('https://use1-dev-fhs-gateway.rbictg.com/graphql', {
    method: 'POST',
    body: JSON.stringify({
      query:
        'mutation PriceOrder($input: PriceOrderInput!, $delivery: PriceDeliveryInput) {\n  priceOrder(input: $input, delivery: $delivery) {rbiOrderId,status}}',
      variables: {
        delivery: null,
        input: v1cart,
      },
    }),
    headers: {
      Authorization: `Bearer ${bearer}`,
      'Content-Type': 'application/json',
      'X-Ui-Language': 'en',
      'X-Ui-Platform': v1cart.platform,
      'X-Ui-Region': 'US',
      'X-Ui-Sha': 'c2fc001375a1722baee5826c17b236cbe98f4209',
      'X-Ui-Version': '9.2.1',
      'X-User-Datetime': '2024-07-18T11:21:17-05:00',
      'X-Platform-Framework': 'react-native',
      'X-Session-Id': '124194402184',
    },
  });

  const json = await res.json();

  return json.data.priceOrder;
}
