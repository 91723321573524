import { ImageBackground, ImageBackgroundProps } from 'expo-image';
import { Link } from 'expo-router';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';

import { Button, Text, YStack, tokens, useMqSelect } from '@fhs/ui';
import { useClickLogs } from '@fhs-legacy/frontend/src/hooks/use-click-logs';

export type RapidRescueCardProps = Omit<ImageBackgroundProps, 'children' | 'source'> & {
  pickUpHref: string;
};
export function RapidRescueCard({ pickUpHref, ...props }: RapidRescueCardProps) {
  const { logOrderNowClickEvent } = useClickLogs();
  const { formatMessage } = useIntl();
  const buttonText = formatMessage({ id: 'orderNow' });

  return (
    <ImageBackground
      source={require('./rapid-rescue-card-background.webp')}
      placeholder={{ thumbhash: 'MTkOK4Zvh4lnech2gIgDmSg' }}
      {...props}
      style={[styles.rapidRescueBanner, props.style]}
    >
      <YStack style={styles.contentStack}>
        <Text weight="bold" style={styles.bannerText}>
          Avoid the Wait. Order Online.
        </Text>
        <Link href={pickUpHref} asChild>
          <Button
            style={styles.button}
            hoveredStyle={styles.button}
            focusedStyle={styles.button}
            size={useMqSelect({ $gteDesktopLg: 'xl' }, 'lg')}
            onPress={logOrderNowClickEvent}
          >
            <Button.Text>{buttonText}</Button.Text>
          </Button>
        </Link>
      </YStack>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  rapidRescueBanner: {
    aspectRatio: 1920 / 757,
  },
  contentStack: {
    position: 'absolute',
    alignItems: 'center',
    paddingHorizontal: 16,
    left: 0,
    right: '57.25%',
    top: '55%',
    gap: 8,
  },
  button: {
    paddingHorizontal: 24,
    marginTop: 16,
  },
  bannerText: {
    color: tokens.colors.$houseDark,
    fontSize: 25,
    lineHeight: 32,
    textAlign: 'center',
    justifyContent: 'center',
  },
});
