import { getCurrentSession } from '@fhs-legacy/frontend/src/remote/auth';

import { Logger } from '../../debugger';

export async function fetchCommitOrderMutation(
  rbiOrderId: string,
  logger: Logger
): Promise<{ status: 'INSERT_REQUESTED' | 'INSERT_SUCCESSFUL'; rbiOrderId: string }> {
  logger.log('Committing Order', rbiOrderId);
  const session = await getCurrentSession();
  const bearer = session?.getIdToken().getJwtToken();

  const res = await fetch('https://use1-dev-fhs-gateway.rbictg.com/graphql', {
    method: 'POST',
    body: JSON.stringify({
      query:
        'mutation CommitOrder($input: CommitOrderInput!, $delivery: CommitDeliveryInput, $skipCoolingPeriod: Boolean) {commitOrder(input: $input,delivery: $delivery, skipCoolingPeriod: $skipCoolingPeriod) {rbiOrderId,status}}',
      variables: {
        delivery: null,
        skipCoolingPeriod: false, // what is this?
        input: {
          rbiOrderId,
          creditType: 'VISA',
          fireOrderIn: 0,
          payment: {
            fullName: 'John Smith',
            worldpayInput: {
              storedPaymentMethodId: '4445226050090007',
            },
          },
        },
      },
    }),
    headers: {
      Authorization: `Bearer ${bearer}`,
      'Content-Type': 'application/json',
      'X-Ui-Language': 'en',
      'X-Ui-Platform': 'web',
      'X-Ui-Region': 'US',
      'X-Ui-Sha': 'c2fc001375a1722baee5826c17b236cbe98f4209',
      'X-Ui-Version': '9.2.1',
      'X-User-Datetime': '2024-07-18T11:21:17-05:00',
      'X-Platform-Framework': 'react-native',
      'X-Session-Id': '124194402184',
    },
  });

  const json = await res.json();

  if (!json.data && json.errors) {
    throw json.errors;
  }

  return json.data.commitOrder;
}
