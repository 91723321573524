import { router, useLocalSearchParams, useSegments } from 'expo-router';

import { ActionSheet } from '@fhs/ui';

import { PaymentMethodSelection } from '../components/action-payment-method-selection';
import { CartActionRemoveItem } from '../components/cart-action-remove-item';
import { CartActionRemoveOffer } from '../components/cart-action-remove-offer';

import { CartScreen as CartReviewScreen } from './_cart';
import { CheckoutScreen } from './_checkout';

const onCloseCart = () => router.replace('/v2/cart');
const onCloseCheckout = () => router.replace('/v2/cart/(checkout)');

export function CartScreen() {
  const { action, id } = useLocalSearchParams<{
    action: 'remove-offer' | 'remove-item' | 'time' | 'service-mode' | 'payment';
    id: string;
  }>();
  const segments = useSegments();
  const showTimeSelector = action === 'time';
  const showPaymentSelector = action === 'payment';
  const showServiceModeSelector = action === 'service-mode';
  const showRemoveOfferSelector = action === 'remove-offer';
  const showRemoveItemSelector = action === 'remove-item';
  const isCheckout = segments.includes('(checkout)');

  return (
    <>
      {isCheckout ? <CheckoutScreen /> : <CartReviewScreen />}
      {showRemoveOfferSelector && (
        <ActionSheet isVisible onClose={onCloseCart}>
          <CartActionRemoveOffer id={id} onClose={onCloseCart} />
        </ActionSheet>
      )}
      {showRemoveItemSelector && (
        <ActionSheet isVisible onClose={onCloseCart}>
          <CartActionRemoveItem id={id} onClose={onCloseCart} />
        </ActionSheet>
      )}
      {showTimeSelector && (
        <ActionSheet isVisible onClose={onCloseCheckout}>
          time
        </ActionSheet>
      )}
      {showServiceModeSelector && (
        <ActionSheet isVisible onClose={onCloseCheckout}>
          service mode
        </ActionSheet>
      )}
      {showPaymentSelector && (
        <ActionSheet isVisible onClose={onCloseCheckout}>
          <PaymentMethodSelection />
        </ActionSheet>
      )}
    </>
  );
}
