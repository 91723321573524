import { Image } from 'expo-image';
import { ImageStyle, StyleProp, TouchableWithoutFeedback, View, ViewStyle } from 'react-native';

import { Button, IconInfoFill, Text, createMqStyles, tokens } from '@fhs/ui';
import { useIsMobileBp } from '@fhs-legacy/frontend/src/hooks/breakpoints';

export type OfferCardProps = {
  imageUrl: string;
  title: string;
  description: string;
  onPressButton: () => void;
  onPressImage: () => void;
  isDisabled?: boolean;
  buttonLabel: string;
  style?: StyleProp<ViewStyle>;
  isHidden?: boolean;
};

export function OfferCard({
  imageUrl,
  title,
  description,
  onPressButton,
  onPressImage,
  buttonLabel,
  isDisabled,
  style,
  isHidden,
}: OfferCardProps) {
  const styles = useMqStyles();
  const isMobile = useIsMobileBp();
  const infoIconSize = isMobile ? 16 : 20;
  if (isHidden) {
    return <View style={style} />;
  }
  return (
    <View style={[styles.container, style]}>
      <TouchableWithoutFeedback onPress={onPressImage}>
        <View style={styles.imageContainer}>
          <Image
            style={styles.image as StyleProp<ImageStyle>}
            source={{ uri: imageUrl }}
            contentFit="contain"
          />
          <IconInfoFill
            color={tokens.colors.$blackOpacity75}
            style={styles.infoIcon}
            size={infoIconSize}
          />
        </View>
      </TouchableWithoutFeedback>
      <View style={styles.contentContainer}>
        <View style={styles.titleContainer}>
          <Text.Ui size="md" weight="semibold" ellipsizeMode="tail" numberOfLines={2}>
            {title}
          </Text.Ui>
        </View>
        <Text.Ui size="sm" style={styles.description}>
          {description}
        </Text.Ui>
        <Button
          style={styles.buttonContainer}
          disabled={isDisabled}
          type="outline"
          size="xs"
          onPress={onPressButton}
        >
          <Button.Text style={styles.buttonLabel}>{buttonLabel}</Button.Text>
        </Button>
      </View>
    </View>
  );
}

const useMqStyles = createMqStyles({
  container: {
    $base: {
      width: 215,
      height: 297,
      borderColor: tokens.colors.$blackOpacity10,
      borderWidth: 1,
      borderRadius: 8,
      alignItems: 'center',
      flex: 1,
    },
    $ltDesktop: {
      width: 160,
      height: 232,
    },
  },
  contentContainer: {
    $base: {
      flex: 1,
      padding: 16,
      width: '100%',
    },
    $ltDesktop: {
      padding: 12,
    },
  },
  titleContainer: {
    $base: {
      flex: 1,
    },
  },
  imageContainer: {
    $base: {
      height: 140,
      width: '100%',
      borderTopStartRadius: 8,
      borderTopEndRadius: 8,
      backgroundColor: tokens.colors.$white,
      borderBottomWidth: 1,
      borderBottomColor: tokens.colors.$black10,
      overflow: 'hidden',
    },
    $ltDesktop: {
      height: 96,
    },
  },
  image: {
    $base: {
      flex: 1,
    },
  },
  infoIcon: {
    $base: {
      position: 'absolute',
      right: 12,
      top: 12,
    },
    $ltDesktop: {
      right: 8,
      top: 8,
    },
  },
  description: {
    $base: {
      marginTop: 12,
    },
    $ltDesktop: {
      marginTop: 8,
    },
  },
  buttonContainer: {
    $base: {
      marginTop: 16,
      height: 34,
    },
    $ltDesktop: {
      height: 31,
    },
  },
  buttonLabel: {
    $ltDesktop: {
      fontSize: 10,
      lineHeight: 14,
    },
  },
});
