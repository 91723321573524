import { useId } from 'react';
import { Defs, Path, RadialGradient, Stop, Svg, type SvgProps } from 'react-native-svg';

export type FaviconProps = Omit<SvgProps, 'width' | 'height'> & {
  size?: number;
};

export const Favicon = ({ size = 40, ...otherProps }: FaviconProps) => {
  const idA = useId();
  const idB = useId();

  return (
    <Svg width={size} height={size} fill="none" viewBox="0 0 46 46" {...otherProps}>
      <Path
        stroke="#B4911E"
        strokeMiterlimit={10}
        strokeWidth={1.25}
        d="M45.5 23.196c0-1.882-.38-4.47-2.406-7.357-2.01-2.886-1.678-5.866-1.678-5.866s0-.534-.458-.722c-.46-.173-.792.11-.792.11l-6.125 4.015c.839.926-1.393-1.396-.617-.643l4.036-6.055s.285-.329.11-.784c-.174-.455-.727-.455-.727-.455s-3.008.33-5.92-1.663c-2.912-1.992-5.524-2.384-7.423-2.384-1.9 0-4.51.377-7.423 2.384-2.912 1.993-5.92 1.663-5.92 1.663s-.538 0-.727.455c-.175.455.11.784.11.784l3.672 5.506c.902-.8-2.279 2.26-.854.8L6.834 9.361s-.333-.283-.792-.11-.459.722-.459.722.333 2.98-1.677 5.866C1.896 18.725 1.5 21.314 1.5 23.196c0 1.882.38 4.47 2.406 7.357 2.01 2.886 1.677 5.867 1.677 5.867s0 .533.46.721a.778.778 0 0 0 .79-.11l5.525-3.623c-1.33-1.365 2.105 1.898.854.8L9.54 39.714s-.285.33-.11.784c.174.455.728.455.728.455s3.007-.33 5.919 1.663C18.989 44.608 21.601 45 23.5 45c1.9 0 4.51-.377 7.423-2.384 2.912-1.992 5.92-1.663 5.92-1.663s.538 0 .727-.455c.19-.455-.11-.784-.11-.784l-4.036-6.055c-1.172 1.145 1.788-1.93.617-.643l6.125 4.015s.333.283.792.11c.459-.172.459-.721.459-.721s-.333-2.98 1.677-5.867c2.01-2.886 2.406-5.475 2.406-7.357Z"
      />
      <Path
        stroke="#EBA518"
        strokeMiterlimit={10}
        d="M45.5 22.804c0-1.882-.38-4.47-2.406-7.357-2.01-2.886-1.678-5.867-1.678-5.867s0-.533-.458-.721c-.46-.173-.792.11-.792.11l-6.125 4.015c.839.926-1.393-1.396-.617-.643l4.036-6.055s.285-.33.11-.784c-.174-.455-.727-.455-.727-.455s-3.008.33-5.92-1.663C28.011 1.392 25.399 1 23.5 1c-1.9 0-4.51.376-7.423 2.384-2.912 1.992-5.92 1.663-5.92 1.663s-.538 0-.727.455c-.175.455.11.784.11.784l3.672 5.506c.902-.8-2.279 2.259-.854.8L6.834 8.97s-.333-.283-.792-.11c-.459.172-.459.721-.459.721s.333 2.98-1.677 5.867C1.896 18.333 1.5 20.922 1.5 22.804c0 1.882.38 4.47 2.406 7.357 2.01 2.886 1.677 5.866 1.677 5.866s0 .534.46.722c.458.188.79-.11.79-.11l5.525-3.623c-1.33-1.365 2.105 1.898.854.8L9.54 39.322s-.285.329-.11.784c.174.455.728.455.728.455s3.007-.33 5.919 1.663c2.912 1.992 5.524 2.384 7.423 2.384 1.9 0 4.51-.377 7.423-2.384 2.912-1.993 5.92-1.663 5.92-1.663s.538 0 .727-.455c.19-.455-.11-.784-.11-.784l-4.036-6.055c-1.172 1.145 1.788-1.93.617-.644l6.125 4.016s.333.283.792.11c.459-.172.459-.722.459-.722s-.333-2.98 1.677-5.866c2.01-2.887 2.406-5.475 2.406-7.357Z"
      />
      <Path
        fill={`url(#${idA})`}
        d="M45.5 22.804c0-1.882-.38-4.47-2.406-7.357-2.01-2.886-1.678-5.867-1.678-5.867s0-.533-.458-.721c-.46-.173-.792.11-.792.11l-6.125 4.015c.839.926-1.393-1.396-.617-.643l4.036-6.055s.285-.33.11-.784c-.174-.455-.727-.455-.727-.455s-3.008.33-5.92-1.663C28.011 1.392 25.399 1 23.5 1c-1.9 0-4.51.376-7.423 2.384-2.912 1.992-5.92 1.663-5.92 1.663s-.538 0-.727.455c-.175.455.11.784.11.784l3.672 5.506c.902-.8-2.279 2.259-.854.8L6.834 8.97s-.333-.283-.792-.11c-.459.172-.459.721-.459.721s.333 2.98-1.677 5.867C1.896 18.333 1.5 20.922 1.5 22.804c0 1.882.38 4.47 2.406 7.357 2.01 2.886 1.677 5.866 1.677 5.866s0 .534.46.722c.458.188.79-.11.79-.11l5.525-3.623c-1.33-1.365 2.105 1.898.854.8L9.54 39.322s-.285.329-.11.784c.174.455.728.455.728.455s3.007-.33 5.919 1.663c2.912 1.992 5.524 2.384 7.423 2.384 1.9 0 4.51-.377 7.423-2.384 2.912-1.993 5.92-1.663 5.92-1.663s.538 0 .727-.455c.19-.455-.11-.784-.11-.784l-4.036-6.055c-1.172 1.145 1.788-1.93.617-.644l6.125 4.016s.333.283.792.11c.459-.172.459-.722.459-.722s-.333-2.98 1.677-5.866c2.01-2.887 2.406-5.475 2.406-7.357Z"
      />
      <Path
        fill="#01000B"
        d="M8.258 22.804c0 8.345 6.822 15.09 15.226 15.09s15.242-6.76 15.242-15.09-6.822-15.09-15.242-15.09c-8.42 0-15.226 6.76-15.226 15.09Z"
      />
      <Path
        fill="#B4911E"
        d="M23.484 7.714c8.42 0 15.242 6.76 15.242 15.09s-6.822 15.09-15.242 15.09c-8.42 0-15.226-6.76-15.226-15.09s6.822-15.09 15.226-15.09Zm0-.785c-8.831 0-16.017 7.122-16.017 15.875s7.186 15.874 16.017 15.874c8.832 0 16.033-7.121 16.033-15.874S32.332 6.929 23.484 6.929Z"
      />
      <Path
        stroke="#EBA518"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M8.258 22.506c0 8.345 6.822 15.09 15.226 15.09s15.242-6.76 15.242-15.09-6.822-15.09-15.242-15.09c-8.42 0-15.226 6.76-15.226 15.09Z"
      />
      <Path fill="#210000" d="m22.31 29.4-4.717.007.002 1.192 4.717-.008-.002-1.192Z" />
      <Path
        fill={`url(#${idB})`}
        d="M29.546 18.631c-.032-.298-.08-.486-.142-.596-.064-.11-.159-.157-.285-.157l-7.708.267v3.059l5.413-.58a.32.32 0 0 0 .285-.142c.063-.094.11-.282.142-.58h.27v5.27h-.27c-.032-.36-.08-.58-.142-.69-.064-.11-.159-.156-.285-.156l-5.413.58v5.506c0 .141.031.267.11.36.08.095.254.158.555.205v.376l-4.891 1.224v-.644c.3-.047.475-.11.554-.203a.651.651 0 0 0 .11-.361l-.031-16.126a.61.61 0 0 0-.095-.36c-.063-.095-.253-.173-.554-.204v-.377h11.965a.316.316 0 0 0 .285-.172c.064-.11.111-.346.143-.706h.285v5.207h-.301Z"
      />
      <Path
        fill="#000"
        d="M29.404 14.145a.316.316 0 0 1-.285.173H17.153v.376c.174.032.301.063.396.11v-.25h11.966a.315.315 0 0 0 .284-.174s0-.03.016-.047v-.894h-.285c-.031.361-.079.596-.142.706h.016ZM21.917 31.008s.08.078.159.094v-.125s-.127-.016-.175-.032c0 .016.016.047.032.063h-.016ZM27.093 20.483a.32.32 0 0 1-.285.14l-5.002.55v.25l5.413-.58a.32.32 0 0 0 .285-.14s0-.032.016-.032v-.785h-.269c-.032.298-.08.487-.142.58l-.016.017ZM29.515 18.114h-.08c.048.11.08.282.111.517h.285v-.329s0-.031-.016-.031c-.063-.11-.158-.157-.285-.157h-.015ZM27.22 24.56h-.08c.048.126.08.33.111.613h.27v-.408s0-.032-.017-.047c-.063-.11-.158-.157-.285-.157ZM18.103 15.133s-.19-.14-.38-.172c.047.078.08.188.08.298l.03 16.125a.546.546 0 0 1-.11.361c-.063.094-.253.157-.554.204v.643l.396-.063v-.36c.3-.047.475-.377.554-.47a.651.651 0 0 0 .11-.362l-.031-15.843a.61.61 0 0 0-.095-.36Z"
        opacity={0.25}
      />
      <Path
        fill="#01000B"
        d="M29.815 12.859h-.269c-.3 0-.538.22-.57.518 0 .156-.031.282-.047.36h-11.76c-.158 0-.3.063-.411.173a.532.532 0 0 0-.174.392v.377c0 .282.205.517.49.564.063 0 .11.016.158.032l.032 16.14s-.095.048-.174.095c-.38.204-.49.408-.49.69v.377c0 .157.078.329.19.439.11.094.237.125.38.125h.078l4.875-1.223a.551.551 0 0 0 .49-.565v-.376a.574.574 0 0 0-.49-.565c-.08 0-.127-.016-.174-.031v-4.957l4.669-.534.032.33a.556.556 0 0 0 .57.517h.284a.542.542 0 0 0 .396-.172.56.56 0 0 0 .174-.392v-5.27a.624.624 0 0 0-.158-.409.578.578 0 0 0-.412-.172h-.285a.59.59 0 0 0-.57.502c0 .11-.015.188-.031.235l-4.669.533V18.71l6.964-.267s.016.141.032.251a.578.578 0 0 0 .57.502h.268c.159 0 .301-.063.396-.172a.567.567 0 0 0 .174-.408v-5.192a.532.532 0 0 0-.174-.393.57.57 0 0 0-.395-.172h.031Zm0 5.772h-.285c-.031-.297-.079-.486-.142-.596-.064-.11-.159-.156-.285-.156l-7.708.266v3.06l5.413-.581a.32.32 0 0 0 .285-.141c.063-.095.11-.283.142-.58h.27v5.27h-.27c-.031-.361-.079-.58-.142-.69-.063-.11-.158-.157-.285-.157l-5.413.58v5.506c0 .141.032.267.11.36.08.095.254.158.555.205v.376l-4.89 1.224v-.534c.3-.047.474-.22.553-.313a.652.652 0 0 0 .11-.361l-.03-16.126a.61.61 0 0 0-.096-.36c-.063-.095-.253-.173-.554-.204v-.377H29.12a.315.315 0 0 0 .285-.172c.063-.11.11-.346.142-.706h.285v5.207h-.016Z"
      />
      <Defs>
        <RadialGradient
          id={idA}
          cx={0}
          cy={0}
          r={1}
          gradientTransform="matrix(22.0158 0 0 21.8196 23.484 22.804)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0.47} stopColor="#D71921" />
          <Stop offset={0.59} stopColor="#D3181F" />
          <Stop offset={0.69} stopColor="#C9151C" />
          <Stop offset={0.79} stopColor="#B81117" />
          <Stop offset={0.88} stopColor="#A00B0F" />
          <Stop offset={0.96} stopColor="#810405" />
          <Stop offset={1} stopColor="#700000" />
        </RadialGradient>
        <RadialGradient
          id={idB}
          cx={0}
          cy={0}
          r={1}
          gradientTransform="matrix(58.9252 0 0 58.4 76.031 18.663)"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0.31} stopColor="#fff" />
          <Stop offset={0.53} stopColor="#FAFAFA" />
          <Stop offset={0.78} stopColor="#EDEEEE" />
          <Stop offset={1} stopColor="#DBDCDD" />
        </RadialGradient>
      </Defs>
    </Svg>
  );
};
