import { StyleSheet } from 'react-native';

import { IconCheck, IconRemove, tokens } from '../..';

import { CheckboxPressable, type CheckboxPressableProps } from './checkbox-pressable';
import { useCheckbox } from './context';
import type { CheckedState } from './types';

const checkBoxContent = (checked: CheckedState) => {
  if (checked === 'mixed') {
    return <IconRemove color={tokens.colors.$white} size={18} />;
  }

  if (checked) {
    return <IconCheck color={tokens.colors.$white} size={18} />;
  }

  return null;
};

export type CheckboxControlProps = CheckboxPressableProps;

export const CheckboxControl = (props: CheckboxControlProps) => {
  const checkbox = useCheckbox();

  const isCheckboxSelected = Boolean(checkbox.checked); // purposefully casting 'mixed' to true;

  return (
    <CheckboxPressable
      {...props}
      style={[styles.checkbox, isCheckboxSelected && styles.checkboxSelected]}
      outlineFocusedStyle={
        isCheckboxSelected ? styles.outlineFocusedSelected : styles.outlineFocused
      }
      hoveredStyle={!isCheckboxSelected && styles.hovered}
      disabledStyle={isCheckboxSelected && styles.disabledSelected}
    >
      {checkBoxContent(checkbox.checked)}
    </CheckboxPressable>
  );
};

const styles = StyleSheet.create({
  checkbox: {
    alignSelf: 'center',
    width: 20,
    height: 20,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity30,
    justifyContent: 'center',
    borderRadius: 4,
  },
  checkboxSelected: {
    backgroundColor: tokens.colors.$houseRedDarken,
    borderColor: tokens.colors.$transparent,
  },
  // hover
  hovered: {
    borderColor: tokens.colors.$houseDark,
  },
  // disabled
  disabledSelected: {
    backgroundColor: tokens.colors.$houseRedOpacity10,
  },
  // Focus
  outlineFocused: {},
  outlineFocusedSelected: {
    borderColor: tokens.colors.$houseRedDarkenOpacity10,
  },
});
