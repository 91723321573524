import { useCallback, useRef } from 'react';

import { useDisclose } from '@fhs-legacy/universal-components';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { useOpenClosedText } from 'pages/store-locator/new-ui/store-card/hooks/use-open-closed-text';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useServiceModeContext } from 'state/service-mode';
import DateSingleton, { DateSingletonInstanceKey } from 'utils/dateTime/DateSingleton';

import { StoreClosedActionSheet } from './StoreClosedActionSheet';
import { IUseStoreClosedActionSheetHook } from './types';

const dateSingletonInstance = DateSingleton.getInstance(
  DateSingletonInstanceKey.storeClosedConfirmation
);

/**
 * Hook for request user confirmation on store closed future ordering
 */
export const useStoreClosedActionSheet: IUseStoreClosedActionSheetHook = ({
  restaurant,
  shouldCheckSingletonDate,
  isCatering,
}) => {
  const { isOpen, onClose, onOpen } = useDisclose();
  const enableFutureOrdering = useFlag(LaunchDarklyFlag.ENABLE_FUTURE_ORDERING);
  const requestResolverRef = useRef<(param: boolean) => void>();
  const { isOpen: isRestaurantOpen } = useOpenClosedText(restaurant as IRestaurantNode);
  const { isDelivery } = useServiceModeContext();

  const handleChangeRestaurant = useCallback(() => {
    dateSingletonInstance.saveDate();
    onClose();
    requestResolverRef.current?.(false);
  }, [onClose]);
  const handleScheduleFutureOrder = useCallback(() => {
    dateSingletonInstance.saveDate();
    onClose();
    requestResolverRef.current?.(true);
  }, [onClose]);

  const requestScheduleFutureOrderingConfirmation = useCallback(async () => {
    if (!enableFutureOrdering || isRestaurantOpen || isCatering || isDelivery) {
      return true;
    }
    if (shouldCheckSingletonDate) {
      // If singletonDate date haven't expired, exit from request confirmation flow
      if (!dateSingletonInstance.isDateExpired()) {
        return Promise.resolve(true);
      }
      // Otherwise save current date and request user confirmation
      dateSingletonInstance.saveDate();
    }
    onOpen();
    return new Promise<boolean>(resolve => {
      requestResolverRef.current = resolve;
    });
  }, [
    enableFutureOrdering,
    isRestaurantOpen,
    onOpen,
    shouldCheckSingletonDate,
    isCatering,
    isDelivery,
  ]);
  const storeClosedActionSheet = enableFutureOrdering ? (
    <StoreClosedActionSheet
      restaurant={restaurant}
      isOpen={isOpen}
      onChangeRestaurant={handleChangeRestaurant}
      onScheduleFutureOrder={handleScheduleFutureOrder}
    />
  ) : null;
  return {
    requestScheduleFutureOrderingConfirmation,
    storeClosedActionSheet,
  };
};
