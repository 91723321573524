import { ActivityIndicator, Image, StyleSheet, View } from 'react-native';

import { Button, Text, tokens } from '@fhs/ui';

import { GetCart, RemoveItemFromCart, useMutation, useQuery } from '../../screens/client';

type Props = {
  id: string;
  onClose(): void;
};

export function CartActionRemoveItem(props: Props) {
  const { data: cart, fetching } = useQuery(GetCart);
  const [{ fetching: removeItemFromCartFetching }, removeItemFromCart] = useMutation(
    RemoveItemFromCart,
    { lineId: props.id }
  );

  if (fetching || removeItemFromCartFetching) {
    return <ActivityIndicator size="large" />;
  }

  const item = cart?.entries.find(entry => entry.lineId === props.id);

  if (!item) {
    props.onClose();
  }

  return (
    <View style={styles.container}>
      <Text.Heading type="one">Remove Item</Text.Heading>
      <Text.Ui size="md" style={styles.copy}>
        Are you sure you want to remove this item from your cart?
      </Text.Ui>

      <View style={styles.offerContainer}>
        <Image source={{ uri: item?.imageUrl }} style={styles.image} />
        <Text.Ui size="md" weight="semibold" numberOfLines={1}>
          {item?.displayName}
        </Text.Ui>
      </View>

      <View style={styles.buttonGroup}>
        <Button
          size="lg"
          onPress={async () => {
            await removeItemFromCart();
            props.onClose();
          }}
        >
          <Button.Text>Remove</Button.Text>
        </Button>
        <Button size="lg" type="outline" onPress={props.onClose}>
          <Button.Text>Cancel</Button.Text>
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  image: {
    aspectRatio: 2 / 1,
    width: 56,
  },
  offerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
    gap: 12,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: tokens.colors.$black10,
    height: 48,
    width: '100%',
    marginBottom: 20,
  },
  copy: {
    paddingTop: 12,
    paddingBottom: 24,
  },
  buttonGroup: {
    gap: 8,
  },
  container: {
    maxWidth: 334,
    marginHorizontal: 'auto',
  },
});
