import { Logger } from '../../debugger';
import { Store } from '../../types';

type Response = {
  result: {
    name: string;
    latitude: number;
    longitude: number;
    posRestaurantId: string | null;
    phoneNumber: string | null;
    posVendor: string | null;
    physicalAddress?: {
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      country?: string | null;
      stateProvinceShort?: string | null;
      postalCode?: string | null;
    };
  };
};

export async function fetchStoreInformation(id: string, logger: Logger): Promise<Store> {
  logger.log('Fetching store information from sanity');

  const query = encodeURIComponent(
    `*[_type == 'restaurant' && number == '${id}']{posRestaurantId,name,latitude,longitude,phoneNumber,physicalAddress,'posVendor': pos.vendor}[0]`
  );

  const res = await fetch(
    `https://czqk28jt.apicdn.sanity.io/v2021-03-25/data/query/dev_fhs_us?query=${query}`
  );

  const json = ((await res.json()) as Response).result;
  logger.log(json);

  return {
    id,
    name: json.name ?? '',
    posRestaurantId: json.posRestaurantId ?? '',
    posVendor: json.posVendor ?? '',
    latitude: json.latitude ?? null,
    longitude: json.longitude ?? null,
    phoneNumber: json.phoneNumber ?? '',
    addressLine1: json?.physicalAddress?.address1 ?? '',
    addressLine2: json?.physicalAddress?.address2 ?? '',
    city: json?.physicalAddress?.city ?? '',
    state: json?.physicalAddress?.stateProvinceShort ?? '',
    country: json?.physicalAddress?.country ?? '',
    zip: json?.physicalAddress?.postalCode ?? '',
  };
}
