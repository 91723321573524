import {
  selectAppliedOffers,
  selectCmsOffers,
  selectCmsSurpriseOffers,
  selectCombinedOffers,
  selectDiscountAppliedCmsOffer,
  selectExtendedCmsOffersMap,
  selectIncentiveErrorsFilteredByCode,
  selectIncentiveErrorsWithCode,
  selectIncentivesIds,
  selectIsDiscountNotApplied,
  selectOfferEligibleItem,
  selectOfferFeedbackMap,
  selectOfferRedemptionAvailableAfter,
  selectOffers,
  selectOffersEligibleItems,
  selectOffersLoading,
  selectPersonalizedOffers,
  selectSelectedOffer,
  selectShouldRefetchOffers,
  selectSurpriseAvailable,
  selectUpsizeAvailable,
  selectUserOffers,
  selectValidAppliedOffers,
} from './offers/offers.selectors';
import {
  selectAppliedLoyaltyRewards,
  selectAppliedLoyaltyRewardsArray,
  selectAppliedPoints,
  selectAppliedRewardsFeedbackMap,
  selectAvailableLoyaltyRewardsMap,
  selectIsEvaluatingAppliedRewards,
  selectIsPricingRewardApplication,
  selectMaxDailyRewardRedemptionLimit,
  selectShouldRefetchRewards,
  selectStagedCartPoints,
  selectTotalTimesRewardApplied,
} from './rewards/rewards.selectors';
import {
  select12DigitSwipeCard,
  selectDailyRewardTransactionCount,
  selectLoyaltyLoading,
  selectLoyaltyUser,
  selectLoyaltyUserExpireDateFormattedText,
  selectLoyaltyUserId,
  selectUser,
} from './user/user.selectors';

export const loyaltySelectors = {
  // Offer actions
  selectAppliedOffers,
  selectCmsOffers,
  selectCmsSurpriseOffers,
  selectExtendedCmsOffersMap,
  selectIncentiveErrorsFilteredByCode,
  selectIncentiveErrorsWithCode,
  selectIncentivesIds,
  selectOfferFeedbackMap,
  selectOffersEligibleItems,
  selectOfferEligibleItem,
  selectOffers,
  selectOffersLoading,
  selectOfferRedemptionAvailableAfter,
  selectPersonalizedOffers,
  selectSelectedOffer,
  selectSurpriseAvailable,
  selectUpsizeAvailable,
  selectUserOffers,
  selectValidAppliedOffers,
  selectDiscountAppliedCmsOffer,
  selectIsDiscountNotApplied,
  selectShouldRefetchOffers,
  selectCombinedOffers,
  // Rewards actions
  selectAppliedLoyaltyRewards,
  selectAppliedLoyaltyRewardsArray,
  selectAppliedPoints,
  selectAvailableLoyaltyRewardsMap,
  selectIsPricingRewardApplication,
  selectShouldRefetchRewards,
  selectStagedCartPoints,
  selectTotalTimesRewardApplied,
  selectAppliedRewardsFeedbackMap,
  selectIsEvaluatingAppliedRewards,
  selectMaxDailyRewardRedemptionLimit,
  // User actions
  select12DigitSwipeCard,
  selectLoyaltyLoading,
  selectUser,
  selectLoyaltyUser,
  selectLoyaltyUserId,
  selectDailyRewardTransactionCount,
  selectLoyaltyUserExpireDateFormattedText,
};
