import { ErrorBoundaryProps, Stack } from 'expo-router';
import * as SplashScreen from 'expo-splash-screen';
import { LogBox, Text, View } from 'react-native';

import { MqSwitch } from '@fhs/ui';
import AppWrapper from '@fhs-legacy/frontend/src/app';
import { AppContainer } from '@fhs-legacy/frontend/src/components/app-container';
import { PypestreamWrapper as Pypestream } from '@fhs-legacy/frontend/src/pages/pypestream';
import { isMobile, isSSG } from '@fhs-legacy/frontend/src/utils/environment';

import { AppHeader } from '../components/app-header';
import { getScreenTitleHeader } from '../components/get-screen-title-header';
import { MaxCartAmountToast } from '../components/max-cart-amount-toast';

const appHeaderDesktopOnly = () => (
  <MqSwitch $ltDesktop={() => null} $gteDesktop={() => <AppHeader />} />
);

// call this immediately per docs
// @see https://docs.expo.dev/versions/latest/sdk/splash-screen/#splashscreenpreventautohideasync
SplashScreen.preventAutoHideAsync();
// We don't like logbox.
LogBox.ignoreAllLogs();
LogBox.uninstall();

export function ErrorBoundary(props: ErrorBoundaryProps) {
  return (
    <View style={{ flex: 1, marginTop: '20%', marginLeft: 8, marginRight: 8 }}>
      <Text style={{ textAlign: 'center', marginBottom: 16 }}>FATAL CRASH</Text>
      <Text style={{ fontWeight: 'bold', marginBottom: 16 }}>
        {props.error.name}: {props.error.message}
      </Text>
    </View>
  );
}

export const unstable_settings = {
  initialRouteName: '(root)',
};

function IndexLayout() {
  return (
    <Stack initialRouteName="(root)">
      <Stack.Screen
        name="os/[nativeAction]"
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen name="(root)" options={{ headerShown: false }} />
      <Stack.Screen
        name="(auth)"
        options={{
          headerShown: false,
          presentation: 'modal',
        }}
      />
      <Stack.Screen
        name="(drawer)"
        options={{
          header: appHeaderDesktopOnly,
          presentation: 'card',
        }}
      />
      <Stack.Screen
        name="[staticPage]"
        options={{
          header: appHeaderDesktopOnly,
        }}
      />
      <Stack.Screen
        name="store-locator"
        options={{
          header: appHeaderDesktopOnly,
        }}
      />
      <Stack.Screen name="menu" options={{ header: appHeaderDesktopOnly }} />
      <Stack.Screen
        name="cart"
        options={{
          header: appHeaderDesktopOnly,
          presentation: 'card',
        }}
      />
      <Stack.Screen
        name="order-confirmation"
        options={{
          header: appHeaderDesktopOnly,
          presentation: 'card',
        }}
      />
      <Stack.Screen
        name="receipt"
        options={{
          header: getScreenTitleHeader({ backStyle: 'dynamic' }),
          title: 'Receipt',
          presentation: 'card',
        }}
      />
      <Stack.Screen
        name="redeem"
        options={{
          header: appHeaderDesktopOnly,
        }}
      />
      <Stack.Screen
        name="rewards"
        options={{
          header: appHeaderDesktopOnly,
          presentation: 'card',
        }}
      />
      <Stack.Screen
        name="amplify"
        options={{
          header: appHeaderDesktopOnly,
          presentation: 'card',
        }}
      />
      <Stack.Screen
        name="support"
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="signout"
        options={{
          headerShown: false,
          presentation: 'formSheet',
        }}
      />
      {/* TODO(simplified-menu): move this outside of the Provider stack */}
      <Stack.Screen
        name="v2"
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="claim-points"
        options={{
          headerShown: false,
        }}
      />
    </Stack>
  );
}

export default function RootLayout() {
  return (
    <AppWrapper>
      <AppContainer>
        <IndexLayout />
        {!isSSG && !isMobile() && <Pypestream />}
        <MaxCartAmountToast />
      </AppContainer>
    </AppWrapper>
  );
}
