import { Stack } from 'expo-router';

import StoreSelected from '@fhs-legacy/frontend/src/components/store-selected';
import MenuContent from '@fhs-legacy/frontend/src/pages/menu-content';
import { isNative } from '@fhs-legacy/frontend/src/utils/environment';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export default function MenuItemPage() {
  return (
    <StoreSelected>
      <Stack.Screen options={{ headerShown: isNative }} />
      <MenuContent />
    </StoreSelected>
  );
}
