import { Button, ButtonProps, IconCartFill } from '@fhs/ui';
import { useOrderContext } from '@fhs-legacy/frontend/src/state/order';

export type HeaderCartButtonProps = Omit<ButtonProps, 'children'>;
export function HeaderCartButton(props: HeaderCartButtonProps) {
  const { numCartPreviewEntries } = useOrderContext();

  const isCartEmpty = numCartPreviewEntries < 1;

  return (
    <Button type={isCartEmpty ? 'outline' : 'solid'} size="sm" disabled={isCartEmpty} {...props}>
      <Button.Icon>
        <IconCartFill />
      </Button.Icon>
      {!isCartEmpty && <Button.Text>{numCartPreviewEntries}</Button.Text>}
    </Button>
  );
}
