import { useState } from 'react';

import { TipOption } from '@fhs/cart/src/components/pick-up-tip-selector';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { TipsForStoreEmployees, defaultTipsForStoreEmployees } from 'state/launchdarkly/variations';
import { ServiceMode } from 'state/order';
import { isDelivery } from 'utils/service-mode';

export const usePickupTips = (serviceMode: ServiceMode | null) => {
  const isDeliveryOrder = isDelivery(serviceMode);

  const tipsForStoreEmployees =
    useFlag<TipsForStoreEmployees>(LaunchDarklyFlag.TIP_FOR_STORE_EMPLOYEES) ||
    defaultTipsForStoreEmployees;

  const showPickUpTips = tipsForStoreEmployees?.enabled && !isDeliveryOrder;

  // Format the pick up tips in options
  const pickUpPipOptions: TipOption[] | undefined = showPickUpTips
    ? Object.entries(tipsForStoreEmployees.tipAmounts)
        .map(([key, value]) => ({
          label: key,
          value: value.toString(),
        }))
        .sort((a, b) => Number(a.value) - Number(b.value))
    : undefined;

  const [selectedTip, setSelectedTip] = useState('0');
  const [otherTip, setOtherTip] = useState('0');

  const pickUpTipCents = selectedTip === 'other' ? otherTip : selectedTip;

  return {
    selectedTip,
    otherTip,
    pickUpPipOptions,
    pickUpTipCents,
    setSelectedTip,
    setOtherTip,
    showPickUpTips,
  };
};
