import { StyleSheet } from 'react-native';

import { tokens } from '@fhs/ui';
import { Box, HStack, Text } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

// Stop using UCL components for new styles
export const totalSectionStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  feeRow: {
    flex: 1,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  feeBreakdown: {
    display: 'flex',
    paddingVertical: 16,
    paddingHorizontal: 20,
    alignItems: 'flex-start',
    gap: 8,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    marginVertical: 8,
  },
  feeBreakdownRow: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    gap: 8,
  },
  feeLabel: {
    fontSize: 14,
    lineHeight: 18,
  },
  baseFeeRow: {
    alignItems: 'center',
  },
  feeBreakdownLabel: {
    fontSize: 14,
    lineHeight: 18,
  },
});

export const FeeDeliveryItem = Text.withConfig({
  color: Styles.color.alert.warning.background,
  fontWeight: 'bold',
});

export const Total = Box;

export const GrandTotalLabel = Text.withConfig({
  flexGrow: 1,
  color: theme.token('text-default'),
  fontSize: 16,
  fontWeight: 'bold',
});

export const TotalLabel = Text.withConfig<{ isFreeDelivery?: boolean }>(p => ({
  flexGrow: 1,
  color: p.isFreeDelivery ? Styles.color.alert.warning.background : theme.token('text-default'),
  fontWeight: p.isFreeDelivery ? 'bold' : 'normal',
}));

export const DonationLabel = Text.withConfig({
  color: theme.token('text-default'),
  fontWeight: 'normal',
});

export const TotalsRow = HStack;
