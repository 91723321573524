import React from 'react';
import { useIntl } from 'react-intl';

import { Text, XStack } from '@fhs/ui';
import Currency from 'components/currency';

import { CurrencyLineSkeleton } from '../../currency-line-skeleton';

import { Total, TotalLabel, TotalsRow, totalSectionStyles } from './styled';
import { IRenderTotalsProps } from './types';
import { getFeesByGroup, getStandardizedName } from './utils';

export const RenderTotals: React.FC<React.PropsWithChildren<IRenderTotalsProps>> = ({
  cart = {},
  otherDiscountAmount,
  rewardDiscount,
  isLoading,
}) => {
  const { formatMessage } = useIntl();

  const { subTotalCents = 0, fees = [] } = cart;

  const itemFees = getFeesByGroup(fees || []);

  return (
    <>
      {!!rewardDiscount &&
        (isLoading ? (
          <CurrencyLineSkeleton />
        ) : (
          <XStack style={totalSectionStyles.feeRow}>
            <Text style={totalSectionStyles.feeLabel}>
              {formatMessage({
                id: 'rewardSavings',
              })}
            </Text>
            <XStack testID="reward-savings">
              <Currency
                prefix="- "
                amount={rewardDiscount}
                textProps={totalSectionStyles.feeLabel}
              />
            </XStack>
          </XStack>
        ))}
      {!!otherDiscountAmount &&
        (isLoading ? (
          <CurrencyLineSkeleton />
        ) : (
          <XStack style={totalSectionStyles.feeRow}>
            <Text style={totalSectionStyles.feeLabel}>
              {formatMessage({
                id: 'comboOfferSavings',
              })}
            </Text>
            <XStack testID="discount-savings">
              <Currency
                prefix="- "
                amount={otherDiscountAmount}
                textProps={totalSectionStyles.feeLabel}
              />
            </XStack>
          </XStack>
        ))}

      {isLoading ? (
        <CurrencyLineSkeleton />
      ) : (
        <XStack style={totalSectionStyles.feeRow}>
          <Text style={totalSectionStyles.feeLabel}>
            {formatMessage({
              id: 'subtotal',
            })}
          </Text>
          <Total testID="subtotal">
            <Currency amount={subTotalCents} textProps={totalSectionStyles.feeLabel} />
          </Total>
        </XStack>
      )}
      {!!itemFees &&
        Object.entries(itemFees).map(([name, entryCents]: [string, number], index) =>
          isLoading ? (
            <CurrencyLineSkeleton />
          ) : (
            <TotalsRow key={index}>
              <TotalLabel testID={`fee-name-${name}`}>
                {formatMessage({ id: `${getStandardizedName({ name })}Fee` })}
              </TotalLabel>
              <Total testID={`fee-amount-${name}`}>
                <Currency amount={entryCents} />
              </Total>
            </TotalsRow>
          )
        )}
    </>
  );
};

export default RenderTotals;
