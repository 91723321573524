import { ScrollView } from '@fhs-legacy/universal-components';
import { WEB_APP_DESKTOP_LG_MAX_WIDTH } from 'utils/constants';

export const ScreenScrollView = ScrollView.withConfig({
  showsVerticalScrollIndicator: true,
  _web: {
    _contentContainerStyle: {
      marginX: 'auto',
      w: {
        base: 'full',
        desktop: WEB_APP_DESKTOP_LG_MAX_WIDTH,
      },
    },
  },
});
