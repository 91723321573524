import { ImageBackground, ImageBackgroundProps } from 'expo-image';
import { Link } from 'expo-router';
import { StyleSheet } from 'react-native';

import { Button, YStack, useMqSelect } from '@fhs/ui';

export type CareersCardProps = Omit<ImageBackgroundProps, 'children' | 'source'> & {
  careersHref: string;
};
export function CareersCard({ careersHref, ...props }: CareersCardProps) {
  return (
    <ImageBackground
      source={require('./careers-card-background.webp')}
      placeholder={{ thumbhash: '1ygKF4IXd4Zgi5eYdZmXGIebaCC3I4MP' }}
      alt="Careers. Help us fuel the future"
      {...props}
      style={[styles.careersCard, props.style]}
    >
      <YStack style={styles.container}>
        <Link href={careersHref} asChild target="_blank">
          <Button type="solid" size={useMqSelect({ $gteDesktopLg: 'xl' }, 'lg')}>
            <Button.Text>Work With Us</Button.Text>
          </Button>
        </Link>
      </YStack>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  careersCard: {
    aspectRatio: 2030 / 1905,
    width: '50%',
  },
  container: {
    position: 'absolute',
    top: '60%',
    left: 0,
    right: 0,
    alignItems: 'center',
  },
});
