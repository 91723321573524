import { IOffer } from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import { isItemGroupDiscount, isOfferDiscount } from 'utils/cart/helper';
import { isCombo, isPicker } from 'utils/menu';

import { IParsedLoyaltyReward } from './hooks/types';
import { LoyaltyAppliedOffer, LoyaltyOffer, isLoyaltyOffer } from './types';

export const isDiscountLoyaltyOffer = (offer: LoyaltyOffer) =>
  offer?.incentives?.every(benefit =>
    [`${MenuObjectTypes.OFFER_DISCOUNT}`, `${MenuObjectTypes.ITEM_GROUP_DISCOUNT}`].includes(
      benefit?._type || ''
    )
  );

export const isDiscountCBAOffer = (offer: IOffer) =>
  offer?.option?._type === MenuObjectTypes.OFFER_DISCOUNT;

export const isDiscountOffer = (offer: IOffer | LoyaltyOffer) =>
  isLoyaltyOffer(offer) ? isDiscountLoyaltyOffer(offer) : isDiscountCBAOffer(offer);

export const isAppliedOfferSwap = (offer: LoyaltyAppliedOffer): boolean => !!offer.swap;

export const getCanStackOfferCheck =
  <T>(getOffer: (element: T) => LoyaltyOffer | LoyaltyAppliedOffer | null) =>
  (offer: LoyaltyOffer, list: T[]): boolean => {
    return (
      offer.isStackable ||
      !Array.isArray(list) ||
      list.length === 0 ||
      list.every((element: T) => {
        const offerItem = getOffer(element);
        // the stackable offer check is for offers only
        return offerItem
          ? !!(offerItem as LoyaltyAppliedOffer)?.swap || offerItem?.isStackable
          : true;
      })
    );
  };

export const getCmsOffersMapByLoyaltyId = (
  offers: LoyaltyOffer[] | null
): { [key: string]: LoyaltyOffer } => {
  return (offers || []).reduce((acc, offer) => {
    if (offer?.loyaltyEngineId) {
      acc[offer.loyaltyEngineId] = offer;
    }

    return acc;
  }, {});
};

export const getCmsOffersMapByCmsId = (
  offers: LoyaltyOffer[] | null
): { [key: string]: LoyaltyOffer } => {
  return (offers || []).reduce((acc, offer) => {
    if (offer?._id) {
      acc[offer._id] = offer;
    }

    return acc;
  }, {});
};

export const incentiveIsOfferBenefit = (offer: LoyaltyOffer): boolean => {
  return !!offer.incentives?.every(incentive => {
    return (
      isOfferDiscount(incentive) ||
      isItemGroupDiscount(incentive) ||
      ((isPicker(incentive) || isCombo(incentive)) && incentive.isOfferBenefit)
    );
  });
};

/**
 * Util fn to find a reward in array and calculate if has reached limit per order
 */
export const findRewardInAppliedRewards = (
  appliedLoyaltyRewardsArray: IParsedLoyaltyReward[],
  engineRewardId: string,
  limitPerOrder?: number | null
) => {
  const appliedReward = appliedLoyaltyRewardsArray.find(
    appliedReward => appliedReward.rewardId === engineRewardId
  );

  if (!appliedReward || !limitPerOrder) {
    return { appliedReward, limitPerOrderMet: false };
  }

  return { appliedReward, limitPerOrderMet: appliedReward.timesApplied >= limitPerOrder };
};
