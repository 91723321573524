import { StyleSheet } from 'react-native';

import { tokens } from '../../tokens';
import { Text, TextProps } from '../text';

import { useQuantity } from './context';

export type QuantityLabelProps = { textProps?: TextProps };

export function QuantityLabel({ textProps }: QuantityLabelProps) {
  const quantity = useQuantity();

  return (
    <Text.Ui
      size="md"
      {...textProps}
      style={[styles.labelText, textProps?.style, quantity.disabled && styles.labelTextDisabled]}
    >
      {quantity.label}
    </Text.Ui>
  );
}

const styles = StyleSheet.create({
  labelText: {
    alignSelf: 'center',
  },
  labelTextDisabled: {
    color: tokens.colors.$disabledText,
  },
});
