import { gql } from '@apollo/client';

import { FireOrderIn, IAddress, IBackendCartEntries, IServerOrder } from '@rbi-ctg/menu';
import {
  IAppliedOffer,
  IFirstDataPayment,
  IOrbitalPayment,
  IWorldpayPayment,
} from 'generated/rbi-graphql';
import { IParsedLoyaltyReward } from 'state/loyalty/hooks/types';
import { OrderStatus } from 'state/order';
import { CartPaymentType } from 'state/order/types';
import { CardTypes, IApplePayDetails, IGooglePayDetails } from 'state/payment/types';
import { ServiceMode } from 'state/service-mode';
import { POSVendor } from 'state/store/hooks/enums';
import { RBIPlatform } from 'utils/environment';

import { OrderFragment } from './fragments/order';

export interface IOrderAddressInput extends IAddress {
  country: string | null;
  phoneNumber: string;
}

export interface IPriceOrderInput {
  appliedOffers?: IAppliedOffer[];
  rewardsApplied?: IParsedLoyaltyReward[] | null;
  brand: string;
  cartEntries: IBackendCartEntries[];
  cartVersion?: number | null;
  customerLocale?: string | null;
  customerName?: string | null;
  loyaltyBarcode?: string | null;
  paymentMethod?: CardTypes | null;
  platform: RBIPlatform;
  posVendor: POSVendor | null;
  redeemReward?: boolean | null;
  redeemedOn?: string | null;
  requestedAmountCents: number;
  serviceMode: ServiceMode;
  storeAddress: IOrderAddressInput;
  storeId: string;
  storePosId?: string | null;
  vatNumber?: string | null;
}

export interface IPriceOrderDeliveryInput {
  dropoff: IOrderAddressInput;
  instructions?: string;
  tipCents?: number;
  quoteId?: string;
}

export interface IPriceOrderMutationResponse {
  priceOrder: {
    rbiOrderId: string;
    status: OrderStatus;
  };
}

export interface IPriceOrderMutationVariables {
  input: IPriceOrderInput;
  delivery?: IPriceOrderDeliveryInput | null;
}

export const PriceOrderMutation = gql`
  mutation PriceOrder($input: PriceOrderInput!, $delivery: PriceDeliveryInput)
  @authRequired
  @gateway(flag: "enable-react-native-fulfillment-service-price-order") {
    priceOrder(input: $input, delivery: $delivery) {
      rbiOrderId
      status
    }
  }
`;

export interface IGetOrderQueryResponse {
  order: IServerOrder;
}

export interface IGetOrderQueryVariables {
  rbiOrderId: string;
}

export type IServerCateringOrder = IServerOrder & {
  catering: {
    pickupTime: string;
    notes: string;
    declineReason: string;
  };
  cart: {
    userDetails: {
      phoneNumber: string;
      email: string;
    };
  };
};

export interface IGetStoreOrderQueryResponse {
  cateringOrder: IServerCateringOrder;
}

export interface IGetStoreOrderQueryVariables {
  storeOrderJwt: string;
}

export const GetOrderQuery = gql`
  query GetOrder($rbiOrderId: ID!)
  @gateway(flag: "enable-react-native-fulfillment-service-get-order")
  @authRequired {
    order(rbiOrderId: $rbiOrderId) {
      ...OrderFragment
      availableTimeOptions
      warningMessage
    }
  }
  ${OrderFragment}
`;

export const GetStoreOrderQuery = gql`
  query GetStoreOrder($storeOrderJwt: String!) {
    cateringOrder(cateringJwt: $storeOrderJwt) {
      ...OrderFragment
      catering {
        pickupTime
        notes
        declineReason
      }
      cart {
        userDetails {
          phoneNumber
          email
        }
      }
    }
  }
  ${OrderFragment}
`;

export interface IConfirmCateringOrderInput {
  input: {
    cateringJwt: string;
    decision: string;
    declineReason?: string;
  };
}
export interface IConfirmCateringMutationResponse {
  confirmCateringOrder: {
    status: string;
  };
}
export const ConfirmCateringOrder = gql`
  mutation ConfirmCateringOrder($input: ConfirmCateringOrderInput!) {
    confirmCateringOrder(input: $input) {
      status
    }
  }
`;
export interface ICommitOrderMutationInput {
  billingAddress?: {
    locality: string | null;
    postalCode: string;
    region: string | null;
    streetAddress: string | null;
    unitNumber?: string | null;
  };
  ccBin?: string | null;
  ccExpiryMonth?: string | null;
  ccExpiryYear?: string | null;
  ccLast4?: string | null;
  creditType?: string | null;
  fdAccessToken?: string;
  fdAccountId?: string;
  fdNonce?: string;
  fireOrderIn?: FireOrderIn;
  fullName?: string;
  rbiOrderId: string;
  payment?: IPayment;
  storeEmail?: string;
  applePayDetails?: IApplePayDetails;
  googlePayDetails?: IGooglePayDetails;
  chaseProfileId?: string;
}

export interface ICommitOrderMutationDeliveryInput {
  instructions?: string;
  tipCents?: number;
  pushNotification?: boolean;
  // the user's location to deliver to
  dropoff?: Partial<IOrderAddressInput>;
}

export interface ICommitOrderMutationVariables {
  input: ICommitOrderMutationInput;
  delivery: ICommitOrderMutationDeliveryInput | null;
  skipCoolingPeriod?: boolean;
}

export interface ICommitOrderMutationResponse {
  commitOrder: {
    rbiOrderId: string;
    status: string;
  };
}

export interface IPayment {
  /** Details from Apple Pay for payment. */
  applePayDetails?: IApplePayDetails;
  /** Billing address associated to card */
  billingAddress?: {
    locality: string | null;
    postalCode: string;
    region: string | null;
    streetAddress: string | null;
    unitNumber?: string | null;
  };
  /** Contains supplemental data for CC transactions */
  ccMetadata?: {
    ccBin?: string | null;
    ccExpiryMonth?: string;
    ccExpiryYear?: string;
    ccLast4?: string | null;
  };
  /** chase id from TH endpoint to trigger direct payment */
  chaseProfileId?: string;
  /** First Data payment data */
  firstDataInput?: IFirstDataPayment;
  /** Name on credit card */
  fullName: string;
  /** Details from Google Pay for payment. */
  googlePayDetails?: IGooglePayDetails;
  paymentType?: CartPaymentType;
  cashPayment?: boolean;
  orbitalInput?: IOrbitalPayment;
  worldpayInput?: IWorldpayPayment;
}

export const CommitOrderMutation = gql`
  mutation CommitOrder(
    $input: CommitOrderInput!
    $delivery: CommitDeliveryInput
    $skipCoolingPeriod: Boolean
  ) @authRequired @gateway(flag: "enable-react-native-fulfillment-service-commit-order") {
    commitOrder(input: $input, delivery: $delivery, skipCoolingPeriod: $skipCoolingPeriod) {
      rbiOrderId
      status
      cart {
        cartEntries {
          ...CartEntryFragment
        }
      }
    }
  }
`;

export const DeliveryStatusQuery = gql`
  query DeliveryOrderStatus($rbiOrderId: ID!) {
    order(rbiOrderId: $rbiOrderId) {
      delivery {
        status
        shareUuid
        dropoff {
          arrivalTime
          beforeTime
        }
        driver {
          name
          phoneNumber
          profileImageUrl
        }
        task {
          uuid
        }
      }
    }
  }
`;

export const UpdateOrderMutation = gql`
  mutation UpdateOrder($input: UpdateOrderInput!)
  @authRequired
  @gateway(flag: "enable-react-native-fulfillment-service-update-order") {
    updateOrder(input: $input) {
      rbiOrderId
      fireOrderIn
    }
  }
`;

export const ValidateCommitOrderMutation = gql`
  mutation ValidateCommitOrder($input: ValidateCommitOrderInput!) @authRequired {
    validateCommitOrder(input: $input) {
      rbiOrderId
      status
    }
  }
`;
