import { type FlashList } from '@shopify/flash-list';
import { type RefObject, createContext, createRef, useContext } from 'react';

import type { FlashListData, ItemSizeType, SectionType } from './types';

export type AnchorSectionListContextType = {
  size: ItemSizeType;
  activeSectionId: string;
  setActiveSectionId: (sectionId: string) => void;
  sections: Array<SectionType>;
  flashListRef: RefObject<FlashList<any>>;
  flashListData: FlashListData;
};

export const anchorSectionListContext = createContext(<AnchorSectionListContextType>{
  size: 'lg',
  activeSectionId: '',
  setActiveSectionId: () => {},
  sections: [],
  flashListRef: createRef(),
  flashListData: [],
});

export const useAnchorSectionList = () => useContext(anchorSectionListContext);
