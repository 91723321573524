import { isApolloError } from '@apollo/client';
import delve from 'dlv';
import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@fhs-legacy/universal-components';
import { Nullable } from '@rbi-ctg/frontend';
import ActionButton from 'components/action-button';
import { IConfirmProps } from 'components/confirm-dialog';
import Dialog from 'components/dialog';
import { IDialogProps } from 'components/dialog/types';
import Link from 'components/link';
import { DialogCb, IUseDialogComponentProps, IUseDialogProps } from 'hooks/use-dialog-modal';
import { IGraphqlErrorMap, createGqlErrorMap, parseGraphQLErrorCodes } from 'utils/errors';
import logger from 'utils/logger';
import noop from 'utils/noop';

import useRoutes from './use-routes';

export type ModalCb = (data: IErrorModal) => void;

interface IUseErrorModalArgs<T> {
  Component?: React.ComponentType<React.PropsWithChildren<IUseDialogComponentProps>>;
  onConfirm?: DialogCb<T>;
  onCancel?: DialogCb<T>;
  onDismiss?: DialogCb<T>;
  onOpen?: ModalCb;
  type?: string;
  showCancel?: boolean;
  init?: boolean;
  allowDismiss?: boolean;
  modalAppearanceEventMessage: string;
}

export interface IErrorModal {
  body?: string | ReactNode;
  link?: {
    key: string;
    to: string;
    text: string;
  };
  message: ReactNode;
  error?: Error;
  name?: string;
  title?: string;
  modalAppearanceEventMessage?: string;
  confirmationText?: string;
  dismissText?: string;
  onConfirm?: Function;
  onDismiss?: Function;
}

type Props<P> = IUseDialogProps & Partial<IDialogProps> & Partial<IConfirmProps> & P;
export type UseErrorDialogHook<P = any> = [
  React.FC<React.PropsWithChildren<Props<P>>>,
  ModalCb,
  () => void
];

export default function useErrorModal<T extends object, P = {}>({
  Component,
  onConfirm = noop,
  onCancel = noop,
  onDismiss = onCancel,
  onOpen = noop,
  init = false,
  allowDismiss = true,
  /**
   * A small string sent to mParticle describing the purpose of the modal.
   */
  modalAppearanceEventMessage,
}: IUseErrorModalArgs<T>): UseErrorDialogHook<P> {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(init);
  const { getLocalizedRouteForPath } = useRoutes();
  const [pendingData, setPending] = useState<Nullable<T>>(null);
  const modalAppearanceEventMessageOverride = useRef<string | null>(null);
  const errorEventMessageOverride = useRef<string | null>(null);
  const openErrorDialog = useCallback(
    (data: IErrorModal) => {
      onOpen(data);

      if (data) {
        const { error, modalAppearanceEventMessage: eventMessageOverride } = data;
        const message: string | null = typeof data.message === 'string' ? data.message : null;
        let parsedError: Error | IGraphqlErrorMap | undefined = error;

        if (eventMessageOverride) {
          modalAppearanceEventMessageOverride.current = eventMessageOverride;
        }

        if (parsedError && isApolloError(parsedError)) {
          const errors = parseGraphQLErrorCodes(parsedError);

          if (errors.length) {
            const [{ errorCode, message: errorMessage }] = errors;
            errorEventMessageOverride.current = [errorCode, errorMessage].join(' ');
            // we cannot filter datadog results based on array elements
            // so we create a map of the list of errors supplied by apollo
            parsedError = createGqlErrorMap([...parsedError.graphQLErrors]);
          }
        }

        logger.error({
          error: parsedError,
          message: eventMessageOverride || modalAppearanceEventMessage,
          modalHeader: eventMessageOverride || modalAppearanceEventMessage,
          modalMessage: message,
        });
      }

      setPending(data as T);
      setOpen(true);
    },
    [modalAppearanceEventMessage, onOpen]
  );

  const dismissDialog = useCallback(() => {
    onDismiss(pendingData);
    setPending(null);
    if (allowDismiss) {
      setOpen(false);
    }
  }, [allowDismiss, onDismiss, pendingData]);

  const confirmDialog = useCallback(() => {
    onConfirm(pendingData);
    setPending(null);
    setOpen(false);
    onCancel();
  }, [onCancel, onConfirm, pendingData]);

  const ErrorDialogComponent: UseErrorDialogHook<P>[0] = useCallback(
    // @ts-ignore TS(2590) FIXME: Expression produces a union type that is too complex to represent.
    ({
      buttonLabel = formatMessage({ id: 'okay' }),
      heading = formatMessage({ id: 'somethingWrong' }),
      image,
      ...rest
    }) => {
      if (!open) {
        return null;
      }

      const msg = delve(pendingData as T, 'message', null);
      const title = delve(pendingData as T, 'title', null);
      const body = delve(pendingData as T, 'body', null);
      const link = delve(pendingData as T, 'link', null);

      const confirmationText = delve(pendingData as T, 'confirmationText', null);
      const dismissText = delve(pendingData as T, 'dismissText', null);
      const onConfirmAction = delve(pendingData as T, 'onConfirm', null);
      const onDismissAction = delve(pendingData as T, 'onDismiss', null);

      let linkBody;
      if (Component) {
        return <Component onDismiss={dismissDialog} onConfirm={confirmDialog} />;
      }

      if (link) {
        linkBody = formatMessage(
          { id: `${link.key}` },
          {
            link: (
              <Link to={getLocalizedRouteForPath(link.to)} onPress={dismissDialog}>
                {link.text}
              </Link>
            ),
          }
        );
      }

      const dialogBody = linkBody || body || msg || formatMessage({ id: 'errorProcessingRequest' });

      const actions =
        confirmationText && dismissText && onConfirmAction && onDismissAction ? (
          <Button.Group>
            <ActionButton
              fullWidth
              onPress={onDismissAction}
              testID="error-dialog-dismiss-btn"
              mb={2}
            >
              {dismissText || buttonLabel}
            </ActionButton>
            <ActionButton fullWidth onPress={onConfirmAction} testID="error-dialog-confirm-btn">
              {confirmationText}
            </ActionButton>
          </Button.Group>
        ) : (
          <ActionButton
            fullWidth
            marginTop="$4"
            onPress={confirmDialog}
            testID="error-dialog-confirm-btn"
          >
            {dismissText || buttonLabel}
          </ActionButton>
        );

      return (
        // @ts-ignore TS(2590) FIXME: Expression produces a union type that is too complex to represent.
        <Dialog
          showDialog={open}
          heading={title || heading}
          bodyComponent={dialogBody}
          image={image}
          onDismiss={dismissDialog}
          actions={actions}
          modalAppearanceEventMessage={
            modalAppearanceEventMessageOverride.current || modalAppearanceEventMessage
          }
          errorEventMessage={errorEventMessageOverride.current ?? undefined}
          {...rest}
        />
      );
    },
    [
      formatMessage,
      open,
      pendingData,
      Component,
      confirmDialog,
      dismissDialog,
      modalAppearanceEventMessage,
      getLocalizedRouteForPath,
    ]
  );

  return [ErrorDialogComponent, openErrorDialog, dismissDialog];
}
