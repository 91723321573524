import { useMemo } from 'react';

import { useAnchorSectionList } from './context';
import { useScrollToSection } from './use-scroll-to-section';

export function useAnchors() {
  const context = useAnchorSectionList();
  const scrollToSection = useScrollToSection();

  return useMemo(() => {
    return context.sections.map(section => {
      return {
        id: section.id,
        title: section.anchorTitle,
        image: section.image,
        onPress: () => {
          scrollToSection(section.id, { animated: false });
          // we are manually setting the activeSectionid  because we want
          // to ensure that the section the user clicked on is the one that
          // highlights
          context.setActiveSectionId(section.id);
        },
        isActive: section.id === context.activeSectionId,
      };
    });
  }, [context, scrollToSection]);
}
