import { createContext, useContext } from 'react';

import type { CheckedState } from './types';

export type CheckboxContext = {
  label: string;
  handlePress: () => void;
  checked: CheckedState;
  disabled: boolean;
};

export const CheckboxContext = createContext<CheckboxContext | null>(null);

export const useCheckbox = () => {
  const context = useContext(CheckboxContext);

  if (__DEV__) {
    if (context == null) {
      throw new Error('useCheckbox usage must be wrapped within a Checkbox.');
    }
  }

  return context as CheckboxContext;
};
