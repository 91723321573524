import { useIntl } from 'react-intl';
import { View } from 'react-native';

import { Button, IconChevronRight, Text, createMqStyles, useMqSelect } from '@fhs/ui';

interface Props {
  onPressSeeAll: () => void;
}

export function OffersSectionHeader({ onPressSeeAll }: Props) {
  const mqStyles = useMqStyles();
  const { formatMessage } = useIntl();

  return (
    <View style={mqStyles.container}>
      <Text.Heading type={useMqSelect({ $gteDesktop: 'one' }, 'three')}>
        {formatMessage({ id: 'offers' })}
      </Text.Heading>
      <Button type="ghost" size="sm" onPress={onPressSeeAll}>
        <Button.Text>{formatMessage({ id: 'seeAll' })}</Button.Text>
        <Button.Icon>
          <IconChevronRight />
        </Button.Icon>
      </Button>
    </View>
  );
}

const useMqStyles = createMqStyles({
  container: {
    $base: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      paddingTop: 16,
    },
    $gteDesktop: {
      paddingTop: 32,
    },
  },
});
