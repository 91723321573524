import React from 'react';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native';

import { tokens } from '../../tokens';
import { XStack } from '../stack';

import { TabbedHeaderLink } from './tabbed-header-link';
import { TabbedHeaderProps } from './types';

export function TabbedHeader(props: TabbedHeaderProps) {
  const { items, onSelect } = props;
  return (
    <XStack style={[styles.container]} testID="tabbed-menu-header">
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        {items.map((item, idx) => (
          <TabbedHeaderLink
            key={item.slug}
            item={item}
            isActive={idx === props.activeIndex}
            onSelect={onSelect}
          />
        ))}
      </ScrollView>
    </XStack>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: tokens.colors.$white,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: tokens.colors.$blackOpacity10,
  },
});
