import { StyleSheet, View, ViewProps } from 'react-native';

import { tokens } from '../../tokens';

export type HeaderDividerProps = ViewProps;
export function HeaderDivider(props: HeaderDividerProps) {
  return <View {...props} style={[styles.divider, props.style]} />;
}

const styles = StyleSheet.create({
  divider: {
    width: 1,
    height: 34,
    backgroundColor: tokens.colors.$blackOpacity10,
  },
});
