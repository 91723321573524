import Head from 'expo-router/head';
import { useIntl } from 'react-intl';

import AuthHome from '@fhs-legacy/frontend/src/pages/authentication/auth-home';
import { AuthMethod } from '@fhs-legacy/frontend/src/pages/authentication/auth-home/types';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export default function SigninPage() {
  const { formatMessage } = useIntl();
  return (
    <>
      <Head>
        <title>{`${formatMessage({ id: 'signUp' })} - Firehouse Subs`}</title>
      </Head>
      <AuthHome method={AuthMethod.SIGN_IN} />
    </>
  );
}
