import { PropsWithChildren } from 'react';
import { GestureResponderEvent, StyleSheet, View } from 'react-native';

import { ListItemButton } from '@fhs/ui/src/components/list-item-button';

import { tokens } from '../../tokens';
import { RadioButton } from '../radio';
import { XStack, YStack } from '../stack';

import { ActionSheetOptionType } from './types';

const styles = StyleSheet.create({
  listItem: {
    borderWidth: 0,
    borderRadius: 0,
    borderBottomWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  listItemHover: {
    backgroundColor: tokens.colors.$blackOpacity04,
  },
  listItemLeftContent: {
    alignItems: 'center',
    flex: 7,
  },
  listItemContent: {
    flex: 1,
  },
  itemSecondLine: {
    marginTop: 8,
    marginRight: 12,
  },
  iconWrapper: {
    backgroundColor: tokens.colors.$black10,
    marginRight: 12,
    height: 40,
    width: 40,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export function ListItem({ firstLine, secondLine }: { firstLine: string; secondLine?: string }) {
  return (
    <YStack style={styles.listItemContent}>
      <ListItemButton.Text weight="semibold">{firstLine}</ListItemButton.Text>
      {secondLine && (
        <ListItemButton.Text numberOfLines={1} ellipsizeMode="tail" style={styles.itemSecondLine}>
          {secondLine}
        </ListItemButton.Text>
      )}
    </YStack>
  );
}

export function ActionSheetListItem({
  children,
  icon,
  type,
  selected = false,
  onPress,
}: PropsWithChildren & {
  icon?: React.JSX.Element;
  selected?: boolean;
  type: ActionSheetOptionType;
  onPress: (event: GestureResponderEvent) => void;
}) {
  const renderRightElement = () => {
    switch (type) {
      case 'radio':
      default:
        return <RadioButton selected={selected} onSelect={onPress} />;
    }
  };

  return (
    <ListItemButton style={styles.listItem} hoveredStyle={styles.listItemHover} onPress={onPress}>
      <XStack style={styles.listItemLeftContent}>
        {icon && <View style={styles.iconWrapper}>{icon}</View>}
        {children}
      </XStack>
      <View>{renderRightElement()}</View>
    </ListItemButton>
  );
}
