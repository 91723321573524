import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@fhs-legacy/universal-components';
import { TextInput, TextInputMask } from 'components/ucl/text-input';
import { PaymentFieldVariations } from 'state/launchdarkly/variations';
import { CardType } from 'state/payment/types';
import { creditCardNumberMask, expiryMask } from 'utils/masks';
import { IPaymentErrors, IPaymentState } from 'utils/payment';

import {
  CardNumberWrapper,
  StyledCvvWrapper,
  StyledExpiryCVVWrapper,
  StyledExpiryWrapper,
} from './styled';

interface IFirstDataCreditCardFormInputs {
  onChange: (name: string, value: string) => void;
  errors: IPaymentErrors;
  paymentValues: IPaymentState;
  supportedCardTypes?: CardType[];
  paymentFieldVariations: PaymentFieldVariations;
}

const CreditCardIcon: FC<React.PropsWithChildren<{ cardType: string }>> = ({ cardType }) =>
  ({
    AMEX: <Icon variant="amex" />,
    DISCOVER: <Icon variant="discover" />,
    DINERS_CLUB: <Icon variant="diners" />,
    JCB: <Icon variant="jcb" />,
    MASTERCARD: <Icon variant="mastercard" />,
    VISA: <Icon variant="visa" />,
  }[cardType] || null);

const FirstDataCreditCardInputs: FC<React.PropsWithChildren<IFirstDataCreditCardFormInputs>> = ({
  errors,
  onChange,
  paymentValues,
  paymentFieldVariations,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {paymentFieldVariations.cardNumber && (
        <CardNumberWrapper>
          <TextInputMask
            aria-label={formatMessage({ id: 'creditCardNumber' })}
            onChangeText={value => onChange('cardNumber', value)}
            label={formatMessage({ id: 'creditCardNumber' })}
            value={paymentValues.cardNumber}
            required
            testID="cardNumber"
            errorMessage={errors.cardType || errors.cardNumber}
            autoComplete="cc-number"
            keyboardType="numeric"
            // @ts-expect-error TS(2322) FIXME: Type '(cardNumber: string) => any[]' is not assign... Remove this comment to see the full error message
            mask={creditCardNumberMask}
            endIcon={
              paymentValues.isCardNumberValid ? (
                <CreditCardIcon cardType={paymentValues.cardType} />
              ) : null
            }
          />
        </CardNumberWrapper>
      )}
      <StyledExpiryCVVWrapper>
        {paymentFieldVariations.expiration && (
          <StyledExpiryWrapper>
            <TextInputMask
              autoComplete="cc-exp"
              errorMessage={errors.expiry}
              keyboardType="numeric"
              label={formatMessage({ id: 'cardExpiration' })}
              mask={expiryMask}
              onChangeText={(value: string) => {
                onChange('expiry', value);
              }}
              required
              testID="expiry"
              value={paymentValues.expiry || ''}
            />
          </StyledExpiryWrapper>
        )}
        {paymentFieldVariations.cvv && (
          <StyledCvvWrapper>
            <TextInput
              aria-label={formatMessage({ id: 'cvv' })}
              onChangeText={value => onChange('cvv', value)}
              label={formatMessage({ id: 'cvv' })}
              value={paymentValues.cvv || ''}
              required
              testID="cvv"
              errorMessage={errors.cvv}
              autoComplete="cc-csc"
              keyboardType="numeric"
              // TODO - RN input should support pattern
              // pattern="[0-9]*"
              maxLength={4}
            />
          </StyledCvvWrapper>
        )}
      </StyledExpiryCVVWrapper>
    </>
  );
};

export default FirstDataCreditCardInputs;
