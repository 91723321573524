import { useIntl } from 'react-intl';
import { View } from 'react-native';

import { createMqStyles } from '@fhs/ui';
import { LoyaltyPromoCode } from '@fhs-legacy/frontend/src/pages/loyalty/loyalty-offers/loyalty-promo-code';
import { IPromoCodeProps } from '@fhs-legacy/frontend/src/pages/loyalty/loyalty-offers/loyalty-promo-code/types';

interface Props extends IPromoCodeProps {}
export function PromoCodeButton({ ...promoCodeProps }: Props) {
  const mqStyles = useMqStyles();
  const { formatMessage } = useIntl();

  return (
    <View style={mqStyles.container}>
      <LoyaltyPromoCode
        buttonLabel={formatMessage({ id: 'haveAPromoCode' })}
        style={mqStyles.button}
        {...promoCodeProps}
      />
    </View>
  );
}

const useMqStyles = createMqStyles({
  container: {
    $base: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    $gteDesktop: {
      flex: 1,
    },
  },
  button: {
    $base: {
      marginTop: 0,
      // using margin:0 it does not work
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
      width: '100%',
    },
    $ltDesktop: {
      marginTop: 12,
    },
    $gteDesktop: {
      height: 72,
    },
  },
});
