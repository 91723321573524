import { Image, ImageBackground, ImageStyle } from 'expo-image';
import { Link } from 'expo-router';
import { StyleProp, View } from 'react-native';

import { Button, createMqStyles, useMqSelect } from '@fhs/ui';

export type CaptainsClubCardProps = {
  pickUpHref: string;
};

export function CaptainsClubCard({ pickUpHref }: CaptainsClubCardProps) {
  const mqStyles = useMqStyles();
  const backgroundSource = useMqSelect(
    {
      $gteDesktopLg: require('./captains-club-card-background-desktop.webp'),
    },
    require('./captains-club-card-background-tablet.webp')
  );
  return (
    <ImageBackground
      alt="New! Firehouse Captain's Club. Sauced and spiced to perfection. Featuring Captain Sorensen's Hot Sauce"
      source={backgroundSource}
      placeholder={{ thumbhash: 'mXkGE4KvFGd7iTdob3b2dDc' }}
      style={mqStyles.captainsClubBanner}
    >
      <View style={mqStyles.contentContainer}>
        <Image
          source={require('./captains-club-card-logo.webp')}
          alt="Firehouse captain's club"
          placeholder={{ thumbhash: 'uEiGC4QUt1eAfYE4f4705weYhXeEstg' }}
          style={mqStyles.logo as StyleProp<ImageStyle>}
          contentFit="contain"
        />
        <Image
          source={require('./captains-club-card-caption.webp')}
          alt="Firehouse captain's club"
          placeholder={{ thumbhash: 'PwiCAYAloLZ3eHUAAAAAAIiIdXhwl3g' }}
          style={mqStyles.caption as StyleProp<ImageStyle>}
          contentFit="contain"
        />
        <Link href={pickUpHref} asChild style={mqStyles.ctaContainer}>
          <Button size="lg">
            <Button.Text>Order Now</Button.Text>
          </Button>
        </Link>
      </View>
    </ImageBackground>
  );
}

const useMqStyles = createMqStyles({
  captainsClubBanner: {
    $base: {
      aspectRatio: 1024 / 505,
    },
    $gteDesktopLg: {
      aspectRatio: 1920 / 757,
    },
  },
  contentContainer: {
    $base: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '40%',
      top: '15%',
      left: '57%',
    },
    $gteDesktopLg: {
      width: '38%',
      top: '12%',
    },
  },
  logo: {
    $base: {
      aspectRatio: 643 / 355,
      width: '88%',
    },
  },
  caption: {
    $base: {
      aspectRatio: 726 / 46,
      marginTop: '5%',
      width: '100%',
    },
  },
  ctaContainer: {
    $base: {
      width: '55%',
      top: '12%',
    },
    $gteDesktopLg: {
      width: '42%',
    },
  },
});
