import { Image, ImageBackground, ImageBackgroundProps, ImageStyle } from 'expo-image';
import { Link } from 'expo-router';
import { StyleProp } from 'react-native';

import { Button, Text, YStack, createMqStyles, useMqSelect } from '@fhs/ui';

export type ChickenParmesanCardProps = Omit<ImageBackgroundProps, 'children' | 'source'> & {
  pickUpHref: string;
};
export function ChickenParmesanCard({ pickUpHref, ...props }: ChickenParmesanCardProps) {
  const mqStyles = useMqStyles();

  return (
    <ImageBackground
      source={require('./chicken-parmesan-card-background.webp')}
      placeholder={{ thumbhash: '9SgOK4afdYl5lrd1cHcDdjg' }}
      {...props}
      style={[mqStyles.chickenParmesanBanner, props.style]}
    >
      <YStack style={mqStyles.contentContainer}>
        <Image
          source={require('./chicken-parmesan-logo.webp')}
          alt="Chicken Parmesan Meatball Sub"
          placeholder={{ thumbhash: 'U+mGC4YWh4h/d3GHf3DpB2d3bYhwV1c' }}
          style={mqStyles.logo as StyleProp<ImageStyle>}
          contentFit="contain"
        />
        <Text style={mqStyles.text}>
          Savory chicken meatballs, bright marinara, shaved Parmesan, melted provolone, and crispy
          Italian-seasoned breadcrumbs on a toasted garlic bread roll.
        </Text>
        <Image
          source={require('./limited-time-only.webp')}
          alt="Limited time only"
          placeholder={{ thumbhash: 'gQeCAYAIeoiPaJiWgEh2gJwndwLPVlg' }}
          style={mqStyles.limitedTimeOnly as StyleProp<ImageStyle>}
          contentFit="contain"
        />
        <Link href={pickUpHref} asChild>
          <Button size={useMqSelect({ $gteDesktopLg: 'xl' }, 'lg')}>
            <Button.Text>Order Now</Button.Text>
          </Button>
        </Link>
      </YStack>
      <Text style={mqStyles.copyrightText}>TM & © 2024 FRG, LLC.</Text>
    </ImageBackground>
  );
}

const useMqStyles = createMqStyles({
  chickenParmesanBanner: {
    $base: {
      aspectRatio: 2771 / 1270,
    },
  },
  contentContainer: {
    $base: {
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      right: '55%',
      left: 0,
    },
    $ltDesktopLg: {
      top: 32,
      bottom: 32,
      gap: 24,
    },
    $gteDesktopLg: {
      top: 64,
      bottom: 64,
      gap: 32,
    },
  },

  logo: {
    $base: {
      aspectRatio: 1219 / 518,
    },
    $ltDesktopLg: {
      width: '65%',
    },
    $gteDesktopLg: {
      width: '70%',
    },
  },

  limitedTimeOnly: {
    $base: {
      aspectRatio: 1875 / 141,
    },
    $ltDesktopLg: {
      width: '70%',
    },
    $gteDesktopLg: {
      width: '60%',
    },
  },

  text: {
    $base: {
      textAlign: 'center',
    },
    $ltDesktopLg: {
      fontSize: 16,
      lineHeight: 26,

      width: '90%',
    },
    $gteDesktopLg: {
      fontSize: 20,
      lineHeight: 28,

      width: 490,
    },
  },

  copyrightText: {
    $base: {
      position: 'absolute',
      right: '3%',
      bottom: '6%',
    },
    $ltDesktopLg: {
      fontSize: 16,
    },
    $gteDesktopLg: {
      fontSize: 20,
    },
  },
});
