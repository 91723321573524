import { router } from 'expo-router';
import { Pressable, StyleSheet, View } from 'react-native';

import { IconChevronRight, IconPaymentTypeApplePay, Text, tokens } from '@fhs/ui';

export function CheckoutPaymentDetails() {
  return (
    <Pressable style={styles.anchor} onPress={() => router.setParams({ action: 'payment' })}>
      {props => (
        <View style={[styles.container, props.pressed && styles.pressedContainer]}>
          <View style={[styles.iconCircle, props.pressed && styles.iconCirclePressed]}>
            <IconPaymentTypeApplePay size={24} />
          </View>
          <Text.Ui size="md" style={styles.text}>
            Apple Pay
          </Text.Ui>
          <IconChevronRight size={24} />
        </View>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  pressedContainer: {
    backgroundColor: tokens.colors.$houseLight,
  },
  container: {
    alignItems: 'center',
    height: 72,
    width: 343,
    borderColor: tokens.colors.$black10,
    backgroundColor: tokens.colors.$white,
    borderWidth: 1,
    borderRadius: 8,
    gap: 8,
    padding: 16,
    flexDirection: 'row',
  },
  text: {
    flex: 1,
  },
  anchor: {
    width: '100%',
  },
  iconCirclePressed: {
    backgroundColor: tokens.colors.$black10,
  },
  iconCircle: {
    height: 40,
    width: 40,
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: tokens.colors.$houseLight,
  },
});
