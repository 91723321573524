import { createSelector } from '@reduxjs/toolkit';
import { keyBy, uniqBy } from 'lodash';

import { OfferRedemptionType } from 'generated/graphql-gateway';
import { RootState } from 'state/global-state';
import { IncentiveEvaluationErrorCodes } from 'state/loyalty/hooks/types';
import { LoyaltyOffer } from 'state/loyalty/types';
import { getCmsOffersMapByCmsId, isDiscountLoyaltyOffer } from 'state/loyalty/utils';

import { incentiveErrorsFilteredList } from './offers.utils';

export const selectAppliedOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.appliedOffers;
};

export const selectOfferFeedbackMap = ({ loyalty }: RootState) => {
  return loyalty.offers.offersFeedbackMap;
};

export const selectOffersEligibleItems = ({ loyalty }: RootState) => {
  return loyalty.offers.offersEligibleItems;
};

export const selectOfferEligibleItem = ({ loyalty }: RootState, lineId) => {
  return loyalty.offers.offersEligibleItems.find(eligibleItem => eligibleItem.lineId === lineId);
};

export const selectOffersLoading = ({ loyalty }: RootState) => {
  return loyalty.offers.offersLoading;
};

export const selectOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.offers;
};

export const selectUserOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.userOffers;
};

export const selectPersonalizedOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.personalizedOffers;
};

export const selectSurpriseAvailable = ({ loyalty }: RootState) => {
  return loyalty.offers.surpriseAvailable;
};

export const selectUpsizeAvailable = ({ loyalty }: RootState) => {
  return loyalty.offers.upsizeAvailable;
};

export const selectSelectedOffer = ({ loyalty }: RootState) => {
  return loyalty.offers.selectedOffer;
};

export const selectIncentiveErrorsFilteredByCode = (
  { loyalty }: RootState,
  errorCodeToFilter: IncentiveEvaluationErrorCodes
) => {
  return incentiveErrorsFilteredList(
    loyalty.offers.offersFeedbackMap,
    evaluationError => evaluationError.code !== errorCodeToFilter
  );
};

export const selectIncentiveErrorsWithCode = (
  { loyalty }: RootState,
  errorCodeToFilter: IncentiveEvaluationErrorCodes
) => {
  return incentiveErrorsFilteredList(
    loyalty.offers.offersFeedbackMap,
    evaluationError => evaluationError.code === errorCodeToFilter
  );
};

export const selectCmsOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.cmsOffers;
};

export const selectCmsSurpriseOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.cmsOffers.filter(
    offer => offer.redemptionType === OfferRedemptionType.SURPRISE
  );
};

export const selectAppliedCmsOffers = ({ loyalty }: RootState) => {
  const set = new Set(loyalty.offers.appliedOffers.map(({ id }) => id));
  return loyalty.offers.cmsOffers?.filter(cmsOffer => set.has(cmsOffer.loyaltyEngineId));
};

export const selectDiscountAppliedCmsOffer = createSelector(
  selectAppliedCmsOffers,
  appliedCmsOffers =>
    //Return first match because there should never be two applied offer discounts
    appliedCmsOffers?.find(isDiscountLoyaltyOffer)
);

export const selectOfferRedemptionAvailableAfter = ({ loyalty }: RootState) => {
  return loyalty.offers.offerRedemptionAvailableAfter;
};

export const selectExtendedCmsOffersMap =
  ({ loyalty }: RootState) =>
  (newCmsOffers: LoyaltyOffer[]) => {
    const cmsOffersMap = getCmsOffersMapByCmsId(loyalty.offers.cmsOffers);
    const newCmsOffersMap = getCmsOffersMapByCmsId(newCmsOffers);

    return { ...cmsOffersMap, ...newCmsOffersMap };
  };

export const selectValidAppliedOffers =
  ({ loyalty }: RootState) =>
  (offersMap: { [key: string]: LoyaltyOffer }) => {
    return loyalty.offers.appliedOffers.filter(
      offer => !!offer.swap || !!offersMap[offer.cmsId || '']
    );
  };

export const selectIncentivesIds = ({ loyalty }: RootState) => {
  return new Set(loyalty.offers.incentivesIds);
};

export const selectIsDiscountNotApplied = ({ loyalty }: RootState) => {
  return loyalty.offers.isDiscountNotApplied;
};

export const selectShouldRefetchOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.shouldRefetchOffers;
};

export const selectCombinedOffers = createSelector(
  selectPersonalizedOffers,
  selectUserOffers,
  selectCmsOffers,
  selectAppliedOffers,
  (personalizedOffers, userOffers, cmsOffers, appliedOffers) => {
    const baseOffersMap = keyBy(userOffers, 'id');
    const personalizedOffersMap = keyBy(personalizedOffers, 'loyaltyEngineId');
    const _offers: LoyaltyOffer[] = [];
    //Evaluating personalizedOffers first
    const mixedOffers = uniqBy([...personalizedOffers, ...cmsOffers], 'loyaltyEngineId');
    mixedOffers?.forEach((offer: LoyaltyOffer) => {
      if (!offer.loyaltyEngineId || !offer._id) {
        return;
      }
      const personalizedOffer = personalizedOffersMap[offer.loyaltyEngineId];
      const engineOffer = baseOffersMap[offer.loyaltyEngineId];
      const metadata = personalizedOffer ? personalizedOffer?.metadata : engineOffer?.metadata;
      _offers.push({
        ...offer,
        metadata,
      });
    });
    return _offers.filter(
      offer => !appliedOffers.find(appliedOffer => appliedOffer.id === offer.loyaltyEngineId)
    );
  }
);
