import { ImageBackground } from 'expo-image';
import { Link } from 'expo-router';
import { StyleSheet } from 'react-native';

import { Button, YStack, useMqSelect } from '@fhs/ui';

export function DonateTodayCard({ donateHref }: { donateHref: string }) {
  return (
    <ImageBackground
      source={require('./donate-today-card-background.webp')}
      placeholder={{ thumbhash: 'cxgKD4T3iJeIeHaNdjW3uHiJQ6BZB40H' }}
      alt="Donate Today"
      style={styles.card}
    >
      <YStack style={styles.container}>
        <Link href={donateHref} target="_blank">
          <Button type="solid" size={useMqSelect({ $gteDesktopLg: 'xl' }, 'lg')}>
            <Button.Text>Donate Now</Button.Text>
          </Button>
        </Link>
      </YStack>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  card: {
    width: '50%',
    aspectRatio: 1354 / 1270,
  },
  container: {
    position: 'absolute',
    bottom: '10%',
    left: 0,
    right: 0,
    alignItems: 'center',
  },
});
