import { ImageBackground, ImageBackgroundProps } from 'expo-image';
import { Link } from 'expo-router';
import { StyleSheet } from 'react-native';

import { Button, YStack, useMqSelect } from '@fhs/ui';

export type HookAndLadderCardProps = Omit<ImageBackgroundProps, 'children' | 'source'> & {
  pickUpHref: string;
};
export function HookAndLadderCard(props: HookAndLadderCardProps) {
  return (
    <ImageBackground
      source={require('./hook-and-ladder-card-background.webp')}
      placeholder={{ thumbhash: 'tSgKD4LImHeKdIh5l2eo91h4dzAnB3gB' }}
      alt="Hook & Ladder"
      {...props}
      style={[styles.hookAndLadderCard, props.style]}
    >
      <YStack style={styles.container}>
        <Link href="/menu/picker-57783225-461a-409b-8983-af4b1be9cce1" asChild>
          <Button type="solid" size={useMqSelect({ $gteDesktopLg: 'xl' }, 'lg')}>
            <Button.Text>Order Now</Button.Text>
          </Button>
        </Link>
      </YStack>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  hookAndLadderCard: {
    width: '50%',
    aspectRatio: 1354 / 1270,
  },
  container: {
    position: 'absolute',
    top: '42%',
    left: 0,
    right: 0,
    alignItems: 'center',
  },
});
