import { Link } from 'expo-router';
import React from 'react';
import { useIntl } from 'react-intl';
import { StyleProp, ViewStyle } from 'react-native';

import { Text, createMqStyles } from '@fhs/ui';
import { InlineAlert } from '@fhs-legacy/universal-components';

interface Props {
  style?: StyleProp<ViewStyle>;
}

const switchDeliveryPath = '/store-locator/address';

export const SwitchToDelivery = ({ style }: Props) => {
  const { formatMessage } = useIntl();
  const mqStyles = useMqStyles();

  return (
    <InlineAlert
      iconCentered
      status="info"
      borderRadius={8}
      style={[mqStyles.inlineText, style]}
      message={
        <Text.Ui style={[mqStyles.inlineText]} size="md">
          {formatMessage(
            { id: 'lookingForMoreOffers' },
            {
              link: (
                <Link href={switchDeliveryPath}>
                  <Text.Ui style={mqStyles.textHighlighted} size="md">
                    {formatMessage({
                      id: 'switchServiceModeToDeliveryButton',
                    })}
                  </Text.Ui>
                </Link>
              ),
            }
          )}
        </Text.Ui>
      }
    />
  );
};

const useMqStyles = createMqStyles({
  inlineText: {
    $base: {
      fontSize: 12,
      flex: 1,
    },
    $gteDesktop: {
      fontSize: 14,
    },
  },
  textHighlighted: {
    $base: {
      textDecorationLine: 'underline',
      fontSize: 12,
    },
    $gteDesktop: {
      fontSize: 14,
    },
  },
});
