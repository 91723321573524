import { useEffect } from 'react';
import { useRef } from 'react';
import { StyleSheet, type View } from 'react-native';

import { tokens } from '../../tokens';
import { Pressable, PressableProps, usePressableState } from '../pressable';
import { Text } from '../text';

import { useAnchors } from './use-anchors';

export function AnchorListHorizontal() {
  const anchors = useAnchors();

  return (
    <>
      {anchors.map(a => (
        <Anchor key={a.id} isActive={a.isActive} onPress={a.onPress} title={a.title} />
      ))}
    </>
  );
}

const Anchor = (props: {
  isActive: boolean;
  onPress: PressableProps['onPress'];
  title: string;
}) => {
  const ref = useRef<View>(null);

  useEffect(() => {
    if (!props.isActive) {
      ref.current?.blur?.();
    }
  }, [props.isActive]);

  return (
    <Pressable
      ref={ref}
      onPress={props.onPress}
      borderRadius={0}
      style={[styles.pressable, props.isActive && styles.isActivePressable]}
      focusedStyle={styles.isFocusedPressable}
      hoveredStyle={styles.isHoveredPressable}
      outlineStyle={styles.hideOutline}
      outlinePressedStyle={styles.hideOutline}
      outlineFocusedStyle={styles.hideOutline}
      outlineDisabledStyle={styles.hideOutline}
      outlineHoveredStyle={styles.hideOutline}
    >
      <Title isActive={props.isActive} title={props.title} />
    </Pressable>
  );
};

const Title = ({ isActive, title }: { isActive: boolean; title: string }) => {
  const pressableState = usePressableState();
  return (
    <Text.Ui
      size="md"
      weight="semibold"
      style={[isActive && styles.isActiveText, pressableState.hovered && styles.isHoveredText]}
    >
      {title}
    </Text.Ui>
  );
};

const styles = StyleSheet.create({
  pressable: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderWidth: 0,
    borderBottomWidth: 3,
    borderColor: tokens.colors.$blackOpacity10,
  },
  isActivePressable: {
    borderColor: tokens.colors.$houseRedDarken,
  },
  isHoveredPressable: {
    borderColor: tokens.colors.$houseRedDarken,
  },
  isFocusedPressable: {
    borderColor: tokens.colors.$houseRedDarken,
  },
  isActiveText: {
    color: tokens.colors.$houseRedDarken,
  },
  isHoveredText: {
    color: tokens.colors.$houseRedDarken,
  },
  isFocusedText: {
    color: tokens.colors.$houseRedDarken,
  },
  hideOutline: {
    borderColor: tokens.colors.$transparent,
  },
});
