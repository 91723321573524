import { useState } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { useMaskedInputProps } from 'react-native-mask-input';

import { FormControlTextInput, IconDollar, RadioGroup, Text, XStack, YStack } from '@fhs/ui';
import { useIsDesktopBp } from '@fhs-legacy/frontend/src/hooks/breakpoints';
import { useUIContext } from '@fhs-legacy/frontend/src/state/ui';
import { getCurrencyMask } from '@fhs-legacy/frontend/src/utils/masks';

import { PickUpTipOption } from './pick-up-tip-option';

export type TipOption = {
  value: string;
  label: string;
};

type PickUpTipSelectorProps = {
  value: string;
  pickUpTipCents: number;
  tipOptions: TipOption[];
  onSelect: (value: string) => void;
  onChangeOtherValue: (value: string) => void;
};

export function PickUpTipSelector({
  tipOptions,
  value,
  pickUpTipCents,
  onSelect,
  onChangeOtherValue,
}: PickUpTipSelectorProps) {
  const { formatMessage } = useIntl();
  const { currencySymbol, formatCurrencyForLocale } = useUIContext();

  const [maskedTip, setMaskedTip] = useState('');

  const isDesktop = useIsDesktopBp();

  const { onChangeText, value: maskedValue } = useMaskedInputProps({
    value: maskedTip,
    onChangeText: (_, unmasked) => {
      setMaskedTip((+unmasked / 100).toFixed(2));
      onChangeOtherValue(unmasked);
    },
    mask: getCurrencyMask(currencySymbol ?? ''),
  });

  if (isDesktop) {
    return (
      <>
        <YStack style={styles.container}>
          <XStack style={styles.tipSelectorDesktop}>
            <Text weight="semibold" style={styles.tipText}>
              {formatMessage({ id: 'tip' })}:
            </Text>
            <RadioGroup style={styles.radioGroupDesktop} value={value} onChange={onSelect}>
              {tipOptions.map(tip => (
                <RadioGroup.Item value={tip.value} label={tip.label} key={tip.value}>
                  <PickUpTipOption text={tip.label} />
                </RadioGroup.Item>
              ))}
              <RadioGroup.Item value="other" label={formatMessage({ id: 'other' })}>
                <PickUpTipOption text={formatMessage({ id: 'other' })} />
              </RadioGroup.Item>
            </RadioGroup>
          </XStack>
          <XStack style={{ flex: 1, justifyContent: 'flex-end' }}>
            <Text weight="semibold" style={styles.tipText}>
              + {formatCurrencyForLocale(pickUpTipCents)}
            </Text>
          </XStack>
          {value === 'other' && (
            <YStack style={styles.inputContainerDesktop}>
              <Text weight="semibold">{formatMessage({ id: 'tipAmount' })}</Text>
              <FormControlTextInput
                placeholder={formatMessage({ id: 'tipInputPlaceholder' })}
                onChangeText={onChangeText}
                value={maskedValue}
                keyboardType="number-pad"
                icon={<IconDollar size={24} />}
              />
            </YStack>
          )}
        </YStack>
      </>
    );
  }

  return (
    <>
      <XStack style={styles.tipRow} testID="tip-amount">
        <Text weight="semibold" style={styles.tipText}>
          {formatMessage({ id: 'tip' })}
        </Text>
        <Text weight="semibold" style={styles.tipText}>
          + {formatCurrencyForLocale(pickUpTipCents)}
        </Text>
      </XStack>
      <YStack style={styles.container}>
        <RadioGroup style={styles.radioGroup} value={value} onChange={onSelect}>
          {tipOptions.map(tip => (
            <RadioGroup.Item value={tip.value} label={tip.label} key={tip.value}>
              <PickUpTipOption text={tip.label} />
            </RadioGroup.Item>
          ))}
          <RadioGroup.Item value="other" label={formatMessage({ id: 'other' })}>
            <PickUpTipOption text={formatMessage({ id: 'other' })} />
          </RadioGroup.Item>
        </RadioGroup>
        {value === 'other' && (
          <YStack style={styles.inputContainer}>
            <Text weight="semibold">{formatMessage({ id: 'tipAmount' })}</Text>
            <FormControlTextInput
              placeholder={formatMessage({ id: 'tipInputPlaceholder' })}
              onChangeText={onChangeText}
              value={maskedValue}
              keyboardType="number-pad"
              icon={<IconDollar size={24} />}
            />
          </YStack>
        )}
      </YStack>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
    flex: 1,
  },
  radioGroup: {
    flexDirection: 'row',
    gap: 16,
    justifyContent: 'space-between',
    flex: 1,
  },
  radioGroupDesktop: {
    flexDirection: 'row',
    gap: 16,
    flex: 1,
  },
  inputContainer: {
    marginTop: 16,
    gap: 8,
  },
  inputContainerDesktop: {
    flex: 1,
    width: '50%',
    gap: 4,
  },
  tipSelectorDesktop: {
    alignItems: 'center',
    gap: 16,
  },
  tipContainer: {
    marginTop: 8,
  },
  tipRow: { justifyContent: 'space-between', marginBottom: 8 },
  tipText: { fontSize: 14, lineHeight: 18 },
});
