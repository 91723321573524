import { useEffect, useState } from 'react';

import {
  _addItemToCart,
  _addOfferToCart,
  _commitOrder,
  _priceOrder,
  _removeItemFromCart,
  _removeOfferFromCart,
  _removePointsFromItem,
  _selectStore,
  _setDonationAmount,
  _usePointsOnItem,
} from '../backend/graphql';
import { cart } from '../backend/tmp-cart';
import { CartModel } from '../types';

export const GetCart = `query {
  cart {
    entries
  }
}`;

/*
 * represents a graphql query:
 *
 * query GetCart() {
 *   cart: Cart
 * }
 */
const memoryState: { current: CartModel | null } = { current: null };
export function useQuery(_query: typeof GetCart) {
  const [state, setState] = useState({
    fetching: !memoryState.current,
    error: null,
    data: memoryState.current,
  });

  useEffect(() => {
    cart.subscribe(data => {
      memoryState.current = data;
      setState({ fetching: false, error: null, data });
    });

    // simulate loading states..
    if (memoryState.current === null) {
      setTimeout(() => {
        memoryState.current = cart.getState();
        setState({
          fetching: false,
          error: null,
          data: cart.getState(),
        });
      }, 1000);
    }
  }, []);

  return state;
}

export const AddItemToCart = `mutation AddItemToCart($itemId: ID!) {
  addItemToCart(itemId: $itemId) {
  }
}`;

export const SelectStore = `mutation SelectStore($storeNumber: ID!, $serviceMode: ServiceMode!) {
  selectStore($storeNumber: ID!, $serviceMode: ServiceMode!) {
  }
}`;

export const RemoveOfferFromCart = `mutation RemoveOfferFromCart($offerId: ID!) {
  removeOfferFromCart(offerId: $offerId) {
  }
}`;

export const RemoveItemFromCart = `mutation RemoveItemFromCart($lineId: ID!) {
  removeItemFromCart(lineId: $lineId) {
  }
}`;

export const RemovePointsFromItem = `mutation RemovePointsFromItem($lineId: ID!) {
  removePointsFromItem(lineId: $lineId) {
  }
}`;

export const UsePointsOnItem = `mutation UsePointsOnItem($lineId: ID!) {
  usePointsOnItem(lineId: $lineId) {
  }
}`;

export const SetDonationAmount = `mutation SetDonationAmount($donationAmount: Int!) {
  setDonationAmount(lineId: $lineId) {
  }
}`;

export const PriceOrder = `mutation PriceOrder() {
  priceOrder() {
    cart
  }
}`;

export const CommitOrder = `mutation CommitOrder() {
  commitOrder() {
    cart
  }
}`;

export const AddOfferToCart = `mutation (){
  addOfferToCart() {
    cart
  }
}`;

type State<T> = { data: null | T; fetching: boolean; error: null | Error };
export function useMutation<T extends CartModel, V extends Record<string, any>>(
  mutation:
    | typeof AddItemToCart
    | typeof RemoveOfferFromCart
    | typeof RemoveItemFromCart
    | typeof UsePointsOnItem
    | typeof RemovePointsFromItem
    | typeof SetDonationAmount
    | typeof SelectStore
    | typeof PriceOrder
    | typeof CommitOrder
    | typeof AddOfferToCart,
  variables?: V
): [State<T>, (vars?: V) => Promise<T>] {
  const [state, setState] = useState<State<T>>({
    data: null,
    fetching: false,
    error: null,
  });

  return [
    state,
    async (vars?: Record<string, any>) => {
      let data;
      switch (mutation) {
        case SelectStore:
          setState(d => ({ fetching: true, data: d.data, error: null }));
          try {
            data = await _selectStore(
              vars?.id ?? variables?.id,
              vars?.serviceMode ?? variables?.serviceMode
            );
            setState({ fetching: false, error: null, data });
          } catch (error) {
            if (error instanceof Error) {
              setState({ fetching: false, error, data: null });
            }
            throw error;
          }
          return data;

        case AddOfferToCart:
          setState(d => ({ fetching: true, data: d.data, error: null }));
          try {
            data = await _addOfferToCart(vars?.offerId ?? variables?.offerId, []);
            setState({ fetching: false, error: null, data });
          } catch (error) {
            if (error instanceof Error) {
              setState({ fetching: false, error, data: null });
            }
            throw error;
          }
          return data;

        case AddItemToCart:
          setState(d => ({ fetching: true, data: d.data, error: null }));
          try {
            data = await _addItemToCart(vars?.itemId ?? variables?.itemId);
            setState({ fetching: false, error: null, data });
          } catch (error) {
            if (error instanceof Error) {
              setState({ fetching: false, error, data: null });
            }
            throw error;
          }
          return data;

        case RemoveItemFromCart:
          setState(d => ({ fetching: true, data: d.data, error: null }));
          try {
            data = await _removeItemFromCart(vars?.lineId ?? variables?.lineId);
            setState({ fetching: false, error: null, data });
          } catch (error) {
            if (error instanceof Error) {
              setState({ fetching: false, error, data: null });
            }
            throw error;
          }
          return data;

        case UsePointsOnItem:
          setState(d => ({ fetching: true, data: d.data, error: null }));
          try {
            data = await _usePointsOnItem(vars?.lineId ?? variables?.lineId);
            setState({ fetching: false, error: null, data });
          } catch (error) {
            if (error instanceof Error) {
              setState({ fetching: false, error, data: null });
            }
            throw error;
          }
          return data;

        case RemovePointsFromItem:
          setState(d => ({ fetching: true, data: d.data, error: null }));
          try {
            data = await _removePointsFromItem(vars?.lineId ?? variables?.lineId);
            setState({ fetching: false, error: null, data });
          } catch (error) {
            if (error instanceof Error) {
              setState({ fetching: false, error, data: null });
            }
            throw error;
          }
          return data;

        case RemoveOfferFromCart:
          setState(d => ({ fetching: true, data: d.data, error: null }));
          try {
            data = await _removeOfferFromCart(vars?.offerId ?? variables?.offerId);
            setState({ fetching: false, error: null, data });
          } catch (error) {
            if (error instanceof Error) {
              setState({ fetching: false, error, data: null });
            }
            throw error;
          }
          return data;

        case SetDonationAmount:
          setState(d => ({ fetching: true, data: d.data, error: null }));
          try {
            data = await _setDonationAmount(vars?.donationAmount ?? variables?.donationAmount);
            setState({ fetching: false, error: null, data });
          } catch (error) {
            if (error instanceof Error) {
              setState({ fetching: false, error, data: null });
            }
            throw error;
          }
          return data;

        case PriceOrder:
          setState(d => ({ fetching: true, data: d.data, error: null }));
          try {
            data = await _priceOrder();
            setState({ fetching: false, error: null, data });
          } catch (error) {
            if (error instanceof Error) {
              setState({ fetching: false, error, data: null });
            }
            throw error;
          }
          return data;

        case CommitOrder:
          setState(d => ({ fetching: true, data: d.data, error: null }));
          try {
            data = await _commitOrder();
            setState({ fetching: false, error: null, data });
          } catch (error) {
            if (error instanceof Error) {
              setState({ fetching: false, error, data: null });
            }
            throw error;
          }
          return data;
      }
    },
  ];
}
