import {
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  type TouchableWithoutFeedbackProps,
} from 'react-native';

import { tokens } from '../../tokens';
import { Text, TextProps } from '../text';

import { useRadioItem } from './context';

export type RadioLabelProps = TouchableWithoutFeedbackProps & {
  textProps?: TextProps;
};
export function RadioLabel({ textProps, ...touchableProps }: RadioLabelProps) {
  const radioItem = useRadioItem();

  return (
    <TouchableWithoutFeedback
      // Accessible set to false because the control should be the accessible element, this would be redundant
      accessible={false}
      aria-hidden
      // @ts-ignore RN does not like that we are making the touchable non-focusable
      focusable={false}
      onPress={radioItem.handlePress}
      {...touchableProps}
    >
      <Text.Ui
        size="md"
        {...textProps}
        style={[
          styles.touchableText,
          textProps?.style,
          radioItem.disabled && styles.touchableTextDisabled,
        ]}
      >
        {radioItem.label}
      </Text.Ui>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  touchableText: {
    alignSelf: 'center',
    ...Platform.select({ web: { cursor: 'pointer' } }),
  },
  touchableTextDisabled: {
    color: tokens.colors.$disabledText,
    // web allows this, but react native changed their types
    ...(Platform.select({ web: { cursor: 'unset' } }) as any),
  },
});
