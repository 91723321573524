import { useMemo } from 'react';

import { ServiceModeLocationProps, ServiceModeLocationType } from '@fhs/ui';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { useOrderContext } from '@fhs-legacy/frontend/src/state/order';
import { ServiceMode, useServiceModeContext } from '@fhs-legacy/frontend/src/state/service-mode';
import { useStoreContext } from '@fhs-legacy/frontend/src/state/store';
import { useUIContext } from '@fhs-legacy/frontend/src/state/ui';

function getTypeFromServiceMode(opts: {
  serviceMode?: ServiceMode;
  enableDeliveryFeeOnServiceModeBar?: boolean;
}): ServiceModeLocationType {
  if (!opts.serviceMode) {
    return 'location';
  }

  if (
    opts.serviceMode === ServiceMode.CATERING_DELIVERY ||
    opts.serviceMode === ServiceMode.CATERING_PICKUP
  ) {
    return 'catering';
  }

  if (opts.serviceMode === ServiceMode.DELIVERY) {
    return opts.enableDeliveryFeeOnServiceModeBar ? 'deliveryWithFees' : 'delivery';
  }

  return 'pickup';
}

export function useServiceModeLocation(): Pick<
  ServiceModeLocationProps,
  'type' | 'storeText' | 'serviceModeSubtitle'
> {
  const { serviceMode, deliveryFees } = useServiceModeContext();
  const enableDeliveryFeeOnServiceModeBar = Boolean(
    useFlag(LaunchDarklyFlag.SHOW_DELIVERY_FEE_ON_SERVICE_MODE_BAR)
  );
  const isDelivery = serviceMode === ServiceMode.DELIVERY;

  const { store } = useStoreContext();
  const { deliveryAddress } = useOrderContext();
  const storeText = isDelivery ? deliveryAddress?.addressLine1 : store.name;

  const { formatCurrencyForLocale: currencyFormatter } = useUIContext();
  const serviceModeSubtitle =
    isDelivery && enableDeliveryFeeOnServiceModeBar && deliveryFees?.deliveryTotalFee
      ? `${currencyFormatter(deliveryFees.deliveryTotalFee)} Delivery Fee (Per Order)}`
      : '';

  return useMemo(
    () => ({
      type: getTypeFromServiceMode({ serviceMode, enableDeliveryFeeOnServiceModeBar }),
      storeText,
      serviceModeSubtitle,
    }),
    [serviceMode, enableDeliveryFeeOnServiceModeBar, storeText, serviceModeSubtitle]
  );
}
