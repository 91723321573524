import { Amplify } from 'aws-amplify';
import { type SelectionSet, generateClient } from 'aws-amplify/data';

import { type Schema } from '@fhs/backend/amplify/data/resource';
// @ts-ignore
import outputs from '@fhs/backend/amplify_outputs.json';

Amplify.configure(outputs);
export const client = generateClient<Schema>();

export type { Schema };
// This is for select certain fields from query
export type { SelectionSet };
