import { ReactNode, useMemo } from 'react';

import { CheckboxContext } from './context';
import type { CheckedState } from './types';

export type CheckboxProps = {
  children: ReactNode;
  onChange: (checked: CheckedState) => void;
  checked: CheckedState;
  disabled?: boolean;
  label: string;
};

export function Checkbox({ children, onChange, checked, disabled = false, label }: CheckboxProps) {
  const contextValue = useMemo(
    () => ({
      label,
      handlePress: () => {
        if (disabled) {
          return;
        }

        if (checked === 'mixed') {
          onChange(true);
          return;
        }

        onChange(!checked);
      },
      checked,
      disabled,
    }),
    [label, onChange, checked, disabled]
  );

  return <CheckboxContext.Provider value={contextValue}>{children}</CheckboxContext.Provider>;
}
