import { Image, ImageProps } from 'expo-image';
import { StyleSheet } from 'react-native';

import { LtoContentCA } from '../lto-content/lto-content-ca';

export type TopMarketingImageProps = Omit<ImageProps, 'children' | 'source'>;
export function TopMarketingImage(props: TopMarketingImageProps) {
  return (
    <LtoContentCA
      thanksgivingTurkeySub={
        <Image
          source={require('./thanksgiving-turkey-sub-ca.webp')}
          placeholder={{ thumbhash: 'HoqGDQImeUJwp2WJqClg4uUFCIeEiWeoiA' }}
          alt="It's back! Thanksgiving Turkey Sub"
          contentFit="contain"
          {...props}
          style={[styles.marketingImage, props.style]}
        />
      }
      default={
        <Image
          source={require('./hook-and-ladder.webp')}
          placeholder={{ thumbhash: '2lmKFYInl3hziXDat5hzYEsGJ0eEh4D4Zw' }}
          alt="Hook & Ladder"
          contentFit="contain"
          {...props}
          style={[styles.marketingImage, props.style]}
        />
      }
    />
  );
}

const styles = StyleSheet.create({
  marketingImage: {
    aspectRatio: 1600 / 1622,
    width: '100%',
    height: '100%',
    minHeight: 200,
    alignSelf: 'center',
  },
});
