import React from 'react';

import { ProductDetail } from 'components/product-detail';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import { ProductWizardProvider } from 'state/product-wizard';
import { ProductMenuObject } from 'state/product-wizard/types';

export interface IMenuWizardProps {
  product: ProductMenuObject;
}
export const ProductWizard = React.memo<IMenuWizardProps>(({ product }) => {
  useRumPageView('product-wizard', 'Product Wizard');
  return (
    <ProductWizardProvider product={product}>
      <ProductDetail />
    </ProductWizardProvider>
  );
});
