import { OrderDisplayType } from '@fhs/ui/src/components/order-display/types';
import { getServiceModeBottomCTAText } from '@fhs-legacy/frontend/src/pages/cart/types';
import { IHistoricalOrder } from '@rbi-ctg/menu';
import { useUIContext } from 'state/ui';
import { parseStringifiedJSON } from 'utils/parse-string';

// This is a temporary function to transform the shape of the serverOrder
export function freshOrderTransformer({
  storeName,
  orderType,
  serverOrder,
  buildImageUrl,
  formatMessage,
}: {
  storeName: string;
  orderType: OrderDisplayType;
  serverOrder: IHistoricalOrder;
  buildImageUrl: ReturnType<typeof useUIContext>['buildImageUrl'];
  formatMessage: Function;
}) {
  if (!serverOrder || Object.keys(serverOrder).length === 0) {
    return null;
  }

  const serviceModeTitle = getServiceModeBottomCTAText(formatMessage)[serverOrder.cart.serviceMode];

  return {
    type: orderType,
    serviceMode: serverOrder.cart.serviceMode,
    serviceModeTitle,
    id: serverOrder.rbiOrderId,
    storeName: storeName || serverOrder.order?.storeName,
    storeAddress: `${serverOrder.cart.storeAddress.addressLine1}. ${serverOrder.cart.storeAddress.city}, ${serverOrder.cart.storeAddress.state}`,
    items:
      serverOrder.cart.cartEntries?.reduce((acc: any[], entry: any) => {
        if (entry.isDonation || entry.lineId === 'discount-offer') {
          return acc;
        }

        const image = parseStringifiedJSON({ value: entry.image });

        return acc.concat({
          image: image ? buildImageUrl(image) : '',
          name: entry.name || '',
          quantity: entry.quantity || 1,
        });
      }, []) ?? [],
  };
}
