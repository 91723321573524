import { http as loyaltyHttp } from '@fhs/loyalty';

import { createLogger } from '../debugger';
import { CartModel, ID, ItemEntry, ServiceMode } from '../types';

import { fetchCommitOrderMutation } from './legacy/fetch-commit-order-mutation';
import { fetchPriceOrderMutation } from './legacy/fetch-price-order-mutation';
import { fetchStoreInformation } from './legacy/fetch-store-information';
import { transformCartToV1Cart } from './legacy/transform-cart-to-v1-cart';
import { getCart, updateCart } from './update-cart';

const userId = 'foobar';

export async function _priceOrder(): Promise<CartModel> {
  const logger = createLogger('priceOrder');

  const cart = await getCart(userId);

  if (cart.state === 'PRICE_REQUESTED' || cart.state === 'PRICE_SUCCESSFUL') {
    return cart;
  }

  if (cart.state === 'VALID') {
    const v1Cart = await transformCartToV1Cart('app', cart, logger);
    // TODO: Validate that `status` is a valid state in this machine. I think it will only be PRICE_REQUESTED
    const { rbiOrderId, status } = await fetchPriceOrderMutation(v1Cart, logger);

    // Update the cart with the new status and hold reference to the rbiOrderId metadata
    cart.state = status;
    cart.metadata.rbiOrderId = rbiOrderId;
    await updateCart(cart, logger);

    return await getCart(userId);
  }

  throw new Error('Cannot price cart in current state');
}

export async function _addOfferToCart(offerId: ID, entries: { itemId: ID }[]) {
  const logger = createLogger('addOfferToCart');

  const cart = await getCart(userId);
  const offer = await loyaltyHttp.getOffer(offerId);

  logger.log('converting items');
  const items = await Promise.all(entries.map(({ itemId }) => createItem(itemId)));

  cart.entries.push(...items);
  cart.state = 'NEEDS_LOYALTY_VALIDATION';
  cart.appliedIncentives = [
    {
      offerId,
      appliedCartEntries: items.map(e => e.lineId),
      savingsAmount: null,
      type: offer.type,

      // These fields dont need to be saved in data.
      // they can be handled via graphql resolvers
      title: offer.name,
      description: offer.description,
    },
  ];

  await updateCart(cart, logger);

  return await getCart(userId);
}

export async function _usePointsOnItem(lineId: ID) {
  const logger = createLogger('usePointsOnItem');

  const cart = await getCart(userId);
  const item = cart.entries.find(item => item.lineId === lineId);

  if (!item) {
    throw new Error('Item not found in cart to redeem points on');
  }

  const offer = await loyaltyHttp.getRewardsOffer(item.itemId);

  cart.state = 'NEEDS_LOYALTY_VALIDATION';
  cart.appliedIncentives = [
    {
      offerId: offer.id,
      appliedCartEntries: [item.lineId],
      savingsAmount: null,
      type: offer.type,

      // These fields dont need to be saved in data.
      // they can be handled via graphql resolvers
      title: offer.name,
      description: offer.description,
    },
  ];

  await updateCart(cart, logger);

  return await getCart(userId);
}

export async function _removePointsFromItem(lineId: ID) {
  const logger = createLogger('removePointsFromItem');

  const cart = await getCart(userId);
  cart.appliedIncentives = cart.appliedIncentives.filter(
    incentive => !incentive.appliedCartEntries.includes(lineId)
  );

  cart.state = 'NEEDS_LOYALTY_VALIDATION';

  await updateCart(cart, logger);

  return await getCart(userId);
}

export async function _removeOfferFromCart(offerId: ID) {
  const logger = createLogger('removeOfferFromCart');

  const cart = await getCart(userId);
  const incentive = cart.appliedIncentives.find(i => i.offerId === offerId);

  if (!incentive) {
    throw new Error('Cannot find incentive to remove from cart');
  }

  logger.log('Removing cart entries attached with this offer');
  const cartEntries = cart.entries.filter(
    item => !(incentive.appliedCartEntries ?? []).includes(item.lineId)
  );
  cart.entries = cartEntries;

  cart.state = 'NEEDS_LOYALTY_VALIDATION';
  cart.appliedIncentives = []; // this would need to be updated if we support more than 1

  await updateCart(cart, logger);

  return await getCart(userId);
}

// TODO: serviceMode is coming in as the wrong values from the integration
// on the store locator page. need to figure out what service modes we actually
// want to have
//
// TODO: Ensure all items are available at this store. Figure out with
// product what we want to do if items are not available.
export async function _selectStore(id: ID, serviceMode: ServiceMode) {
  const logger = createLogger('selectStore');
  const cart = await getCart(userId);

  const storeInformation = await fetchStoreInformation(id, logger);
  cart.store = storeInformation;
  cart.serviceMode = serviceMode;
  cart.state = 'NEEDS_LOYALTY_VALIDATION';

  await updateCart(cart, logger);
  return await getCart(userId);
}

export async function _commitOrder(): Promise<CartModel> {
  const logger = createLogger('commitOrder');

  const cart = await getCart(userId);
  if (cart.state !== 'PRICE_SUCCESSFUL') {
    throw new Error('Cannot commit an order that is not successfully priced');
  }

  if (!cart.metadata.rbiOrderId) {
    throw new Error('Cannot commit cart with no rbiOrderId');
  }

  cart.state = 'INSERT_REQUESTED';
  const res = await fetchCommitOrderMutation(cart.metadata.rbiOrderId, logger);
  cart.state = res.status;
  await updateCart(cart, logger);

  return await getCart(userId);
}

/*
 * represents a graphql mutation:
 *
 * mutation AddItemToCart($id: ID!) {
 *   addItemToCart(id: $id): Cart
 * }
 */
export async function _addItemToCart(itemId: ID): Promise<CartModel> {
  const logger = createLogger('addItemToCart');

  const cart = await getCart(userId);
  const item = await createItem(itemId);
  cart.entries.push(item);
  cart.state = 'NEEDS_LOYALTY_VALIDATION';
  logger.log('Adding cart line', item.lineId, 'and settings state to', cart.state);
  await updateCart(cart, logger);
  return getCart(userId);
}

/*
 * represents a graphql mutation:
 *
 * mutation RemoveItemFromCart($lineId: ID!) {
 *   removeItemFromCart(lineId: $id): Cart
 * }
 */
export async function _removeItemFromCart(lineId: ID): Promise<CartModel> {
  const logger = createLogger('removeItemFromCart');
  const cart = await getCart(userId);

  const index = cart.entries.findIndex(i => i.lineId === lineId);
  cart.entries.splice(index, 1);
  cart.state = 'NEEDS_LOYALTY_VALIDATION';
  logger.log('Removing cart item line', lineId, 'and settings state to', cart.state);
  await updateCart(cart, logger);

  return getCart(userId);
}

/*
 * represents a graphql mutation:
 *
 * mutation RemoveItemFromCart($lineId: ID!) {
 *   removeItemFromCart(lineId: $id): Cart
 * }
 */
export async function _setDonationAmount(donationAmount: number): Promise<CartModel> {
  const logger = createLogger('setDonationAmount');
  const cart = await getCart(userId);

  cart.donationAmount = donationAmount;
  cart.state = 'NEEDS_LOYALTY_VALIDATION';
  await updateCart(cart, logger);

  return getCart(userId);
}

// PRETEND PRIVATE METHODS
//------------------------

async function createItem(itemId: ID): Promise<ItemEntry> {
  function uuid(): ID {
    return [
      Math.random().toString(16).slice(7),
      Math.random().toString(16).slice(11),
      Math.random().toString(16).slice(11),
      Math.random().toString(16).slice(11),
      Math.random().toString(16).slice(7),
    ].join('-');
  }

  const lineId = uuid();
  const productData = await dynamo_getItemData(itemId);
  return {
    itemId,
    displayName: productData.displayName,
    // confirm with menu team the basePrice/variants approach
    price: productData.basePrice,
    pointCost: null,
    imageUrl: productData.image.asset.uri,
    lineId,
    type: 'item',
    modifiers: [],
  };
}

// eslint-disable-next-line
import { menuItemData } from '@fhs/menu/src/data/__mocks__/menu-item-data';
async function dynamo_getItemData(slug: string) {
  return menuItemData[slug];
}
