import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FlatList, ListRenderItemInfo, View, useWindowDimensions } from 'react-native';

import { ScrollView, createMqStyles, tokens } from '@fhs/ui';
import { LoyaltyPromoCode } from '@fhs-legacy/frontend/src/pages/loyalty/loyalty-offers/loyalty-promo-code';

import { offers } from '../backend/mock.data';
import { OfferCard, OfferCardProps } from '../components/offer-card';
import { SwitchToDelivery } from '../components/switch-to-delivery';

export function AllOffersPage() {
  const mqStyles = useMqStyles();
  const { formatMessage, formatDate } = useIntl();

  const { width } = useWindowDimensions();
  // edge case for screens like the iphone SE 320x568
  const isSmallPhone = width <= 320;
  const offerCardProps: OfferCardProps[] = useMemo(() => {
    const offerCards: OfferCardProps[] = offers.map(offer => ({
      imageUrl: offer.image,
      title: offer.name,
      description: 'Expires ' + formatDate(offer.expiryDate, { day: '2-digit', month: '2-digit' }),
      onPress: () => null,
      isDisabled: false,
      onPressButton: () => null,
      onPressImage: () => null,
      buttonLabel: formatMessage({ id: 'applyOffer' }),
    }));
    // Add a hidden element to allow the last item to be render with correct size. This will break if column is more than 2
    if (!isSmallPhone && offers.length % 2 === 1) {
      offerCards.push({
        isHidden: true,
        imageUrl: '',
        title: '',
        description: '',
        buttonLabel: '',
        onPressButton: () => {},
        onPressImage: () => {},
      });
    }
    return offerCards;
  }, [formatDate]);

  const renderOffer = useCallback(
    ({ item }: ListRenderItemInfo<OfferCardProps>) => {
      const smallPhoneStyle = isSmallPhone ? [mqStyles.smallPhoneScreen] : [];

      return <OfferCard {...item} style={[mqStyles.item, ...smallPhoneStyle]} />;
    },
    [isSmallPhone, mqStyles.smallPhoneScreen, mqStyles.item]
  );

  const customProps = isSmallPhone
    ? {}
    : {
        numColumns: 2,
        columnWrapperStyle: mqStyles.column,
      };

  const smallPhoneKey = isSmallPhone ? 'small-screen' : 'normal-screen';
  return (
    <ScrollView style={mqStyles.page}>
      <View>
        <View style={mqStyles.header}>
          <LoyaltyPromoCode
            buttonLabel={formatMessage({ id: 'haveAPromoCode' })}
            style={mqStyles.promoButton}
          />
        </View>

        <FlatList
          key={smallPhoneKey}
          style={mqStyles.content}
          contentContainerStyle={[mqStyles.flatList]}
          data={offerCardProps}
          renderItem={renderOffer}
          {...customProps}
        />
        <View style={mqStyles.footer}>
          <SwitchToDelivery style={mqStyles.switchToDelivery} />
        </View>
      </View>
    </ScrollView>
  );
}

const useMqStyles = createMqStyles({
  page: {
    $base: {
      backgroundColor: tokens.colors.$white,
      paddingHorizontal: 16,
      flexDirection: 'column',
      paddingBottom: 16,
    },
  },
  content: {
    $base: {
      flexGrow: 0,
    },
    $gteDesktop: {
      alignSelf: 'center',
    },
  },
  header: {
    $base: {
      alignItems: 'center',
    },
  },
  footer: {
    $gteDesktop: {
      alignSelf: 'center',
    },
  },
  switchToDelivery: {
    $base: {
      marginTop: 12,
    },
  },
  flatList: {
    $base: {
      justifyContent: 'center',
      rowGap: 12,
      paddingBottom: 4,
      flexGrow: 0,
    },
  },
  item: {
    $base: {
      minWidth: 160,
      maxWidth: 400,
      flex: 0.5,
    },
  },
  column: {
    $base: {
      gap: 12,
    },
  },
  promoButton: {
    $base: {
      marginTop: 12,
    },
    $ltDesktop: {
      width: '100%',
    },
  },

  smallPhoneScreen: {
    $base: {
      flex: 1,
      width: 288,
      height: 232,
    },
  },
});
