import { Logger } from '../../debugger';
import { CartModel } from '../../types';

import { LegacyPriceOrderInput, VendorConfig } from './types';

// TODO: `ICart` is not actually the data type we want...
// we want the data shape that belongs to the mutation type
// figure out how we can get that
export async function transformCartToV1Cart(
  platform: 'web' | 'app',
  cart: CartModel,
  logger: Logger
): Promise<LegacyPriceOrderInput> {
  logger.log('transforming to v1 cart');

  if (!cart.store) {
    throw new Error('A Store must be selected to price an order');
  }

  // calculate subtotal. should this be a property on the model?
  // I worry that someone could mess that up and have the value in the data.
  const requestedAmountCents = cart.entries.reduce((p, e) => p + e.price, 0);

  return {
    storeAddress: {
      addressLine1: cart.store.addressLine1,
      city: cart.store.city,
      country: cart.store.country,
      zip: cart.store.zip,
      state: cart.store.state,
      phoneNumber: cart.store.phoneNumber,
    },
    cartEntries: convertCartEntries(cart.entries),
    storeId: cart.store.id,
    storePosId: cart.store.posRestaurantId,
    brand: 'FHS',
    platform,
    posVendor: cart.store.posVendor,
    serviceMode: mapServiceModeToV1(cart.serviceMode),
    requestedAmountCents,
    rewardsApplied: null,
    cartVersion: 1,
    customerLocale: 'en-US',
    customerName: null,
    redeemReward: false,

    // dead field - replaced by appliedIncentives
    appliedOffers: [],
  };
}

function convertCartEntries(entries: CartModel['entries']): LegacyPriceOrderInput['cartEntries'] {
  // TODO: This only handles items and not combos or offers
  return entries.map(entry => ({
    // image: '',
    lineId: entry.lineId,
    name: entry.displayName,
    price: entry.price,
    sanityId: entry.itemId,
    quantity: 1,
    // url: '/menu/item-e50ed0b1-49e8-4f81-89cd-2bd0d614cbf3',
    type: 'Item',
    vendorConfigs: {
      ncr: makePlu('11220'),
      ncrDelivery: makePlu('11220'),
      toast: makePlu('900000000526264403/900000000301988522'),
      toastDelivery: makePlu('900000000526264403/900000000301988522'),
    },

    // TODO: Handle modifiers
    children: [
      {
        children: [
          {
            children: [],
            lineId: '0169b515-8f42-4ea5-ae9c-8f3641c02b21',
            name: 'White Bread',
            pickerSelections: '{}',
            price: 0,
            quantity: 1,
            sanityId: '6223f2f5c74e',
            type: 'ItemOptionModifier',
            url: '',
            vendorConfigs: {
              ncr: makePlu('140210'),
              ncrDelivery: makePlu('140210'),
              toast: makePlu('900000000306722169/900000000306728404'),
              toastDelivery: makePlu('900000000306722169/900000000306728404'),
            },
          },
        ],
        lineId: 'e881de8d-b5ba-4c7d-a37e-cc9795243496',
        name: 'Bread Choice',
        pickerSelections: '{}',
        quantity: 1,
        sanityId: '532002ae60cd',
        type: 'ItemOption',
        url: '',
        vendorConfigs: {
          ncr: { pluType: 'ignore' },
          ncrDelivery: { pluType: 'ignore' },
          toast: { pluType: 'ignore' },
          toastDelivery: { pluType: 'ignore' },
        },
      },
      {
        children: [
          {
            children: [],
            lineId: 'c942b502-95c8-4758-839f-04189ad4b203',
            name: 'Pickle Spear (on side)',
            pickerSelections: '{}',
            price: 0,
            quantity: 1,
            sanityId: '9a91996825ec',
            type: 'ItemOptionModifier',
            url: '',
            vendorConfigs: {
              ncr: makePlu('123510'),
              ncrDelivery: makePlu('123510'),
              toast: makePlu('900000000306636786/900000000420891046'),
              toastDelivery: makePlu('900000000306636786/900000000420891046'),
            },
          },
        ],
        lineId: 'a8ce14c6-a91a-44e3-8dfd-ac153ddecfab',
        name: 'Pickle Spear (on side)',
        pickerSelections: '{}',
        quantity: 1,
        sanityId: 'd1c5e2fded24',
        type: 'ItemOption',
        url: '',
        vendorConfigs: {
          ncr: { pluType: 'ignore' },
          ncrDelivery: { pluType: 'ignore' },
          toast: { pluType: 'ignore' },
          toastDelivery: { pluType: 'ignore' },
        },
      },
    ],
  }));
}

///------------------------------
/// Map new serviceMode values to the ICart serviceModel enum
function mapServiceModeToV1(serviceMode: CartModel['serviceMode']) {
  switch (serviceMode) {
    case 'delivery':
      return 'DELIVERY';
    case 'pickup':
      return 'TAKEOUT';
    default:
      return serviceMode;
  }
}

function makePlu(constantPlu?: string): VendorConfig {
  if (constantPlu) {
    return {
      pluType: 'constantPlu',
      constantPlu,
    };
  }

  return {
    pluType: 'ignore',
  };
}
