import {
  ICartEntry,
  IComboSlot,
  IItem,
  ISection,
  MenuObject,
  PickerSelection,
} from '@rbi-ctg/menu';
import { IModifierSelectionAction } from 'utils/wizard';

export enum ProductWizardStep {
  ProductHome,
  ProductCustomization,
}

export type ProductMenuObject = Exclude<MenuObject, ISection>;

export type UserSelectionPickerAspect = { [id: string]: string };

export interface IUserSelectionComboSlotData {
  comboSlot: IComboSlot | null;
  selectedItem: IItem | null;
}
export type UserSelectionComboSlot = Map<string, IUserSelectionComboSlotData>;

export type IModifierSelectionActionWithData = IModifierSelectionAction & {
  isSanityDefaultSelection: boolean;
};

export type UserSelectionModifierMap = {
  [modifierOptionKey: string]: IModifierSelectionActionWithData;
};

export type UserSelectionModifierSlotMap = {
  [modifierKey: string]: UserSelectionModifierMap;
};

export type UserSelectionModifier = {
  [slotKey: string]: UserSelectionModifierSlotMap;
};

export interface IUserSelection {
  pickerAspects?: UserSelectionPickerAspect;
  comboSlot?: UserSelectionComboSlot;
  modifiers: UserSelectionModifier;
}

/**
 * Tracks if the user made any modifications on the selection options.
 */
export interface IUserManualModifications {
  pickerAspects: boolean;
  comboSlot: boolean;
  modifiers: boolean;
}

export type Product = PickerSelection;

export type ProductOption = IItem | IComboSlot;

export type ProductToCustomize = { product: IItem; slotKey: string };

export type SubmitCustomizationsFn = (args: {
  modifiers: UserSelectionModifierSlotMap;
  selectedItem: IItem;
  slotKey: string;
}) => void;

export interface IProductWizardContext {
  /**
   * The raw menu data object passed to wizard
   */
  menuObject: ProductMenuObject;
  /**
   * The order quantity for the selected product
   */
  productQuantity: number;
  /**
   * The selected Combo/Item that can be customized and added to cart
   */
  selectedProduct: Product;
  /**
   * Sets the order quantity for the selected product
   */
  setProductQuantity: React.Dispatch<React.SetStateAction<number>>;
  /**
   * Method to submit customizations
   */
  submitCustomizations: SubmitCustomizationsFn;
  /**
   * The user's current selections (pickerAspects, comboSlots, modifiers)
   */
  userSelections: IUserSelection;
  /**
   * Tracks if the user has made any modifications to each selection (pickerAspects, comboSlots, modifiers)
   */
  manualModifications: IUserManualModifications;
  /**
   * Method to update picker aspect selection
   */
  updatePickerAspectSelections: (args: UserSelectionPickerAspect) => void;
  /**
   * Indicates if there have been any user modifications to any combo item
   */
  isDirty: boolean;

  // HAAAAAACK: To be used only in ./hooks/use-product-wizard-flow
  _wizardFlowStep: ProductWizardStep;
  _setWizardFlowStep: (step: ProductWizardStep) => void;
  _slotKeyToCustomize: string;
  _setSlotKeyToCustomize: (slotKey: string) => void;
}

export interface IProductWizardProvider {
  product: ProductMenuObject;
}

export interface SetDefaultSelectionsArgs {
  menuObject: ProductMenuObject;
  pickerSelectionOverrides?: Record<string, string>;
  cartEntryOverrides?: ICartEntry;
}

export enum ModifierTypes {
  NON_NUMERIC = 'NON_NUMERIC',
  NUMERIC = 'NUMERIC',
  CHECKBOX = 'CHECKBOX',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
}

export type ProductWizardParams = {
  id: string;
  isEdit?: 'true' | 'false';
  isFavorite?: 'true' | 'false';
  isFromCart?: 'true' | 'false';
  isFromSurprise?: 'true' | 'false';
  rewardBenefitId?: string;
  rewardId?: string;
  currentStep?: string;
  slotKeyToCustomize?: string;
};

export interface IProductWizardFlow {
  id: string;
  isEdit: boolean;
  isFavorite: boolean;
  isFromCart: boolean;
  isFromSurprise: boolean;
  rewardBenefitId: string | undefined;
  rewardId: string | undefined;
  /**
   * Current step in the flow
   */
  currentStep: ProductWizardStep;
  /**
   * The slot key to be customized from combo slot selections
   */
  slotKeyToCustomize: string;
  /**
   * Navigates to the wizard home page
   */
  goToHome: () => void;
  /**
   * Navigates to the product customization screen
   */
  goToCustomizeProduct: (slotKey: string) => void;
}
