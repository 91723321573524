import { Platform, StyleSheet } from 'react-native';

import { tokens } from '../../tokens';
import { Text, TextProps } from '../text/';

import {
  CheckboxTouchableWithoutFeedback,
  CheckboxTouchableWithoutFeedbackProps,
} from './checkbox-touchable-without-feedback';
import { useCheckbox } from './context';

export type CheckboxLabelProps = CheckboxTouchableWithoutFeedbackProps & {
  textProps?: TextProps;
};

export function CheckboxLabel({ textProps, ...touchableProps }: CheckboxLabelProps) {
  const checkbox = useCheckbox();

  return (
    <CheckboxTouchableWithoutFeedback {...touchableProps}>
      <Text.Ui
        size="md"
        {...textProps}
        style={[
          styles.touchableText,
          textProps?.style,
          checkbox.disabled && styles.touchableTextDisabled,
        ]}
      >
        {checkbox.label}
      </Text.Ui>
    </CheckboxTouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  touchableText: {
    alignSelf: 'center',
    ...Platform.select({ web: { cursor: 'pointer' } }),
  },
  touchableTextDisabled: {
    color: tokens.colors.$disabledText,
    ...(Platform.select({ web: { cursor: 'unset' } }) as any),
  },
});
