import { type MenuItem } from '../../types';
import { getUrlForItemDetailPageFromSlug } from '../../utils';

export const menuItemData: Record<string, MenuItem> = {
  'hook-and-ladder': {
    id: 'hook-and-ladder',
    slug: 'hook-and-ladder',
    displayName: 'Hook & Ladder',
    url: 'REPLACE',
    isAvailable: true,
    badge: 'none',
    image: {
      altText: 'Hook and Ladder',
      asset: {
        uri: 'https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/402cb5f1e48f8035f7842e30713b4bc4b0d91576-1340x783.png?w=1340&q=100&fit=max&auto=format',
        blurHash: '',
      },
    },
    descriptionShort: 'Turkey Breast, Honey Ham, and more.',
    description:
      'Smoked turkey breast, honey ham, and melted Monterey Jack, served with lettuce, tomato, onion, mayo, and deli mustard with a dill pickle spear served on the side.',

    basePrice: 899,
    variants: [
      {
        name: 'Medium',
        price: 899,
      },
      {
        name: 'Large',
        price: 1099,
      },
    ],
  },
  'firehouse-hero': {
    id: 'firehouse-hero',
    slug: 'firehouse-hero',
    displayName: 'Firehouse Hero',
    url: 'REPLACE',
    isAvailable: true,
    badge: 'none',
    image: {
      altText: 'Firehouse Hero',
      asset: {
        uri: 'https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/402cb5f1e48f8035f7842e30713b4bc4b0d91576-1340x783.png?w=1340&q=100&fit=max&auto=format',
        blurHash: '',
      },
    },
    basePrice: 899,
    descriptionShort:
      'Premium roast beef, smoked turkey breast, Virginia honey ham, and melted provolone, served fully involved (mayo, deli mustard, lettuce, tomato, onion, and a kosher dill pickle on the side)',
    description:
      'Premium roast beef, smoked turkey breast, Virginia honey ham, and melted provolone, served fully involved (mayo, deli mustard, lettuce, tomato, onion, and a kosher dill pickle on the side)',
  },
  'pepperoni-pizza-meatball-sub': {
    id: 'pepperoni-pizza-meatball-sub',
    slug: 'pepperoni-pizza-meatball-sub',
    displayName: 'Pepperoni Pizza Meatball Sub',
    url: 'REPLACE',
    isAvailable: true,
    badge: 'none',
    image: {
      altText: 'Pepperoni Pizza Meatball Sub',
      asset: {
        uri: 'https://cdn.sanity.io/images/czqk28jt/prod_fhs_us/402cb5f1e48f8035f7842e30713b4bc4b0d91576-1340x783.png?w=1340&q=100&fit=max&auto=format',
        blurHash: '',
      },
    },
    basePrice: 899,
    descriptionShort:
      'Premium roast beef, smoked turkey breast, Virginia honey ham, and melted provolone, served fully involved (mayo, deli mustard, lettuce, tomato, onion, and a kosher dill pickle on the side)',
    description:
      'Premium roast beef, smoked turkey breast, Virginia honey ham, and melted provolone, served fully involved (mayo, deli mustard, lettuce, tomato, onion, and a kosher dill pickle on the side)',
  },
  'bottle-coke': {
    id: 'bottle-coke',
    slug: 'bottle-coke',
    displayName: 'Bottle Coca-Cola®',
    isAvailable: true,
    badge: 'none',
    url: 'REPLACE',
    image: {
      altText: 'Bottle Coca-Cola®',
      asset: {
        uri: 'https://cdn.sanity.io/images/czqk28jt/qa_fhs_us/eb0fdd1e38415d2f8c0c5d50af5a4a90d343757d-2000x1333.png?format=auto',
        blurHash: '',
      },
    },
    basePrice: 239,
    descriptionShort:
      'Enjoy a chilled, refreshing Coca-Cola in a classic glass bottle. Perfectly carbonated with a crisp, bold taste. Ideal for pairing with any meal or savoring on its own. A great companion for any occasion.',
    description:
      'Enjoy a chilled, refreshing Coca-Cola in a classic glass bottle. Perfectly carbonated with a crisp, bold taste. Ideal for pairing with any meal or savoring on its own. A great companion for any occasion.',
  },
};

export const getMenuItemData = (_slug: string) => {
  const found = menuItemData[_slug] ?? menuItemData['hook-and-ladder'];
  found.url = getUrlForItemDetailPageFromSlug(found.slug);
  return found;
};
