import { View } from 'react-native';

import { MqSwitch, XStack, createMqStyles } from '@fhs/ui';

import { SwitchToDelivery } from '../../components/switch-to-delivery';

import { PromoCodeButton } from './promo-code-button';

export function OffersDisclaimer() {
  const mqStyles = useMqStyles();

  return (
    <XStack style={mqStyles.container}>
      <MqSwitch $gteDesktop={() => <PromoCodeButton />} />
      <View style={{ flex: 1 }}>
        <SwitchToDelivery />
      </View>
    </XStack>
  );
}

const useMqStyles = createMqStyles({
  container: {
    $base: {
      gap: 16,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 16,
    },
    $gteDesktop: {
      marginTop: 24,
    },
  },
});
