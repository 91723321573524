{
  "possibleTypes": {
    "AccordionWidgetOrAnchorLinksWidgetOrAnchorWidgetOrCallToActionWidgetOrDoubleImageWidgetOrDownloadFileWidgetOrHeaderWidgetOrImageWidgetOrLanguageSelectorWidgetOrLocaleBlockTextWidgetOrMultiWidgetOrNutritionExplorerWidgetOrNutritionInfoWidgetOrPreviewWidgetOrQuoteWidgetOrRewardsCarouselWidgetOrVideoWidgetOrWebViewWidgetOrYoutubeWidget": [
      "AccordionWidget",
      "AnchorLinksWidget",
      "AnchorWidget",
      "CallToActionWidget",
      "DoubleImageWidget",
      "DownloadFileWidget",
      "HeaderWidget",
      "ImageWidget",
      "LanguageSelectorWidget",
      "LocaleBlockTextWidget",
      "MultiWidget",
      "NutritionExplorerWidget",
      "NutritionInfoWidget",
      "PreviewWidget",
      "QuoteWidget",
      "RewardsCarouselWidget",
      "VideoWidget",
      "WebViewWidget",
      "YoutubeWidget"
    ],
    "AlertOrAppDownloadOrBrazeContentCardsOrFanFavoritesOrGoogleAdSlotOrHeroOrLockedOffersOrMainHeroOrMarketingCardGroupOrMarketingTileCarouselOrMarketingTileGroupOrOffersSectionOrOurServicesOrQuestsSectionOrRecentItemsOrRewardsSectionOrRollUpTheRimSectionOrScanForRewardsOrSimpleHomePageOrTimsRewardsPointsSectionOrTrending": [
      "Alert",
      "AppDownload",
      "BrazeContentCards",
      "FanFavorites",
      "GoogleAdSlot",
      "Hero",
      "LockedOffers",
      "MainHero",
      "MarketingCardGroup",
      "MarketingTileCarousel",
      "MarketingTileGroup",
      "OffersSection",
      "OurServices",
      "QuestsSection",
      "RecentItems",
      "RewardsSection",
      "RollUpTheRimSection",
      "ScanForRewards",
      "SimpleHomePage",
      "TimsRewardsPointsSection",
      "Trending"
    ],
    "AlertOrAppDownloadOrBrazeContentCardsOrFanFavoritesOrLockedOffersOrMainHeroOrMarketingTileGroupOrOffersSectionOrOurServicesOrRecentItemsOrTrending": [
      "Alert",
      "AppDownload",
      "BrazeContentCards",
      "FanFavorites",
      "LockedOffers",
      "MainHero",
      "MarketingTileGroup",
      "OffersSection",
      "OurServices",
      "RecentItems",
      "Trending"
    ],
    "AllowRedemptionWithoutLoginOrAndOrBetweenDatesOrBetweenTimesOrCartContainsPluOrCartPropertyOrCoolDownOrDayOfWeekBandOrFirstOrderOnlyOrLimitOrOrOrOrderHistoryOrPaymentCardTypeOrPurchaseOrRequiresAssignmentOrRestaurantGroupRestrictions": [
      "AllowRedemptionWithoutLogin",
      "And",
      "BetweenDates",
      "BetweenTimes",
      "CartContainsPlu",
      "CartProperty",
      "CoolDown",
      "DayOfWeekBand",
      "FirstOrderOnly",
      "Limit",
      "Or",
      "OrderHistory",
      "PaymentCardType",
      "Purchase",
      "RequiresAssignment",
      "RestaurantGroupRestrictions"
    ],
    "AllowRedemptionWithoutLoginOrBetweenDatesOrBetweenTimesOrCartContainsPluOrCartPropertyOrCartRequirementOrCoolDownOrDayOfWeekBandOrFirstOrderOnlyOrIsStackableOrLimitOrPaymentCardTypeOrPurchaseOrRequiresAssignmentOrRestaurantGroupRestrictions": [
      "AllowRedemptionWithoutLogin",
      "BetweenDates",
      "BetweenTimes",
      "CartContainsPlu",
      "CartProperty",
      "CartRequirement",
      "CoolDown",
      "DayOfWeekBand",
      "FirstOrderOnly",
      "IsStackable",
      "Limit",
      "PaymentCardType",
      "Purchase",
      "RequiresAssignment",
      "RestaurantGroupRestrictions"
    ],
    "AllowRedemptionWithoutLoginOrBetweenDatesOrBetweenTimesOrCartContainsPluOrCartPropertyOrCartRequirementOrCoolDownOrDayOfWeekBandOrFirstOrderOnlyOrLimitOrPaymentCardTypeOrPurchaseOrRequiresAssignmentOrRestaurantGroupRestrictions": [
      "AllowRedemptionWithoutLogin",
      "BetweenDates",
      "BetweenTimes",
      "CartContainsPlu",
      "CartProperty",
      "CartRequirement",
      "CoolDown",
      "DayOfWeekBand",
      "FirstOrderOnly",
      "Limit",
      "PaymentCardType",
      "Purchase",
      "RequiresAssignment",
      "RestaurantGroupRestrictions"
    ],
    "AndOrBetweenTimesOrCartContainsPluOrCartPropertyOrDayOfWeekBandOrOrOrPaymentCardTypeOrPurchase": [
      "And",
      "BetweenTimes",
      "CartContainsPlu",
      "CartProperty",
      "DayOfWeekBand",
      "Or",
      "PaymentCardType",
      "Purchase"
    ],
    "BirthdayMultiplierOrCartLimitOrCartRequirementOrCartRequirementExclusionOrCoolDownOrDayOfWeekBandOrEmailVerifiedRequiredOrExpireAfterOrFirstOrderOnlyOrIncentiveRequirementOrLimitOrLoyaltyBetweenDatesOrLoyaltyBetweenTimesOrLoyaltyPointsOrPaymentMethodOrRequiresAuthenticationOrRestaurantGroupRestrictionsOrServiceModeRestrictionsOrSubtotalSpendOrTransactionCountOrUserAttributesOrUserCreated": [
      "BirthdayMultiplier",
      "CartLimit",
      "CartRequirement",
      "CartRequirementExclusion",
      "CoolDown",
      "DayOfWeekBand",
      "EmailVerifiedRequired",
      "ExpireAfter",
      "FirstOrderOnly",
      "IncentiveRequirement",
      "Limit",
      "LoyaltyBetweenDates",
      "LoyaltyBetweenTimes",
      "LoyaltyPoints",
      "PaymentMethod",
      "RequiresAuthentication",
      "RestaurantGroupRestrictions",
      "ServiceModeRestrictions",
      "SubtotalSpend",
      "TransactionCount",
      "UserAttributes",
      "UserCreated"
    ],
    "BirthdayMultiplierOrCartLimitOrCartRequirementOrCartRequirementExclusionOrCoolDownOrDayOfWeekBandOrEmailVerifiedRequiredOrExpireAfterOrFirstOrderOnlyOrIsStackableOrLimitOrLoyaltyBetweenDatesOrLoyaltyBetweenTimesOrPaymentMethodOrRequiresAuthenticationOrRestaurantGroupRestrictionsOrServiceModeRestrictionsOrSubtotalSpendOrTransactionCountOrUserAttributesOrUserCreated": [
      "BirthdayMultiplier",
      "CartLimit",
      "CartRequirement",
      "CartRequirementExclusion",
      "CoolDown",
      "DayOfWeekBand",
      "EmailVerifiedRequired",
      "ExpireAfter",
      "FirstOrderOnly",
      "IsStackable",
      "Limit",
      "LoyaltyBetweenDates",
      "LoyaltyBetweenTimes",
      "PaymentMethod",
      "RequiresAuthentication",
      "RestaurantGroupRestrictions",
      "ServiceModeRestrictions",
      "SubtotalSpend",
      "TransactionCount",
      "UserAttributes",
      "UserCreated"
    ],
    "BirthdayMultiplierOrCartLimitOrCartRequirementOrCartRequirementExclusionOrCoolDownOrDayOfWeekBandOrEmailVerifiedRequiredOrFirstOrderOnlyOrIncentiveRequirementOrLimitOrLoyaltyBetweenDatesOrLoyaltyBetweenTimesOrPaymentMethodOrRequiresAuthenticationOrRestaurantGroupRestrictionsOrServiceModeRestrictionsOrSubtotalSpendOrTransactionCountOrUserAttributesOrUserCreated": [
      "BirthdayMultiplier",
      "CartLimit",
      "CartRequirement",
      "CartRequirementExclusion",
      "CoolDown",
      "DayOfWeekBand",
      "EmailVerifiedRequired",
      "FirstOrderOnly",
      "IncentiveRequirement",
      "Limit",
      "LoyaltyBetweenDates",
      "LoyaltyBetweenTimes",
      "PaymentMethod",
      "RequiresAuthentication",
      "RestaurantGroupRestrictions",
      "ServiceModeRestrictions",
      "SubtotalSpend",
      "TransactionCount",
      "UserAttributes",
      "UserCreated"
    ],
    "BirthdayMultiplierOrCartLimitOrCartRequirementOrCartRequirementExclusionOrCoolDownOrDayOfWeekBandOrEmailVerifiedRequiredOrFirstOrderOnlyOrIsStackableOrLimitOrLoyaltyBetweenDatesOrLoyaltyBetweenTimesOrPaymentMethodOrRequiresAuthenticationOrRestaurantGroupRestrictionsOrServiceModeRestrictionsOrSubtotalSpendOrTransactionCountOrUserAttributesOrUserCreated": [
      "BirthdayMultiplier",
      "CartLimit",
      "CartRequirement",
      "CartRequirementExclusion",
      "CoolDown",
      "DayOfWeekBand",
      "EmailVerifiedRequired",
      "FirstOrderOnly",
      "IsStackable",
      "Limit",
      "LoyaltyBetweenDates",
      "LoyaltyBetweenTimes",
      "PaymentMethod",
      "RequiresAuthentication",
      "RestaurantGroupRestrictions",
      "ServiceModeRestrictions",
      "SubtotalSpend",
      "TransactionCount",
      "UserAttributes",
      "UserCreated"
    ],
    "BirthdayMultiplierOrCartLimitOrCartRequirementOrCartRequirementExclusionOrCoolDownOrDayOfWeekBandOrEmailVerifiedRequiredOrFirstOrderOnlyOrLimitOrLoyaltyBetweenDatesOrLoyaltyBetweenTimesOrLoyaltyPointsOrPaymentMethodOrRequiresAuthenticationOrRestaurantGroupRestrictionsOrServiceModeRestrictionsOrSubtotalSpendOrTransactionCountOrUserAttributesOrUserCreated": [
      "BirthdayMultiplier",
      "CartLimit",
      "CartRequirement",
      "CartRequirementExclusion",
      "CoolDown",
      "DayOfWeekBand",
      "EmailVerifiedRequired",
      "FirstOrderOnly",
      "Limit",
      "LoyaltyBetweenDates",
      "LoyaltyBetweenTimes",
      "LoyaltyPoints",
      "PaymentMethod",
      "RequiresAuthentication",
      "RestaurantGroupRestrictions",
      "ServiceModeRestrictions",
      "SubtotalSpend",
      "TransactionCount",
      "UserAttributes",
      "UserCreated"
    ],
    "BlockOrBlockContentImage": [
      "Block",
      "BlockContentImage"
    ],
    "BlockOrBlockContentImageOrPlaceholderField": [
      "Block",
      "BlockContentImage",
      "PlaceholderField"
    ],
    "CartRequirementOrCartRequirementExclusionOrDayOfWeekBandOrEmailVerifiedRequiredOrExpireAfterOrIncentiveRequirementOrLoyaltyBetweenDatesOrLoyaltyBetweenTimesOrRequiresAuthenticationOrServiceModeRestrictionsOrSubtotalSpend": [
      "CartRequirement",
      "CartRequirementExclusion",
      "DayOfWeekBand",
      "EmailVerifiedRequired",
      "ExpireAfter",
      "IncentiveRequirement",
      "LoyaltyBetweenDates",
      "LoyaltyBetweenTimes",
      "RequiresAuthentication",
      "ServiceModeRestrictions",
      "SubtotalSpend"
    ],
    "ComboOrConfigOfferOrItemOrPickerOrSystemwideOffer": [
      "Combo",
      "ConfigOffer",
      "Item",
      "Picker",
      "SystemwideOffer"
    ],
    "ComboOrExternalLinkOrItemOrOfferOrPickerOrSection": [
      "Combo",
      "ExternalLink",
      "Item",
      "Offer",
      "Picker",
      "Section"
    ],
    "ComboOrItem": [
      "Combo",
      "Item"
    ],
    "ComboOrItemOrItemGroup": [
      "Combo",
      "Item",
      "ItemGroup"
    ],
    "ComboOrItemOrItemGroupDiscountOrOfferActivationOrOfferDiscountOrPickerOrSwapMapping": [
      "Combo",
      "Item",
      "ItemGroupDiscount",
      "OfferActivation",
      "OfferDiscount",
      "Picker",
      "SwapMapping"
    ],
    "ComboOrItemOrOfferActivationOrOfferDiscountOrOfferItemDiscountOrOfferLoyaltyActionOrPicker": [
      "Combo",
      "Item",
      "OfferActivation",
      "OfferDiscount",
      "OfferItemDiscount",
      "OfferLoyaltyAction",
      "Picker"
    ],
    "ComboOrItemOrOfferActivationOrOfferDiscountOrOfferLoyaltyActionOrPicker": [
      "Combo",
      "Item",
      "OfferActivation",
      "OfferDiscount",
      "OfferLoyaltyAction",
      "Picker"
    ],
    "ComboOrItemOrOfferDiscountOrPicker": [
      "Combo",
      "Item",
      "OfferDiscount",
      "Picker"
    ],
    "ComboOrItemOrOfferOrPickerOrSection": [
      "Combo",
      "Item",
      "Offer",
      "Picker",
      "Section"
    ],
    "ComboOrItemOrPicker": [
      "Combo",
      "Item",
      "Picker"
    ],
    "ComboOrItemOrPickerOrProductCategory": [
      "Combo",
      "Item",
      "Picker",
      "ProductCategory"
    ],
    "ComboOrItemOrPickerOrSection": [
      "Combo",
      "Item",
      "Picker",
      "Section"
    ],
    "ConditionAllergenOrConditionItemOneOfOrConditionNutritionOrConditionParentCategory": [
      "ConditionAllergen",
      "ConditionItemOneOf",
      "ConditionNutrition",
      "ConditionParentCategory"
    ],
    "ConfigOfferOrConfigRewardOrRewardOrSystemwideOffer": [
      "ConfigOffer",
      "ConfigReward",
      "Reward",
      "SystemwideOffer"
    ],
    "CopyrightAndLanguageSelectorOrHighlightedVerticalLinkListWidgetOrHorizontalLinkListWidgetOrImageWidgetOrSocialIconsWithHeaderWidgetOrTextWidgetOrTextWidgetWithUrlOrVerticalLinkListWidgetOrVerticalLinkListWithHeaderWidgetOrWordmarkAndSocialIcons": [
      "CopyrightAndLanguageSelector",
      "HighlightedVerticalLinkListWidget",
      "HorizontalLinkListWidget",
      "ImageWidget",
      "SocialIconsWithHeaderWidget",
      "TextWidget",
      "TextWidgetWithUrl",
      "VerticalLinkListWidget",
      "VerticalLinkListWithHeaderWidget",
      "WordmarkAndSocialIcons"
    ],
    "Document": [
      "AdaptableMenuCard",
      "AdaptableMenuCardGroup",
      "AddOnSection",
      "AdminToolReleaseNotes",
      "Alert",
      "AppData",
      "AppDownload",
      "AppVersion",
      "Barcodes",
      "BrazeContentCards",
      "BuildConfiguration",
      "CartTotalCountdown",
      "Category",
      "CateringPageConfiguration",
      "ClaimPointsUI",
      "Combo",
      "ComboSlot",
      "ConfigOffer",
      "ConfigOfferV2",
      "ConfigQuest",
      "ConfigReward",
      "ConfigRewardV2",
      "DisplayGroup",
      "EarningCalculation",
      "EmptyState",
      "FanFavorites",
      "FeatureAccount",
      "FeatureAccountDeletePage",
      "FeatureAccountForm",
      "FeatureAccountRequestInfoPage",
      "FeatureAuthCampaign",
      "FeatureBeeperModal",
      "FeatureCateringPage",
      "FeatureDisclaimers",
      "FeatureDonation",
      "FeatureFoodware",
      "FeatureFooter",
      "FeatureFooterV2",
      "FeatureGeolocationMapMarkers",
      "FeatureGeolocationModal",
      "FeatureGift",
      "FeatureHomePage",
      "FeatureInvitationCode",
      "FeatureKiosk",
      "FeatureKioskHomePage",
      "FeatureKioskMenu",
      "FeatureKioskOffers",
      "FeatureKioskScreensaver",
      "FeatureLayout",
      "FeatureLocationPromo",
      "FeatureLockers",
      "FeatureMenu",
      "FeatureMobileAppConfiguration",
      "FeatureNavigation",
      "FeatureNutrition",
      "FeatureOffers",
      "FeatureOnboarding",
      "FeaturePayback",
      "FeatureQuiz",
      "FeatureScanAndPay",
      "FeatureSimplifiedLandingPage",
      "FeatureStaticMenu",
      "Footer",
      "FrontEndConfiguration",
      "GoogleAdSlot",
      "Hero",
      "HomePageConfiguration",
      "ImageWithHeaderAndText",
      "Item",
      "ItemGroup",
      "ItemGroupDiscount",
      "KioskAppData",
      "KioskConfiguration",
      "LinkPhysicalCardUi",
      "LockedOffers",
      "LoyaltyConfiguration",
      "LoyaltyContent",
      "LoyaltyOffersUI",
      "LoyaltyQuestsUI",
      "LoyaltyReceipts",
      "LoyaltyUI",
      "MainHero",
      "MarkerItem",
      "MarketingCard",
      "MarketingCardGroup",
      "MarketingTile",
      "MarketingTileBasic",
      "MarketingTileCarousel",
      "MarketingTileGroup",
      "MediaTag",
      "Menu",
      "Modifier",
      "ModifierMultiplier",
      "NavigationSection",
      "NutritionalSection",
      "Offer",
      "OfferActivation",
      "OfferCampaign",
      "OfferCategorization",
      "OfferDiscount",
      "OfferItemDiscount",
      "OfferLoyaltyAction",
      "OfferTemplate",
      "OffersSection",
      "OpPricingRegion",
      "OurServices",
      "Picker",
      "PickerAspect",
      "PlaceholderField",
      "PosConfiguration",
      "ProductCategory",
      "QuestTemplate",
      "QuestionDetails",
      "QuestsSection",
      "RecentItems",
      "RemediationReasons",
      "ResponseDetails",
      "Restaurant",
      "RestaurantAmenity",
      "RestaurantDeliveryProvider",
      "RestaurantGroup",
      "RestaurantKioskData",
      "RestaurantPosData",
      "ResultDetails",
      "Reward",
      "RewardCategory",
      "RewardList",
      "RewardV2",
      "RewardsSection",
      "RollUpTheRim",
      "RollUpTheRimSection",
      "SanityFileAsset",
      "SanityImageAsset",
      "SanityReleaseNotes",
      "SanityValidationConfiguration",
      "ScanForRewards",
      "Section",
      "SimpleHomePage",
      "StaticPage",
      "SupportCategoryGroup",
      "SupportData",
      "SupportFaq",
      "SupportSubcategory",
      "SwapMapping",
      "SystemwideOffer",
      "SystemwideOfferV2",
      "SystemwidePromotion",
      "SystemwideQuest",
      "Tag",
      "Tier",
      "TimsRewardsPointsSection",
      "Trending"
    ],
    "EmailVerifiedRequiredOrLimitOrLoyaltyBetweenDatesOrRequiresAuthenticationOrUserCreated": [
      "EmailVerifiedRequired",
      "Limit",
      "LoyaltyBetweenDates",
      "RequiresAuthentication",
      "UserCreated"
    ],
    "FeaturedRewardsCarouselWidgetOrLoyaltyDoubleButtonWidgetOrLoyaltyGreetingWidgetOrLoyaltyHistoryWidgetOrLoyaltyLinkWidgetOrLoyaltyLogoWidgetOrLoyaltyMarketingTileGroupWidgetOrLoyaltyNotificationsWidgetOrLoyaltyPointsAndRewardsWidgetOrLoyaltyPointsMeterWidgetOrLoyaltyQuestsTileWidgetOrLoyaltyQuestsWidgetOrLoyaltyTiersWidgetOrRewardsListWidget": [
      "FeaturedRewardsCarouselWidget",
      "LoyaltyDoubleButtonWidget",
      "LoyaltyGreetingWidget",
      "LoyaltyHistoryWidget",
      "LoyaltyLinkWidget",
      "LoyaltyLogoWidget",
      "LoyaltyMarketingTileGroupWidget",
      "LoyaltyNotificationsWidget",
      "LoyaltyPointsAndRewardsWidget",
      "LoyaltyPointsMeterWidget",
      "LoyaltyQuestsTileWidget",
      "LoyaltyQuestsWidget",
      "LoyaltyTiersWidget",
      "RewardsListWidget"
    ],
    "ItemOrItemGroup": [
      "Item",
      "ItemGroup"
    ],
    "ItemOrNutritionalSection": [
      "Item",
      "NutritionalSection"
    ],
    "ItemOrPickerOrSection": [
      "Item",
      "Picker",
      "Section"
    ],
    "LoyaltyBasicCardWidgetOrLoyaltyButtonArrayWidgetOrLoyaltyButtonWidgetOrLoyaltyCartWidgetOrLoyaltyImageWidgetOrLoyaltyLegacyShortCodeWidgetOrLoyaltyMarketingTileGroupWidgetOrLoyaltyPaymentMethodWidgetOrLoyaltyQRAndShortCodeWidgetOrLoyaltyQRCodeLoyaltyIdWidgetOrLoyaltyQRCodeWidgetOrLoyaltyQRScanButtonWidgetOrLoyaltyQRScanHeaderWidgetOrLoyaltyShortCodeWidgetOrLoyaltyTextWidgetOrLoyaltyTitleWidget": [
      "LoyaltyBasicCardWidget",
      "LoyaltyButtonArrayWidget",
      "LoyaltyButtonWidget",
      "LoyaltyCartWidget",
      "LoyaltyImageWidget",
      "LoyaltyLegacyShortCodeWidget",
      "LoyaltyMarketingTileGroupWidget",
      "LoyaltyPaymentMethodWidget",
      "LoyaltyQRAndShortCodeWidget",
      "LoyaltyQRCodeLoyaltyIdWidget",
      "LoyaltyQRCodeWidget",
      "LoyaltyQRScanButtonWidget",
      "LoyaltyQRScanHeaderWidget",
      "LoyaltyShortCodeWidget",
      "LoyaltyTextWidget",
      "LoyaltyTitleWidget"
    ],
    "MarketingTileOrMarketingTileBasic": [
      "MarketingTile",
      "MarketingTileBasic"
    ],
    "MarketingTileOrMarketingTileBasicOrMarketingTileListOrMarketingTilePairOrMarketingTileQuad": [
      "MarketingTile",
      "MarketingTileBasic",
      "MarketingTileList",
      "MarketingTilePair",
      "MarketingTileQuad"
    ],
    "NavigationSectionOrStaticPage": [
      "NavigationSection",
      "StaticPage"
    ],
    "NutritionItemOrNutritionItemGroup": [
      "NutritionItem",
      "NutritionItemGroup"
    ],
    "OptInPrivacyPreferencesOrOptInSettingBooleanOrOptInSettingEmailOrOptInSettingEmailAndPushOrOptInSettingEnumOrOptInSettingPush": [
      "OptInPrivacyPreferences",
      "OptInSettingBoolean",
      "OptInSettingEmail",
      "OptInSettingEmailAndPush",
      "OptInSettingEnum",
      "OptInSettingPush"
    ],
    "OptInSettingBooleanOrOptInSettingEmailAndPushOrOptInSettingEnum": [
      "OptInSettingBoolean",
      "OptInSettingEmailAndPush",
      "OptInSettingEnum"
    ],
    "PickerOrSection": [
      "Picker",
      "Section"
    ],
    "RewardOrSystemwideOfferOrSystemwidePromotion": [
      "Reward",
      "SystemwideOffer",
      "SystemwidePromotion"
    ]
  }
}