import { ImageBackground, ImageBackgroundProps } from 'expo-image';
import { Link } from 'expo-router';
import { View } from 'react-native';

import { Button, Text, YStack, createMqStyles, tokens, useMqSelect } from '@fhs/ui';
import { isExternalLink } from '@fhs-legacy/frontend/src/utils/is-external-link';

export type CateringCardProps = Omit<ImageBackgroundProps, 'children' | 'source'> & {
  cateringHref: string;
};
export function CateringCard({ cateringHref, ...props }: CateringCardProps) {
  const styles = useStyles();

  return (
    <ImageBackground
      source={require('./catering-card-background.webp')}
      placeholder={{ thumbhash: 'WZkCC4g9XIuESgqdf1vxqZY' }}
      {...props}
      style={[styles.cateringCard, props.style]}
    >
      <View style={styles.contentContainer}>
        <YStack style={styles.contentStack}>
          <Text weight="bold" style={styles.heading}>
            We Offer Catering!
          </Text>
          <Text style={styles.copy}>
            From small bites to big feasts - our catering has got you covered. Orders must be placed
            at least one day in advance.
          </Text>
          <Link
            href={cateringHref}
            asChild
            {...(isExternalLink(cateringHref) ? { target: '_blank' } : {})}
          >
            <Button type="solid" size={useMqSelect({ $gteDesktopLg: 'xl' }, 'lg')}>
              <Button.Text>Take Me to Catering</Button.Text>
            </Button>
          </Link>
        </YStack>
      </View>
    </ImageBackground>
  );
}

const useStyles = createMqStyles({
  cateringCard: {
    $base: {
      aspectRatio: 2771 / 1270,
    },
  },
  contentContainer: {
    $base: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,

      justifyContent: 'center',
      alignItems: 'center',
      gap: 24,
    },
    $ltDesktopLg: {
      paddingHorizontal: 40,
      right: '50%',
    },
    $gteDesktopLg: {
      paddingHorizontal: 80,
      right: '50%',
    },
  },
  contentStack: {
    $base: {
      borderRadius: 8,
      backgroundColor: tokens.colors.$whiteOpacity90,
      padding: 32,
      gap: 24,
      alignItems: 'center',
    },
  },
  heading: {
    $base: {
      color: tokens.colors.$houseRedDarken,
      textAlign: 'center',
      fontSize: 48,
      lineHeight: 52,
    },
  },
  copy: {
    $base: {
      textAlign: 'center',
      color: tokens.colors.$houseDark,
      fontSize: 20,
      lineHeight: 28,
    },
  },
});
