import { StyleSheet, View } from 'react-native';

import { tokens } from '../../tokens';

import { useRadioItem } from './context';
import { RadioPressable, type RadioPressableProps } from './radio-pressable';

export type RadioControlProps = RadioPressableProps;

export const RadioControl = (props: RadioControlProps) => {
  const radioItem = useRadioItem();

  return (
    <RadioPressable
      borderRadius={100}
      {...props}
      style={[styles.outerCircle, props.style]}
      hoveredStyle={[styles.outerCircleHover, props.hoveredStyle]}
      disabledStyle={[
        radioItem.selected && styles.outerCircleDisabledAndSelected,
        props.disabledStyle,
      ]}
    >
      <View
        style={[
          styles.innerCircle,
          radioItem.selected && styles.innerCircleSelected,
          radioItem.disabled && styles.disabled,
          radioItem.selected && radioItem.disabled && styles.disabledSelected,
        ]}
      />
    </RadioPressable>
  );
};

const styles = StyleSheet.create({
  outerCircle: {
    height: 24,
    width: 24,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  outerCircleHover: {
    borderColor: tokens.colors.$houseDark,
  },
  outerCircleDisabledAndSelected: {
    borderColor: tokens.colors.$transparent,
  },
  innerCircle: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 100,
    borderColor: tokens.colors.$white,
    borderWidth: 6,
    backgroundColor: tokens.colors.$transparent,
  },
  innerCircleSelected: {
    borderColor: tokens.colors.$houseRedDarken,
  },
  disabledSelected: {
    borderColor: tokens.colors.$houseRed10,
  },
  disabled: {
    borderColor: tokens.colors.$white,
  },
});
