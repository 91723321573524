import { StyleSheet } from 'react-native';

import { RapidRescue, YStack, tokens } from '@fhs/ui';

export const NoOrderDisplay = () => {
  return (
    <YStack style={styles.container} testID="home-rapid-rescue">
      <RapidRescue height="100%" width="50%" />
    </YStack>
  );
};

const styles = StyleSheet.create({
  gap16: {
    gap: 16,
  },
  wrapper: {
    padding: 16,
    flex: 1,
  },
  container: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    backgroundColor: tokens.colors.$white,
    gap: 12,
    padding: 16,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
