import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconDollar = (props: IconProps) => {
  const { size = 16, color, ...otherProps } = props;

  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color ?? 'black'}
      {...otherProps}
    >
      <Path
        d="M11.275 19.175H12.65V17.875C13.6667 17.7583 14.4583 17.4458 15.025 16.9375C15.5917 16.4292 15.875 15.75 15.875 14.9C15.875 14.05 15.6333 13.3583 15.15 12.825C14.6667 12.2917 13.85 11.7833 12.7 11.3C11.7333 10.9 11.0333 10.5417 10.6 10.225C10.1667 9.90833 9.95 9.48333 9.95 8.95C9.95 8.43333 10.1375 8.025 10.5125 7.725C10.8875 7.425 11.4 7.275 12.05 7.275C12.55 7.275 12.9833 7.39167 13.35 7.625C13.7167 7.85833 14.025 8.20833 14.275 8.675L15.475 8.1C15.1917 7.51667 14.8167 7.05833 14.35 6.725C13.8833 6.39167 13.3333 6.19167 12.7 6.125V4.85H11.325V6.125C10.475 6.24167 9.80417 6.55417 9.3125 7.0625C8.82083 7.57083 8.575 8.2 8.575 8.95C8.575 9.76667 8.825 10.4167 9.325 10.9C9.825 11.3833 10.575 11.8333 11.575 12.25C12.6917 12.7167 13.4583 13.1375 13.875 13.5125C14.2917 13.8875 14.5 14.35 14.5 14.9C14.5 15.4333 14.2792 15.8625 13.8375 16.1875C13.3958 16.5125 12.8417 16.675 12.175 16.675C11.525 16.675 10.9458 16.4917 10.4375 16.125C9.92917 15.7583 9.575 15.2583 9.375 14.625L8.1 15.05C8.45 15.8167 8.87917 16.4208 9.3875 16.8625C9.89583 17.3042 10.525 17.625 11.275 17.825V19.175ZM12 22C10.6333 22 9.34167 21.7375 8.125 21.2125C6.90833 20.6875 5.84583 19.9708 4.9375 19.0625C4.02917 18.1542 3.3125 17.0917 2.7875 15.875C2.2625 14.6583 2 13.3667 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.02917 5.825 4.9375 4.925C5.84583 4.025 6.90833 3.3125 8.125 2.7875C9.34167 2.2625 10.6333 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3667 21.7375 14.6583 21.2125 15.875C20.6875 17.0917 19.975 18.1542 19.075 19.0625C18.175 19.9708 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z"
        fill="black"
        fill-opacity="0.3"
      />
    </Svg>
  );
};
