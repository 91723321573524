import { router } from 'expo-router';
import Head from 'expo-router/head';
import React, { memo, useEffect, useMemo, useState } from 'react';

import { Box } from '@fhs-legacy/universal-components';
import { MenuObject } from '@rbi-ctg/menu';
import { ScreenScrollView } from 'components/layout/screen-scroll-view';
import { MenuListLoading } from 'components/menu-list-loading';
import MenuOrderWizard from 'components/menu-order-wizard';
import { MenuTile } from 'components/menu-tile-grid/menu-tile';
import { MainMenuObject } from 'components/menu-tile-grid/menu-tile/types';
import useMenuByDayPart, { IMenuByDayPartProps } from 'hooks/use-menu-by-day-part';
import { SHOULD_CHECK_ITEM_IN_OFFER } from 'pages/main-menu/constants';
import NotFound from 'pages/not-found';
import { ProductWizard } from 'pages/product-wizard';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { IMainMenuContext, IMenuObjectState } from 'state/main-menu';
import { useMenuContext } from 'state/menu';
import { ProductMenuObject } from 'state/product-wizard/types';
import logger from 'utils/logger';
import { isFavoritesRoute, isRecentItemsRoute, routes } from 'utils/routing';

import MenuItemUnavailable from './item-unavailable';
import { MenuObjectSkeleton } from './menu-object-skeleton';
import { IMenuContentProps } from './types';
import { useHandleMenuItemInOffer } from './use-handle-menu-item-in-offer';
import { useLogMenuContent } from './use-log-menu-content';

const RenderWizard = memo((props: { data: MenuObject; loading: boolean }) => (
  <MenuOrderWizard {...props} />
));

type InnerContentProps = {
  menuObject: IMenuObjectState;
  isOffer: boolean | null;
  showStaticMenu: boolean;
  shouldBeTreatedAsSection?: boolean;
};
const InnerContent = ({
  menuObject,
  showStaticMenu,
  shouldBeTreatedAsSection,
  isOffer,
}: InnerContentProps) => {
  const enableNewMenuCustomizationDesign = useFlag(
    LaunchDarklyFlag.ENABLE_NEW_MENU_CUSTOMIZATION_DESIGN
  );

  if (!menuObject.data) {
    return (
      <NotFound>
        <MenuItemUnavailable isOffer={isOffer} />
      </NotFound>
    );
  }

  // In React Navigation, the top tabs are treated as menu sections
  // This optional condition accounts for cases where a tab is not a section, but rather a product
  // So we'll first show that product in a tile when navigating between tabs
  if (shouldBeTreatedAsSection) {
    return (
      <MenuTile
        showStaticMenu={showStaticMenu}
        objectFitContain={true}
        item={menuObject.data as MainMenuObject}
      />
    );
  }

  // will eventually decouple from menu content and use layout to route to its page
  if (enableNewMenuCustomizationDesign) {
    return <ProductWizard product={menuObject.data as ProductMenuObject} />;
  }

  return <RenderWizard data={menuObject.data} loading={menuObject.loading} />;
};

function curriedTryGetMenuObject(
  getMenuObject: IMainMenuContext['getMenuObject'],
  setMenuItemNotFound: React.Dispatch<boolean>
): (id: string) => IMenuObjectState {
  return (id: string) => {
    try {
      return getMenuObject(id);
    } catch (err) {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      if (err.notFoundError) {
        setMenuItemNotFound(true);

        logger.warn({ error: err, menuObjectId: id });

        return {
          loading: false,
          data: null,
        };
      }

      // not sure we should throw here...
      throw err;
    }
  };
}

export const MenuObjectContent = ({ id: routeId, shouldBeTreatedAsSection }: IMenuContentProps) => {
  const { getMenuObject, isOffer, setIsOffer, showStaticMenu } = useMenuContext();

  // Preserving the route id in case a modal route is opened above this screen
  const [id] = useState(routeId);

  const [menuItemNotFound, setMenuItemNotFound] = useState(false);
  const tryGetMenuObject = useMemo(
    () => curriedTryGetMenuObject(getMenuObject, setMenuItemNotFound),
    [getMenuObject]
  );

  // if its recent items or favorites don't get menu data
  const menuObjectData =
    isRecentItemsRoute(`/menu/${id}`) || isFavoritesRoute(`/menu/${id}`)
      ? { loading: false, data: null }
      : (tryGetMenuObject(id) as IMenuByDayPartProps); // There is a type mismatch here, this need to be fixed in a future refactor;
  const menuObject = useMenuByDayPart(menuObjectData) as IMenuObjectState;

  const handleMenuItemInOffer = useHandleMenuItemInOffer(setIsOffer);
  useEffect(() => {
    if (!!menuObject.data && SHOULD_CHECK_ITEM_IN_OFFER) {
      handleMenuItemInOffer(menuObject.data);
    }
  }, [handleMenuItemInOffer, menuObject]);

  useLogMenuContent({
    loggedValues: {
      id: menuObject?.data?._id || '',
      name: menuObject?.data?.name?.locale || '',
      menuType: menuObject?.data?._type || '',
    },
    pathname: `/menu/${id}`,
  });

  if (menuItemNotFound) {
    router.replace(routes.notFound404);
  }

  if (menuObject.loading && shouldBeTreatedAsSection) {
    return <MenuListLoading />;
  }

  return (
    <Box flex="1">
      <Head>
        <title>
          {menuObject.data?.name?.locale
            ? `${menuObject.data?.name?.locale} - Firehouse Subs`
            : 'Firehouse Subs'}
        </title>
      </Head>
      {menuObject.loading && !shouldBeTreatedAsSection ? (
        <ScreenScrollView>
          <MenuObjectSkeleton />
        </ScreenScrollView>
      ) : (
        <InnerContent
          menuObject={menuObject}
          isOffer={isOffer}
          showStaticMenu={showStaticMenu}
          shouldBeTreatedAsSection={shouldBeTreatedAsSection}
        />
      )}
    </Box>
  );
};
