import { omit } from 'lodash';
import { StyleSheet, View } from 'react-native';

import { tokens } from '../../tokens';
import { HeaderText } from '../header/header-text';
import { Pressable, PressableProps } from '../pressable';
import { XStack, YStack } from '../stack';
import { Text } from '../text/text';

import { IconByType } from './constants';
import type { ServiceModeLocationProps } from './types';

export function DesktopHeaderServiceModeLocation(props: ServiceModeLocationProps) {
  const pressableProps = omit(props, [
    'type',
    'storeText',
    'serviceModeSubtitle',
  ]) satisfies PressableProps;
  const Icon = IconByType[props.type];

  return (
    <Pressable {...pressableProps} style={[styles.pressable, pressableProps.style]}>
      <View style={styles.iconView}>
        <Icon size={20} />
      </View>
      <YStack>
        {props.storeText ? (
          <XStack>
            <HeaderText numberOfLines={1} ellipsizeMode="tail" style={styles.headerText}>
              {props.storeText}
            </HeaderText>
          </XStack>
        ) : (
          <HeaderText>Locations</HeaderText>
        )}

        {props.serviceModeSubtitle && (
          <Text numberOfLines={1} ellipsizeMode="tail" style={styles.bodyText}>
            {props.serviceModeSubtitle}
          </Text>
        )}
      </YStack>
      <View style={styles.changeTextView}>
        <Text style={styles.changeText}>(change)</Text>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  pressable: {
    alignItems: 'center',
    gap: 8,
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 1,
  },
  iconView: {
    flexShrink: 0,
  },
  changeTextView: {
    marginLeft: 4,
    flexShrink: 0,
  },
  headerText: {
    maxWidth: 150,
  },
  bodyText: {
    fontSize: 12,
    lineHeight: 16,
    color: tokens.colors.$disabledText,
  },
  changeText: {
    textDecorationLine: 'underline',
    fontSize: 12,
    lineHeight: 20,
  },
});
