import { useMemo } from 'react';

import { client, useQuery } from '@fhs/client';

import { useLoyaltyLegacyStates } from '../hooks/use-loyalty-legacy-states';

export const loyaltyIncentivesQueryKeys = {
  all: ['loyaltyIncentives'] as const,
  params: ({
    storeId,
    serviceMode,
    loyaltyId,
  }: {
    storeId: string;
    serviceMode: string;
    loyaltyId?: string;
  }) => [...loyaltyIncentivesQueryKeys.all, storeId, serviceMode, loyaltyId] as const,
};

export const useLoyaltyIncentives = () => {
  const { store, serviceMode, loyaltyUserReady, loyaltyId } = useLoyaltyLegacyStates();

  return useQuery({
    enabled: loyaltyUserReady && !!store.number && !!serviceMode,
    queryKey: loyaltyIncentivesQueryKeys.params({
      serviceMode: serviceMode ?? '',
      storeId: store.number ?? '',
      loyaltyId,
    }),
    queryFn: async () => {
      const response = await client.queries.getLoyaltyIncentives({
        storeNumber: store.number ?? '',
        serviceMode: serviceMode ?? '',
        loyaltyId,
      });
      if (response.errors) {
        throw response.errors;
      }

      return response.data;
    },
  });
};

export const useOfferIncentive = (offerId: string) => {
  const { data, ...props } = useLoyaltyIncentives();

  const currentOffer = useMemo(
    () => data?.offers?.find(offer => offer && offer.id === offerId),
    [data?.offers, offerId]
  );
  return {
    ...props,
    data: currentOffer,
  };
};
