import { type ElementRef, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import {
  Platform,
  TextInput as RnTextInput,
  TextInputProps as RnTextInputProps,
  StyleSheet,
} from 'react-native';

import { useMergedEventHandlers } from '@fhs/utils';

import { tokens } from '../..//tokens';
import { Pressable } from '../pressable';
import { XStack } from '../stack';

export type FormControlTextInputProps = Omit<
  RnTextInputProps,
  'editable' | 'placeholderTextColor'
> & {
  readOnly?: boolean;
  pressedStyle?: RnTextInputProps['style'];
  focusedStyle?: RnTextInputProps['style'];
  hoveredStyle?: RnTextInputProps['style'];
  readOnlyStyle?: RnTextInputProps['style'];
  icon?: React.JSX.Element;
};

export const FormControlTextInput = forwardRef<RnTextInput, FormControlTextInputProps>(
  function FormControlTextInput({ icon, ...props }, forwardedRef) {
    const inputRef = useRef<ElementRef<typeof RnTextInput> | null>(null);
    const [focused, setFocused] = useState(false);
    const [hovered, setHovered] = useState(false);

    const handleOnFocus = useMergedEventHandlers(props.onFocus, () => setFocused(true));
    const handleOnBlur = useMergedEventHandlers(props.onBlur, () => setFocused(false));

    // rome-ignore lint/style/noNonNullAssertion: inputRef is always forwarded
    useImperativeHandle(forwardedRef, () => inputRef.current!);

    return (
      <Pressable
        accessible={false}
        disabled={props.readOnly}
        onHoverIn={() => setHovered(true)}
        onHoverOut={() => setHovered(false)}
        {...Platform.select({ web: { tabIndex: -1 } })}
        onPress={() => {
          inputRef.current?.focus();
        }}
      >
        {({ pressed }) => (
          <XStack
            style={[
              styles.inputContainer,
              hovered && [styles.hovered, props.hoveredStyle],
              focused && [styles.focused, props.focusedStyle],
              pressed && [styles.pressed, props.pressedStyle],
              props.readOnly && [styles.readOnly, props.readOnlyStyle],
              props.style,
            ]}
          >
            <RnTextInput
              {...props}
              readOnly={props.readOnly}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              placeholderTextColor={tokens.colors.$placeholderText}
              style={[styles.baseline, props.style]}
              ref={inputRef}
            />
            {icon && icon}
          </XStack>
        )}
      </Pressable>
    );
  }
);

const styles = StyleSheet.create({
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity30,
    backgroundColor: tokens.colors.$white,
    minHeight: 48,
    paddingHorizontal: 16,
    flex: 1, // TextInput takes available space
  },
  baseline: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    lineHeight: 20,
    color: tokens.colors.$houseDark,
    flex: 1, // TextInput takes available space
    ...Platform.select({
      web: { outlineWidth: 0 }, // remove outline on web
    }),
  },

  pressed: {
    borderColor: tokens.colors.$houseDark,
  },

  focused: {
    borderColor: tokens.colors.$houseDark,
  },

  hovered: {
    backgroundColor: tokens.colors.$blackOpacity04,
  },

  readOnly: {
    color: tokens.colors.$disabledText,
    borderColor: tokens.colors.$blackOpacity30,
    backgroundColor: tokens.colors.$blackOpacity04,
    // web allows this, but react native changed their types
    ...(Platform.select({ web: { cursor: 'not-allowed' } }) as any),
  },
});
