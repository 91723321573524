import { selectors, useAppSelector } from '@fhs-legacy/frontend/src/state/global-state';
import { useLoyaltyContext } from '@fhs-legacy/frontend/src/state/loyalty';
import { useLoyaltyUser } from '@fhs-legacy/frontend/src/state/loyalty/hooks/use-loyalty-user';
import { useServiceModeContext } from '@fhs-legacy/frontend/src/state/service-mode';
import { useStoreContext } from '@fhs-legacy/frontend/src/state/store';

export function useLoyaltyLegacyStates() {
  const { store } = useStoreContext();
  const { serviceMode } = useServiceModeContext();
  const { loyaltyUserReady } = useLoyaltyContext();
  const { loyaltyUser, loading } = useLoyaltyUser();
  const loyaltyId = useAppSelector(selectors.loyalty.selectLoyaltyUserId);
  const loyaltyUserExpireDateFormattedText = useAppSelector(
    selectors.loyalty.selectLoyaltyUserExpireDateFormattedText
  );

  return {
    store,
    serviceMode,
    loyaltyUserReady,
    loyaltyId,
    loyaltyUser,
    loyaltyUserLoading: loading,
    loyaltyUserExpireDateFormattedText,
  };
}
