import React, { useState } from 'react';
import { Modal, StyleSheet } from 'react-native';

import { IconClose, Pressable, Text, XStack, YStack } from '@fhs/ui';
import { useBoxRef } from '@fhs/utils';

import { tokens } from '../../tokens';

import { ActionSheetProps } from './types';

export const DesktopOptionSelectModal = ({
  headerTitle = '',
  onClose,
  isVisible,
  children,
}: ActionSheetProps) => {
  const handleRequestCloseRef = useBoxRef(() => {
    onClose();
  });

  const [contentHeight, setContentHeight] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  const handleLayout = event => {
    const { height } = event.nativeEvent.layout;
    setContainerHeight(height);
  };

  const handleContentLayout = event => {
    const { height } = event.nativeEvent.layout;
    setContentHeight(height);
  };

  const isLongContent = contentHeight / containerHeight > 0.85;

  return (
    <Modal
      visible={isVisible}
      transparent
      animationType="fade"
      onRequestClose={handleRequestCloseRef.current}
    >
      <YStack style={styles.container}>
        <Pressable
          aria-label="Close dropdown"
          accessible={false}
          focusable={false}
          onPress={handleRequestCloseRef.current}
          style={styles.overlay}
        />
        <YStack
          style={[styles.modalWrapper, isLongContent && styles.modalWrapperLongContent]}
          onLayout={handleLayout}
        >
          {!!headerTitle && (
            <XStack style={[styles.header, isLongContent && styles.headerLongContent]}>
              <Text style={styles.headerTitle} weight="semibold">
                {headerTitle}
              </Text>
              <Pressable onPress={onClose}>
                <IconClose size={32} />
              </Pressable>
            </XStack>
          )}
          <YStack style={styles.content} onLayout={handleContentLayout}>
            {children}
          </YStack>
        </YStack>
      </YStack>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundColor: tokens.colors.$blackOpacity75,
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 28,
    paddingTop: 28,
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: 33.6,
  },
  modalWrapper: {
    display: 'flex',
    width: 520,
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundColor: tokens.colors.$white,
    gap: 28,
    borderRadius: 12,
  },
  content: {
    flex: 1,
  },
  modalWrapperLongContent: {
    borderRadius: 0,
  },
  headerLongContent: {
    borderBottomWidth: 1,
    borderBottomColor: tokens.colors.$blackOpacity10,
  },
});
