import { gql } from '@apollo/client';

import { CartEntryFragment } from './cart-entry';

export const OrderFragment = gql`
  fragment OrderFragment on Order {
    _id: rbiOrderId
    createdAt
    posOrderId
    rbiOrderId
    status
    fireOrderIn
    orderNumberFormatted
    cart {
      customerName
      cartEntries {
        ...CartEntryFragment
        children {
          ...CartEntryFragment
          children {
            ...CartEntryFragment
            children {
              ...CartEntryFragment
              children {
                ...CartEntryFragment
                children {
                  ...CartEntryFragment
                }
              }
            }
          }
        }
      }
      cartVersion
      discounts {
        name
        value
      }
      donations {
        id
        name
        totalCents
      }
      fees {
        name
        priceCents
        quantity
        totalCents
      }
      offersFeedback {
        cartEntry {
          lineId
        }
        couponId
        redemptionEligibility {
          evaluationFeedback {
            code
            condition
            message
            redeemableForSeconds
            redeemableInSeconds
            ruleSetType
          }
          isRedeemable
          isValid
        }
      }
      payment {
        cardType
        ccLast4
        panToken
        paymentType
      }
      posVendor
      rewardsApplied {
        cartId
        rewardId
        timesApplied
      }
      serviceMode
      storeAddress {
        addressLine1
        addressLine2
        city
        state
        zip
      }
      storeDetails {
        latitude
        longitude
        storeNumber
      }
      subTotalCents
      taxCents
      ticketNumber
      totalCents
      unavailableCartEntries {
        lineId
        sanityId
      }
      wasLoyaltyEarned
      vatNumber
    }
    delivery {
      pickup {
        latitude
        longitude
      }
      dropoff {
        arrivalTime
        beforeTime
        addressLine1
        addressLine2
        city
        state
        zip
        latitude
        longitude
        phoneNumber
      }
      driver {
        name
        profileImageUrl
        phoneNumber
      }
      feeCents
      feeDiscountCents
      itemizedFees {
        name
        itemValueCents
        quantity
        totalValueCents
      }
      totalFeeCents
      instructions
      status
      tipCents
      serviceFeeCents
      smallCartFeeCents
      geographicalFeeCents
      quotedFeeCents
      baseDeliveryFeeCents
      deliverySurchargeFeeCents
    }
    loyaltyTransaction {
      id
      pointsEarned
      bonusPointsEarned
      pointsUsed
      pointsBalance
      rewardsUsed
    }
    earliestPickupTimeInSeconds
  }

  ${CartEntryFragment}
`;
