import React, { FC, ReactNode, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { AlertDialog, Button } from '@fhs-legacy/universal-components';
import { EventName, emitEvent } from 'utils/event-hub';

import CheckoutErrorCell from './checkout-error-cell';
import { StyledHeader } from './styled';

interface ICheckoutErrorsModalProps {
  checkoutErrors: Array<{ messageId: string; message?: string | ReactNode }>;
  closeModal: () => void;
  headerMessageId?: string;
}

const CheckoutErrorsModal: FC<React.PropsWithChildren<ICheckoutErrorsModalProps>> = ({
  checkoutErrors,
  closeModal,
  headerMessageId,
}) => {
  const { formatMessage } = useIntl();
  const cancelRef = useRef(null);

  const hasMoreThanOneError = checkoutErrors.length > 1;

  const errorsToBeDisplayed = checkoutErrors.map(({ messageId, message }) => (
    <CheckoutErrorCell
      key={messageId}
      showDot={hasMoreThanOneError}
      checkoutErrorId={messageId}
      message={message}
    />
  ));

  useEffect(() => {
    emitEvent(EventName.PLACE_PICKUP_ORDER_END, { status: 'checkout_error' });
  }, []);

  return (
    <AlertDialog
      // Blaine:
      // there is a bug, where if we pass this ref to the Button below,
      // the app crashes in react-router trying to find the react-navigation context
      // this makes zero sense. But I've found that if we just dont attach the
      // ref below then things don't crash :)
      leastDestructiveRef={cancelRef}
      isOpen={true}
      onClose={closeModal}
      testID="checkout-errors-dialog"
    >
      <AlertDialog.Content>
        <AlertDialog.Header>
          <StyledHeader variant="headerTwo">
            {formatMessage({ id: headerMessageId ?? 'unableToPlaceOrder' })}
          </StyledHeader>
        </AlertDialog.Header>
        <AlertDialog.Body>{errorsToBeDisplayed}</AlertDialog.Body>
        <AlertDialog.Footer>
          <Button width="full" onPress={closeModal}>
            {formatMessage({ id: 'okay' })}
          </Button>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};

export default CheckoutErrorsModal;
